import { LOCATION_CHANGE } from 'connected-react-router';
import { track, logError } from '@tracking';
import getVirtualPageViewPayload from '../virtualPageView';

const trackVirtualPageViewMiddleware = ({ getState }) => next => action => {
  const EVENT_NAME = 'virtualPageView';
  try {
    if (action.type === LOCATION_CHANGE) {
      const state = getState();
      const payload = getVirtualPageViewPayload(state);
      track(EVENT_NAME, payload);
    }
  } catch (error) {
    logError(EVENT_NAME, error);
  } finally {
    next(action);
  }
};

export default trackVirtualPageViewMiddleware;
