import { KEYS, sessionCache } from '@services/cache';
import { isProduction } from '@utils/environment';
import sortObject from '@commons/utils/object/sort';
import getData from './getData';

const { TRACKING_DATA } = KEYS;

function saveTrackingData(data) {
  if (!isProduction()) {
    const trackingData = getData();
    const date = new Date();
    sessionCache.set(TRACKING_DATA, [
      {
        key: date,
        data: sortObject(data),
        date: date.toLocaleString(),
      },
      ...trackingData,
    ]);
  }
}

export default saveTrackingData;
