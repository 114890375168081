// taken from: https://developers.google.com/web/fundamentals/app-install-banners/

function isSafariPwa() {
  return window.navigator?.standalone === true;
}

let loadedInPwa = null;
function isPwa() {
  if (loadedInPwa !== null) return loadedInPwa;

  loadedInPwa = Boolean(
    isSafariPwa() || window.matchMedia('(display-mode: standalone)').matches,
  );

  return loadedInPwa;
}

export default isPwa;
