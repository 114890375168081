const OUT_OF_DELIVERY_ERROR_MESSAGE = 'ERROR_OUT_OF_DELIVERY_ZONES';
const SHOP_IS_CLOSED_FOR_LOCATION = 'ERROR_INVALID_RESTAURANT';

const OUT_OF_DELIVERY_MESSAGES = [
  OUT_OF_DELIVERY_ERROR_MESSAGE,
  SHOP_IS_CLOSED_FOR_LOCATION,
];

export default function parseOutOfDeliveryError(error) {
  if (!error || !error.message) {
    return false;
  }

  return OUT_OF_DELIVERY_MESSAGES.includes(error.message);
}
