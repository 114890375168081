const checkout = {
  marginTop: '42px',
  shareTheMeal: {
    separator: {
      height: '32px',
      width: '1px',
      background: '$platinum',
    },
    size: '30px',
  },
};

export default checkout;
