import getFullImageUrl from '@services/images/getFullImageUrl';
import { URLS, DH_URL, PY_STATIC_URL } from '@services/images/constants';

export function getImage({
  type,
  name,
  quality = 100,
  width,
  height,
  countryCode,
}) {
  const parsedType = type.toUpperCase().replace(new RegExp('-', 'g'), '_');
  const baseURL = URLS[parsedType];
  const widthParam = width ? `&width=${width}` : '';
  const heightParam = height ? `&height=${height}` : '';
  const country =
    countryCode && type === 'featured-products' ? `${countryCode}/` : '';
  return `${baseURL}${country}${name}?quality=${quality}${widthParam}${heightParam}`;
}

export function getImageShopCard({ image, width }) {
  return getFullImageUrl({ url: URLS.RESTAURANTS, image, width });
}

export function getImageMenu({ image, width, height }) {
  return getFullImageUrl({ url: URLS.PRODUCT, image, width, height });
}

export function getChannelIcon(path) {
  return `${PY_STATIC_URL}/${path}`;
}

export function getChannelBackground({ path, height, width }) {
  return `${PY_STATIC_URL}/${path}?height=${height}&width=${width}&quality=100`;
}

export function getSeoImage(path, quality = 100) {
  const baseURL = URLS.SEO;
  const url = `${baseURL}${path}?quality=${quality}`;
  return url;
}

export function getOnlinePaymentIcon(path) {
  return `${PY_STATIC_URL}/payment-methods/online-payments/results/${path}`;
}

export function getShopLogo(src) {
  const options = {
    type: 'restaurants',
    name: src,
    quality: 70,
    width: 100,
  };

  return getImage(options);
}

export function getCountryFlagIcon(countryCode) {
  const options = {
    type: 'COUNTRY_FLAG',
    name: `flag-${countryCode}.png`,
    quality: 70,
    width: 100,
  };
  return getImage(options);
}

/* queda asi hasta que en la respuesta del back, se 
mande solo el path en vez de la url entera de dh */
export function getDonationImage({ path, width, height }) {
  const widthParam = width ? `&width=${width}` : '';
  const heightParam = height ? `&height=${height}` : '';

  return `${path}?quality=100${widthParam}${heightParam}`;
}

export function getHomeBackground(countryCode) {
  if (!countryCode) return '';
  const options = {
    type: 'HOME_BACKGROUNDS',
    name: `home-background-${countryCode}.jpg`,
    quality: 100,
    width: 1345,
  };

  return getImage(options);
}

export function getFallbackHomeBackground() {
  const options = {
    type: 'HOME_BACKGROUNDS',
    name: `home-background-others.jpg`,
    quality: 100,
    width: 1345,
  };

  return getImage(options);
}

export function getHeaderBackground(type) {
  if (!type) return '';

  const options = {
    type: 'CATEGORY_BACKGROUND',
    name: `${type}.jpg`,
    quality: 100,
    width: 900,
  };

  return getImage(options);
}

export function getSectionImages(path) {
  return `${DH_URL}${path}?quality=100&width=300`;
}

export function getCartImageProduct(path) {
  return `${DH_URL}/products/${path}?quality=90&height=120&width=120`;
}

export function getHeaderFoodImage(image) {
  if (!image) return null;
  return image?.replace('-staging', '');
}

export function getFoodShopInfoCardImage(type, path) {
  if (type === 'SUGGESTED') {
    return `${URLS.PARTNER_HEADER_DEFAULT}${path}`;
  }
  return `${URLS.PROFILE_HEADERS}${path}`;
}
