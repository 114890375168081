import { successAction } from '@utils/actions';
import isEmptyObject from '@commons/utils/object/isEmptyObject';
import { error as errorLog } from '@logger';

import { getAllFlags } from './selectors';
import { FLAGS_UPDATED } from './actionTypes';

export const updateFlags = newFlags => (dispatch, getState) => {
  try {
    const state = getState();
    const cachedFlags = getAllFlags(state);

    const updatedFlags = Object.values(newFlags).reduce((result, flag) => {
      if (
        !cachedFlags[flag.key] ||
        flag.variation !== cachedFlags[flag.key].variation ||
        flag.abTest !== cachedFlags[flag.key].abTest
      ) {
        // eslint-disable-next-line no-param-reassign
        result[flag.key] = flag;
      }

      return result;
    }, {});

    if (!isEmptyObject(updatedFlags)) {
      dispatch(
        successAction({
          type: FLAGS_UPDATED,
          payload: { flags: updatedFlags },
        }),
      );
    }
  } catch (e) {
    errorLog('[UPDATE_FLAGS][ERROR]', { error: e, data: { newFlags } });
  }
};
