import getInstance from './instance';

export default function updateLocation(location, sentry) {
  const instance = sentry || getInstance();

  instance.configureScope(scope => {
    if (location) {
      const userData = { ...(scope._user || {}) };

      if (location.latitude) userData.latitude = location.latitude;
      if (location.longitude) userData.longitude = location.longitude;

      scope.setUser(userData);
    }
  });
}
