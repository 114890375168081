import { defineMessages } from 'react-intl';

export default defineMessages({
  country: {
    id: 'commons.country',
    defaultMessage: 'Uruguay.',
  },
  button_accept: {
    id: 'button.accept',
    defaultMessage: 'Aceptar',
  },
  button_continue: {
    id: 'button.continue',
    defaultMessage: 'Continuar',
  },
  subTotal: {
    id: 'subTotal',
    defaultMessage: 'Subtotal',
  },
  total: {
    id: 'total',
    defaultMessage: 'Total',
  },
  edit: {
    id: 'edit',
    defaultMessage: 'Editar',
  },
  remove: {
    id: 'remove',
    defaultMessage: 'Eliminar',
  },
  emailLabel: {
    id: 'commons.email.label',
    defaultMessage: 'Mail',
  },
  email_error: {
    id: 'commons.email_error',
    defaultMessage: 'El mail ingresado no es válido',
  },
  activate: {
    id: 'commons.activate',
    defaultMessage: 'Activar',
  },
  cancel: {
    id: 'commons.cancel',
    defaultMessage: 'Cancelar',
  },
  city_top: {
    id: 'home.city_top',
    defaultMessage: 'Top Ciudades',
  },
  food_top: {
    id: 'home.food_top',
    defaultMessage: 'Top Comidas',
  },
  zone_top: {
    id: 'home.zone_top',
    defaultMessage: 'Top Barrios',
  },
  change: {
    id: 'common.change',
    defaultMessage: 'Cambiar',
  },
  shipping: {
    id: 'common.shipping',
    defaultMessage: 'Envío',
  },
  search: {
    id: 'common.search',
    defaultMessage: 'Buscar',
  },
  metaTitle: {
    id: 'commons.metaTitle',
    defaultMessage: 'Delivery de Comida Online - Elegí, Pedí y Ya | PedidosYa',
  },
  metaDescription: {
    id: 'commons.metaDescription',
    defaultMessage:
      '¿Con hambre y nada te copa? Acá tu comida sabrosa. Hoy puede tener otro gusto. PedidosYa, te llena el corazón  ❤',
  },
  phone: {
    id: 'shared.phone',
    defaultMessage: 'Teléfono',
  },
  addressInputErrorMessage: {
    id: 'shared.address.input.error',
    defaultMessage: 'El formato de la dirección es incorrecto',
  },

  reset: {
    id: 'shared.reset',
    defaultMessage: 'Reestablecer',
  },
});
