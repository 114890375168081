import { colors } from '../colors';

const vouchers = {
  voucherRed: '$red',
  voucherPink: 'rgb(247, 126, 233)',
  voucherBlue: 'rgb(32, 100, 237)',
  voucherBrown: 'rgb(197, 125, 40)',
  voucherGreen: 'rgb(173, 232, 0)',
  voucherMagenta: 'rgb(255, 37, 125)',
  voucherYellow: 'rgb(254, 202, 15)',
  voucherOrange: 'rgb(255, 149, 19)',
  voucherTurquoise: 'rgb(0, 189, 237)',
  shadow: '0 2px 6px 1px $sepia_20',
  expirationHighlight: '$limon_darker_3',
  emptyState: {
    color: `${colors.textSecondary}`,
    border: 'dashed 1px $french_gray_1',
  },
  details: {
    largeFontSize: '48px',
  },
};

export default vouchers;
