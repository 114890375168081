import getMinutesToMilliseconds from '@utils/date/getMinutesToMilliseconds';
import yearsToMillis from '@commons/utils/date/yearsToMillis';

export const KEYS = {
  SEARCH_ID: 'SEARCH_ID',
  MARKETING_CAMPAIGNS: 'MARKETING_CAMPAIGNS',
  USER_GPS_PERMISSION_STATUS: 'USER_GPS_PERMISSION_STATUS',
  OS_GPS_PERMISSION_STATUS: 'OS_GPS_PERMISSION_STATUS',
  TRACKING_DATA: 'TRACKING_DATA',
  LOCATION_SELECTION_TYPE: 'LOCATION_SELECTION_TYPE',
  USER_ADDRESS_VALIDATED_QUANTITY: 'USER_ADDRESS_VALIDATED_QUANTITY',
  USER: 'user',
  VENDOR_CONFIG_NOTES: 'VENDOR_CONFIG_NOTES',
  TOOLTIP_ONLINE_PAYMENT: 'TOOLTIP_ONLINE_PAYMENT',
  PICKUP_COACH_MARK: 'PICKUP_COACH_MARK',
};

const ONE_MINUTE_IN_SECONDS = 60;
const HALF_MINUTE_IN_SECONDS = 30;
const ONE_DAY_IN_SECONDS = 86400;

export const TTLS = {
  WITHOUT: -1,
  DEFAULT: getMinutesToMilliseconds(ONE_MINUTE_IN_SECONDS),
  SEARCH_ID: getMinutesToMilliseconds(HALF_MINUTE_IN_SECONDS),
  MARKETING_CAMPAIGNS: yearsToMillis(2),
  VENDOR_CONFIG_NOTES: getMinutesToMilliseconds(ONE_DAY_IN_SECONDS),
};
