/* eslint-disable no-undef */
import { getEnvironment as getEnv } from '@commons/config';
import {
  TEST_ENVIRONMENT,
  DEV_ENVIRONMENT,
  STG_ENVIRONMENT,
  PROD_ENVIRONMENT,
  LOCAL_ENVIRONMENT,
  DOCKER_ENVIRONMENT,
} from '@commons/config/constants';

export const getEnvironment = getEnv;

export const isLocal = () =>
  getEnvironment() === LOCAL_ENVIRONMENT ||
  getEnvironment() === DOCKER_ENVIRONMENT;
export const isDevelopment = () => getEnvironment() === DEV_ENVIRONMENT;
export const isStaging = () => getEnvironment() === STG_ENVIRONMENT;
export const isProduction = () => getEnvironment() === PROD_ENVIRONMENT;
export const isTest = () => getEnvironment() === TEST_ENVIRONMENT;
