import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

import getPlatformIcon from '@commons/utils/getPlatformIcon';
import PLATFORMS from '@appscore/web-constants/platforms';

import connect from './connect';

function OpenGraphHeaders({
  canonicalUrl,
  title,
  description,
  defaultOgHeaders,
}) {
  const platformName = 'PedidosYa';

  const helmetName = {
    property: 'og:site_name',
    content: platformName,
  };

  const helmetImage = {
    property: 'og:image',
    content: getPlatformIcon(),
  };

  const helmetType = {
    property: 'og:type',
    content: 'website',
  };

  const helmetFacebook = {
    property: 'fb:app_id',
    content: PLATFORMS.pedidosya.fbAppID,
  };

  const helmetTitle = {
    property: 'og:title',
    content: (!defaultOgHeaders && title) || PLATFORMS.pedidosya.ogTitle,
  };

  const helmetDescription = {
    property: 'og:description',
    content:
      (!defaultOgHeaders && description) || PLATFORMS.pedidosya.ogDescription,
  };

  const helmetUrl = {
    property: 'og:url',
    content: canonicalUrl,
  };

  const metaTags = [
    helmetName,
    helmetImage,
    helmetType,
    helmetFacebook,
    helmetTitle,
    helmetDescription,
    helmetUrl,
  ];

  return <Helmet meta={metaTags} />;
}

OpenGraphHeaders.propTypes = {
  canonicalUrl: PropTypes.string.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
  defaultOgHeaders: PropTypes.bool,
};

export default connect(OpenGraphHeaders);
