import styled, { css } from 'styled-components';

import { isTop, isRight, isBottom, isLeft, isCenter } from '../helpers';

const POINTER_WIDTH = 20;
const POINTER_VISIBLE = 4;
const POINTER_WIDTH_HALF = POINTER_WIDTH / 2;

const Pointer = styled.div`
  width: ${POINTER_WIDTH}px;
  height: ${POINTER_WIDTH}px;
  background-color: ${({ theme }) => theme.commons.tooltip.background};
  position: absolute;
  z-index: -1;
  transform: translate(-50%) rotate(45deg);

  ${({ firstPosition, secondPosition }) => css`
    ${isTop(firstPosition) && `top: -${POINTER_VISIBLE}px;`}
    ${isRight(firstPosition) &&
      `right: calc(-${POINTER_VISIBLE}px - ${POINTER_WIDTH_HALF}px);`}
    ${isBottom(firstPosition) && `bottom: -${POINTER_VISIBLE}px;`}
    ${isLeft(firstPosition) &&
      `left: calc(${POINTER_WIDTH_HALF}px - ${POINTER_VISIBLE}px);`}

    ${(isTop(firstPosition) || isBottom(firstPosition)) &&
      `
      ${isLeft(secondPosition) ? `left: ${POINTER_WIDTH}px` : ''}
      ${isCenter(secondPosition) ? 'left: 50%;' : ''}
      ${isRight(secondPosition) ? `left: calc(100% - ${POINTER_WIDTH}px);` : ''}
    `}

    ${(isLeft(firstPosition) || isRight(firstPosition)) &&
      `
      ${isTop(secondPosition) ? `top: ${POINTER_WIDTH_HALF}px;` : ''}
      ${
        isCenter(secondPosition)
          ? `top: calc(50% - ${POINTER_WIDTH_HALF}px);`
          : ''
      }
      ${isBottom(secondPosition) ? `bottom: ${POINTER_WIDTH_HALF}px;` : ''}
    `}
  `}
`;

export default Pointer;
