export const QUERY_PARAM = {
  PRODUCT_CONFIG: 'p',
  FEATURED_PRODUCT_CONFIG: 'fp',
  LATITUDE: 'lat',
  LONGITUDE: 'lng',
  CITY_NAME: 'city',
  AREA_ID: 'areaId',
  AREA_NAME: 'areaName',
  ADDRESS: 'address',
  CHANNELS: 'channels',
  CATEGORY: 'category',
  BUSINESS_TYPE: 'bt',
  PAGE: 'page',
  ORDER_IN_PROCESS_CODE: 'code',
  ORDER_ID: 'orderId',
  SEARCH: 'search',
  MENU_SECTION: 'menuSection',
  PLATFORM: 'app',
  ORIGIN: 'origin',
  SORT_BY: 'sortBy',
  PAYMENTS: 'payments',
  ONLINE_PAYMENTS: 'onlinePayments',
  MODAL_PAGE: 'modal',
  OCCASION: 'occasion',
  EXTERNAL_LOGIN: 'externalLogin',
};

export const COUNTRY_NAME = {
  CHILE: 'Chile',
  URUGUAY: 'Uruguay',
  ARGENTINA: 'Argentina',
  PANAMA: 'Panamá',
  COLOMBIA: 'Colombia',
  BOLIVIA: 'Bolivia',
  PARAGUAY: 'Paraguay',
  DOMINICANAN_REPUBLIC: 'República Dominicana',
  VENEZUELA: 'Venezuela',
  NICARAGUA: 'Nicaragua',
  EL_SALVADOR: 'El Salvador',
  PERU: 'Perú',
  ECUADOR: 'Ecuador',
  COSTA_RICA: 'Costa Rica',
  GUATEMALA: 'Guatemala',
  HONDURAS: 'Honduras',
};

export const COUNTRY_SHORT_NAME = {
  ARGENTINA: 'ar',
  CHILE: 'cl',
  ECUADOR: 'ec',
  PANAMA: 'pa',
  PARAGUAY: 'py',
  PERU: 'pe',
  URUGUAY: 'uy',
  BOLIVIA: 'bo',
  COLOMBIA: 'co',
  DOMINICANAN_REPUBLIC: 'do',
  VENEZUELA: 've',
  NICARAGUA: 'ni',
  EL_SALVADOR: 'sv',
  COSTA_RICA: 'cr',
  GUATEMALA: 'gt',
  HONDURAS: 'hn',
};

export const BUSINESS_TYPE = {
  RESTAURANT: 'RESTAURANT',
  PHARMACY: 'PHARMACY',
  GROCERIES: 'GROCERIES',
  COURIER: 'COURIER',
  COFFEE: 'COFFEE',
};

export const TRACKING_ORIGIN = {
  ORDER_CONFIRM: 'order_confirmation',
  SHOP_LIST: 'shop_list',
  SHOP_LIST_CUISINE: 'shop_list_cuisine',
  SHOP_LIST_CHANNEL: 'shop_list_channel',
  SEARCH: 'search',
  CHANNEL_LANDING: 'channel_landing',
  SWIMLANE: 'swimlane',
  MY_FAVORITES: 'my_favorites',
  ORDER_STATUS: 'order_status',
  SUBMIT: 'submit',
  BUTTON: {
    TYPE: 'button',
    CLOSE: 'close',
    OPEN: 'open',
  },
};

export const DELIVERY_TYPE = {
  WITH_LOGISITCS: 'withLogistics',
  OWN_DELIVERY: 'ownDelivery',
};

export const MODAL_TYPE = {
  DELIVERY_TAG: 'deliveryTag',
  UNAVAILABLE_DELIVERY_TIME: 'checkout_unavailable_delivery_time',
  AGE_VALIDATION_FORM: 'age_validation_form',
};

export const CAPACITY_TYPE = {
  NONE: 'no_preorder_buckets_available',
  LOW: 'high_demand',
  MEDIUM: 'no_immediate_delivery',
  FULL: '(not set)',
};

export const LOGIN_ORIGIN = {
  LINK_ACCOUNT: 'link_account',
};

export const VALIDATION_RELIABLE = {
  USER_VALID: 'complete_adult',
  USER_NOT_OF_AGE: 'complete_underage',
  ID_CARD_FORMAT_ERROR: 'id_card_format',
  DATE_FORMAT_ERROR: 'date_of_birth_format',
  SERVICE_ERROR: 'service_error',
  CARDID_BIRTHDAY_DONT_MATCH: 'cardid_birthday_dont_match',
};

export const RESULTS_MAX_CITYLANDING = 30;

export const SPONSORING_TYPES = {
  FEATURED_CPC: 'FEATURED_CPC',
  FEATURED_CPP: 'FEATURED_CPP',
};
