import React, { createContext, useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import getDevice from '@commons/utils/device/getDevice';
import { HUAWEI_WEBVIEW } from '@commons/utils/device/devices';
import { log } from '@logger';
import updateFwfCurrentDevice from '@commons/fwf/update/updateFwfCurrentDevice';

export const DeviceContext = createContext(null);

function useDevice(serverDevice) {
  const initialDevice = getDevice() || serverDevice;
  const [device, changeDevice] = useState(initialDevice);

  const handleResize = useCallback(() => {
    const updatedDevice = getDevice();
    const shouldUpdateDevice = updatedDevice !== device;
    if (shouldUpdateDevice) {
      changeDevice(updatedDevice);
      updateFwfCurrentDevice(updatedDevice);
      log(`[DEVICE_TYPE_CHANGED]: from ${device} to ${updatedDevice}`);
    }
  }, [device]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize]);

  return device;
}
function DeviceProvider({ serverDevice, children }) {
  const device = useDevice(serverDevice);

  return (
    <DeviceContext.Provider
      value={serverDevice === HUAWEI_WEBVIEW ? serverDevice : device}
    >
      {children}
    </DeviceContext.Provider>
  );
}

DeviceProvider.propTypes = {
  serverDevice: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default DeviceProvider;
