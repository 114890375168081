import { firstCharToUpperCase } from '@utils/string';
import cityLandingMessages from './messages';

function getPrefixTitles(area, category) {
  let message = 'city';
  if (area) message = `area${firstCharToUpperCase(message)}`;
  if (category) message = `category${firstCharToUpperCase(message)}`;

  const metaTitlePrefix = `${message}MetaTitle`;
  const metaDescriptionPrefix = `${message}MetaDescription`;

  return { metaTitlePrefix, metaDescriptionPrefix };
}

export default function getCityLandingTitles({
  formatMessage,
  area,
  category,
  city,
}) {
  const { metaTitlePrefix, metaDescriptionPrefix } = getPrefixTitles(
    area,
    category,
  );

  let metaTitle = '';
  let metaDescription = '';

  if (city) {
    const intlProps = { city, area, category };

    metaTitle = formatMessage(cityLandingMessages[metaTitlePrefix], intlProps);
    metaDescription = formatMessage(
      cityLandingMessages[metaDescriptionPrefix],
      {
        ...intlProps,
        category: category && category.toLowerCase(),
      },
    );
  }
  return { metaTitle, metaDescription };
}
