import TaskScheduler from '@utils/browser/TaskScheduler';
import onUserFirstInteraction from '@utils/browser/onUserFirstInteraction';

function preloadModule(module) {
  return module.default.preload();
}

function schedule(scheduleFn, tasks = []) {
  let tasksArray = tasks;
  if (!Array.isArray(tasksArray)) tasksArray = [tasksArray];

  tasksArray.forEach(async task => {
    const { success, data } = await scheduleFn(task);
    if (success) preloadModule(data);
  });
}

function scheduleHighPriority(tasks) {
  schedule(TaskScheduler.scheduleHighPriority, tasks);
}

function scheduleMediumPriority(tasks) {
  schedule(TaskScheduler.scheduleMediumPriority, tasks);
}

function scheduleLowPriority(tasks) {
  schedule(TaskScheduler.scheduleLowPriority, tasks);
}

const preloadsDone = {};
// Calling now with true should be avoided in most cases.
function preload({ id, highTasks, mediumTasks, lowTasks, now }) {
  if (!id || !preloadsDone[id]) {
    if (id) preloadsDone[id] = true;
    const run = () => {
      if (highTasks?.length) scheduleHighPriority(highTasks);
      if (mediumTasks?.length) scheduleMediumPriority(mediumTasks);
      if (lowTasks?.length) scheduleLowPriority(lowTasks);
    };
    if (now) run();
    else onUserFirstInteraction(run);
  }
}

export default { preload };
