import { LOCATION_POINT_TYPE } from '@location/constants';
import isObject from '@commons/utils/object/isObject';
import locationToString from '../mappers/toString';
import LocationPoint from './LocationPoint';
import { IS_NEW_ADDRESS, STREET_TYPE } from '../model';

function UserAddressLocationPoint(location) {
  const {
    id,
    street,
    doorNumber,
    area,
    latitude,
    longitude,
    validated,
    cityName,
    alias,
    corner,
    phone,
    complement,
    notes,
    [IS_NEW_ADDRESS]: isNewAddress,
    [STREET_TYPE]: streetType,
  } = location;

  let areaProp = area;

  if (!isObject(areaProp) && !!area) areaProp = { id: areaProp };

  return LocationPoint({
    id,
    address: locationToString(location),
    area: areaProp,
    latitude,
    longitude,
    street,
    doorNumber,
    validated,
    cityName,
    alias,
    corner,
    phone,
    complement,
    notes,
    type: LOCATION_POINT_TYPE.USER_ADDRESS,
    [IS_NEW_ADDRESS]: isNewAddress,
    [STREET_TYPE]: streetType,
  });
}

export default UserAddressLocationPoint;
