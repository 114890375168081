import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getMainContentId } from '@shared/selectors';

import SkipLink from './SkipLink';

const mapStateToProps = createStructuredSelector({
  mainContentId: getMainContentId,
});

export default connect(mapStateToProps, null)(SkipLink);
