export const MODULE_NAME = 'location';

export const LOCATION_MAP_TYPE = {
  LIST: 'list',
  CONFIRM: 'confirm',
  EMPTY: 'empty',
};

export const LOCATION_ORIGIN = {
  CHECKOUT: 'checkout',
  ACCOUNT: 'account',
  SEARCH: 'search',
  HEADER_LOCATION: 'header_location',
  COURIER: 'courier',
};

export const MAP_ACTION = {
  CONFIRM: 'confirm',
  CANCEL: 'cancel',
};

export const MAP_ZOOM = {
  CITY: 15.5,
  LOCATION: 17.5,
};

export const LOCATION_POINT_TYPE = {
  SEARCH: 'SEARCH',
  SEARCH_AREA: 'SEARCH_AREA',
  GOOGLE: 'GOOGLE',
  GPS: 'GPS',
  USER_ADDRESS: 'USER_ADDRESS',
  SEARCH_COLOMBIAN_FORMAT: 'SEARCH_COLOMBIAN_FORMAT',
};

export const SEARCH_FORM_KEY = {
  CITY: 'city',
  CITY_ID: 'cityId',
  CITY_NAME: 'cityName',
  AREA_ID: 'areaId',
  STREET: 'street',
  DOOR_NUMBER: 'doorNumber',
  CORNER: 'corner',
  ADDRESS: 'address',
  STREET_TYPE: 'streetType',
};
