import PropTypes from 'prop-types';
import React from 'react';
import trackMigrationLinkToStoreClicked, {
  Stores,
} from '@app/modules/migrationLanding/tracking/trackMigrationLinkToStoreClicked';
import trackMigrationLoaded from '@app/modules/migrationLanding/tracking/trackMigrationLoaded';
import Spinner from '@components/Spinner';
import useIsMounted from '@hooks/useIsMounted';
import useOnce from '@hooks/useOnce';
import loadable from '@loadable/component';
import { SearchFrom } from '@migrationLanding/pages/MigrationLanding/constants';

const Appetito24Content = loadable(() =>
  import(
    '@migrationLanding/pages/MigrationLanding/components/Appetito24Content'
  ),
);
const GlovoContent = loadable(() =>
  import('@migrationLanding/pages/MigrationLanding/components/GlovoContent'),
);

const HugoContent = loadable(() =>
  import('@migrationLanding/pages/MigrationLanding/components/HugoContent'),
);

const ContentMap = {
  [SearchFrom.appetito24]: Appetito24Content,
  [SearchFrom.glovo]: GlovoContent,
  [SearchFrom.hugo]: HugoContent,
};

const MigrationLanding = props => {
  const {
    search: { from = SearchFrom.hugo },
  } = props;
  useOnce(() => {
    trackMigrationLoaded({ origin: from });
  });

  const goToHome = () => {
    window.location.href = 'https://pedidosya.com';
  };

  const handleAppStoreClick = () => {
    trackMigrationLinkToStoreClicked({
      origin: from,
      store: Stores.Apple,
    });
  };

  const handleGoogleStoreClick = () => {
    trackMigrationLinkToStoreClicked({
      origin: from,
      store: Stores.Google,
    });
  };

  const isMounted = useIsMounted().current;
  const Landing = ContentMap[from] ?? ContentMap[SearchFrom.appetito24];

  if (!isMounted) {
    return <Spinner fullScreen />;
  }

  return (
    <Landing
      goToHome={goToHome}
      goToAppStore={handleAppStoreClick}
      goToPlayStore={handleGoogleStoreClick}
    />
  );
};

MigrationLanding.propTypes = {
  search: PropTypes.shape({
    from: PropTypes.string,
  }),
};

export default MigrationLanding;
