import styled from 'styled-components';

const Icon = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  & img {
    width: 10px;
  }
`;

export default Icon;
