import React from 'react';
import PropTypes from 'prop-types';

import PeyaLoader from '@pedidosya/web-fenix/animations/PeyaLoader';
import styled, { css } from 'styled-components';

const StyledSpinner = styled.div`
  img {
    width: 60px;
  }
  p {
    margin-top: 0;
    font-size: 16px;
  }

  ${props =>
    props.fullScreen &&
    css`
      right: 50%;
      bottom: 50%;
      transform: translate(50%, 50%);
      position: absolute;
      text-align: center;
      font-family: ${({ theme }) => theme.fonts.secondaryFont};
      font-size: 14px;
    `}

  ${props =>
    !props.fullScreen &&
    css`
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
    `}
`;

function Spinner({ fullScreen }) {
  return (
    <StyledSpinner fullScreen={fullScreen}>
      <PeyaLoader />
    </StyledSpinner>
  );
}

Spinner.propTypes = {
  fullScreen: PropTypes.bool,
};

Spinner.defaultProps = {
  fullScreen: true,
};

export default Spinner;
