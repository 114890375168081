export const ONLINE = 'online';
export const OFFLINE = 'offline';
export const MERCADO_PAGO_PROVIDER_ID = 56;
export const VISANET_PROVIDER_ID = 68;
export const EDENRED_ALIMENTACION_PROVIDER_ID = 85;
export const EDENRED_RESTAURANT_PROVIDER_ID = 86;
export const DECIDIR_PROVIDER_ID = 87;
export const OCA_PROVIDER_ID = 95;
export const MASTERCARD_PROVIDER_ID_UY = 100;
export const INGENICO_PROVIDER_ID_PA = 101;
export const INGENICO_PROVIDER_ID_PY = 102;
export const INGENICO_PROVIDER_ID_BO = 113;
export const CREDITEL_PROVIDER_ID = 114;
export const SPREEDLY_PROVIDER_ID_UY = 115;
export const SPREEDLY_PROVIDER_ID_CH = 117;
export const SPREEDLY_PROVIDER_ID_AR = 118;
export const WEBPAY_PROVIDER_ID = 119;
export const SPREEDLY_PROVIDER_ID_BO = 122;
export const WEBPAY_ONE_CLICK_PROVIDER_ID = 124;
export const SPREEDLY_PROVIDER_ID_PA = 139;
export const SPREEDLY_PROVIDER_ID_PY = 140;
export const SPREEDLY_PROVIDER_ID_CR = 148;
export const SPREEDLY_PROVIDER_ID_SV = 144;
export const SPREEDLY_PROVIDER_ID_GT = 149;
export const SPREEDLY_PROVIDER_ID_HN = 150;

export const EDENRED_TICKET_ALIMENTACION_CARD_TYPE = 615;
export const EDENRED_TICKET_RESTAURANT_CARD_TYPE = 616;
