import { fromJS } from 'immutable';

export const FROM_ORDER_ID = 'fromOrderId';
export const IS_INITIALIZING = 'isInitializing';
export const IS_CHANGING_ADDRESS = 'isChangingAddress';
export const IS_DELETING_LINES = 'isDeletingLines';
export const IS_UPDATING_LINES = 'isUpdetingLines';
export const DATA = 'data';
export const OUT_OF_DELIVERY_ERROR = 'outOfDeliveryError';
export const PRODUCTS_REQUIRE_VALIDATE = 'productsRequireValidate';

export function createModel({
  fromOrderId = null,
  productsRequireValidate = [],
  isInitializing = false,
  isChangingAddress = false,
  isDeletingLines = false,
  isUpdetingLines = false,
  data = null,
  outOfDeliveryError = false,
} = {}) {
  return fromJS({
    [FROM_ORDER_ID]: fromOrderId,
    [IS_INITIALIZING]: isInitializing,
    [IS_CHANGING_ADDRESS]: isChangingAddress,
    [IS_DELETING_LINES]: isDeletingLines,
    [DATA]: data,
    [OUT_OF_DELIVERY_ERROR]: outOfDeliveryError,
    [PRODUCTS_REQUIRE_VALIDATE]: productsRequireValidate,
    [IS_UPDATING_LINES]: isUpdetingLines,
  });
}

const model = createModel();

export default model;
