// Mobile Services Address Model.
// Do not send anything else.
import {
  ID,
  STREET,
  DOOR_NUMBER,
  AREA,
  LATITUDE,
  LONGITUDE,
  VALIDATED,
  CITY_NAME,
  ALIAS,
  CORNER,
  PHONE,
  COMPLEMENT,
  NOTES,
  STREET_TYPE,
} from '@commons/models/LocationPoint/model';

export const modelProps = [
  ID,
  STREET,
  DOOR_NUMBER,
  AREA,
  LATITUDE,
  LONGITUDE,
  VALIDATED,
  CITY_NAME,
  ALIAS,
  CORNER,
  PHONE,
  COMPLEMENT,
  NOTES,
  STREET_TYPE,
];

export { STREET_TYPE };

export const STREET_TYPE_VALUE = [
  { value: 1, text: 'Calle' },
  { value: 2, text: 'Carrera' },
  { value: 3, text: 'Avenida' },
  { value: 4, text: 'Avenida Carrera' },
  { value: 5, text: 'Avenida Calle' },
  { value: 6, text: 'Circular' },
  { value: 7, text: 'Circunvalar' },
  { value: 8, text: 'Diagonal' },
  { value: 9, text: 'Manzana' },
  { value: 10, text: 'Transversal' },
  { value: 11, text: 'Vía' },
];

class UserAddress {
  constructor(data) {
    const payload = { ...data };
    if (payload[STREET_TYPE]) {
      // Server expects streetType as a number
      const streeType = STREET_TYPE_VALUE.find(
        s => s.text === payload[STREET_TYPE],
      );
      payload[STREET_TYPE] = streeType.value;
    }

    // to avoid setting 'undefined' to a prop
    Object.keys(payload).forEach(k => {
      if (modelProps.includes(k)) this[k] = payload[k];
    });
  }
}

export default UserAddress;
