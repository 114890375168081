const defaultToObjectIdFn = ({ id }) => id;

function toObject(arr, { idFn = defaultToObjectIdFn, mapFn } = {}) {
  if (!arr) return {};
  return arr.reduce((obj, val) => {
    obj[idFn(val)] = mapFn ? mapFn(val) : val;
    return obj;
  }, {});
}

export default toObject;
