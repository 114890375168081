import { POSITION, MARGIN, PADDING } from './constants';

export const isTop = pos => pos === POSITION.TOP;
export const isRight = pos => pos === POSITION.RIGHT;
export const isBottom = pos => pos === POSITION.BOTTOM;
export const isLeft = pos => pos === POSITION.LEFT;
export const isCenter = pos => pos === POSITION.CENTER;

export const verticalTranslation = position => {
  let translation = '0';
  if (position.endsWith(POSITION.TOP)) translation = `-${MARGIN + PADDING}px`;
  if (position.endsWith(POSITION.CENTER))
    translation = `calc(-50% - ${PADDING}px)`;
  if (position.endsWith(POSITION.BOTTOM))
    translation = `calc(-100% + ${PADDING}px)`;
  return translation;
};

export const horizontalTranslation = position => {
  let translation = '0';
  if (position.endsWith(POSITION.LEFT)) translation = `-${MARGIN}px`;
  if (position.endsWith(POSITION.CENTER)) translation = '-50%';
  if (position.endsWith(POSITION.RIGHT))
    translation = `calc(-100% + ${MARGIN}px)`;
  return translation;
};
