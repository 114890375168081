import { combineReducers } from 'redux-immutable';

import reducerRegistry from '@utils/redux/ReducerRegistry';

import shopReducer from './reducers/shop/reducer';
import menuReducer from './reducers/menu/reducer';
import cartReducer from './reducers/cart/reducer';
import orderReducer from './reducers/order/reducer';
import citiesReducer from './reducers/cities/reducer';
import areasReducer from './reducers/areas/reducer';
import userReducer from './reducers/user/reducer';
import shopListReducer from './reducers/shopList/reducer';
import addressesReducer from './reducers/addresses/reducer';

import { MODULE_NAME } from './constants';

const coreReducer = combineReducers({
  shop: shopReducer,
  menu: menuReducer,
  cart: cartReducer,
  order: orderReducer,
  cities: citiesReducer,
  areas: areasReducer,
  user: userReducer,
  shopList: shopListReducer,
  addresses: addressesReducer,
});

export default function registerCoreReducer() {
  reducerRegistry.register(MODULE_NAME, coreReducer);
}
