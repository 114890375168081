import { Seq } from 'immutable';

function fromJSGreedy(js) {
  if (typeof js !== 'object' || js === null) return js;
  return Array.isArray(js)
    ? Seq(js)
        .map(fromJSGreedy)
        .toList()
    : Seq(js)
        .map(fromJSGreedy)
        .toMap();
}

export default fromJSGreedy;
