import { defineMessages } from 'react-intl';

export default defineMessages({
  metaTitle: {
    id: 'app.modules.results.containers.ShopsList.metaTitle',
    defaultMessage: 'PedidosYa! Resultados',
  },
  metaDescription: {
    id: 'app.modules.results.containers.ShopsList.metaDescription',
    defaultMessage:
      'Pedí delivery para {address}. Los mejores restaurantes con delivery para {address}, {cityName} por PedidosYa.',
  },
});
