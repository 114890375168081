import { getImageMenu } from '@services/images.service';

// Types
export const TYPE_MENU_ITEM = 'MenuItem';
export const TYPE_MENU_SECTION = 'MenuSection';
export const TYPE_RESTAURANT = 'Restaurant';
export const TYPE_MENU = 'Menu';
export const TYPE_OFFER = 'Offer';
export const TYPE_RATING = 'AggregateRating';
export const TYPE_ORG = 'Organization';

/**
 * Crete the MenuItem schema
 * https://schema.org/MenuItem
 * @param {object}
 * @returns object
 */
function createMenuItem({ name, description, price, image } = {}) {
  return {
    '@type': TYPE_MENU_ITEM,
    name,
    description: description || undefined,
    image: image ? getImageMenu({ image }) : undefined,
    offers: {
      '@type': TYPE_OFFER,
      price,
    },
  };
}

/**
 * Crete the MenuSection schema
 * https://schema.org/MenuSection
 * @param {object}
 * @returns object
 */
function createMenuSection({ name, products } = {}) {
  return {
    '@type': TYPE_MENU_SECTION,
    name,
    hasMenuItem: products.map(createMenuItem),
  };
}

/**
 * Create the JSON Schema for any Restaurant
 * https://schema.org/Restaurant
 * @param {object}
 * @returns object
 */
function createSchemaRestaurant({
  sections = [],
  name = '',
  ratingCount = 0,
  ratingValue = 0,
}) {
  return {
    '@context': 'http://schema.org/',
    '@type': TYPE_RESTAURANT,
    url: `${window.location.origin}${window.location.pathname}`,
    name: name || undefined,
    aggregateRating: {
      '@type': TYPE_RATING,
      ratingCount,
      ratingValue,
      author: {
        '@type': TYPE_ORG,
        legalName: 'PedidosYa',
      },
    },
    hasMenu: {
      '@type': TYPE_MENU,
      hasMenuSection:
        sections?.length > 0 ? sections.map(createMenuSection) : [],
    },
  };
}

export default createSchemaRestaurant;
