export function stringToDate(dateString) {
  if (!dateString) return null;
  return dateString
    ? new Date(dateString.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$2/$1/$3'))
    : null;
}

export const longMonthOptions = {
  day: 'numeric',
  month: 'long',
  year: 'numeric',
};

/**
 * This method should be used instead of new Date(isoStringDate)
 * to avoid date problems in iOS.
 * @param {string [new Date().toISOString()]} isoStringDate
 */
export function parseDate(isoStringDate) {
  if (!isoStringDate) return null;
  const dateParts = isoStringDate.split(/[^0-9]/);
  const [year, month, day, hour, minutes, seconds] = dateParts;
  const date = new Date(year, month - 1, day, hour, minutes, seconds);
  return date;
}

export function stringToTime(stringDate, { withSeconds = false } = {}) {
  const date = parseDate(stringDate);
  if (!date) return null;
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let seconds = '';

  if (minutes.toString().length === 1) minutes = `0${minutes}`;
  if (hours.toString().length === 1) hours = `0${hours}`;
  if (withSeconds) {
    seconds = date.getSeconds();
    if (seconds.toString().length === 1) seconds = `0${seconds}`;
    seconds = `:${seconds}`;
  }

  return `${hours}:${minutes}${seconds}`;
}

export function getCurrentMonth() {
  const today = new Date();
  return today.getMonth() + 1;
}

export function getCurrentYear() {
  const today = new Date();
  return today.getFullYear();
}

export const getLocalTime = () => {
  const date = new Date();
  return date.getHours() * 100 + date.getMinutes();
};

export function stringTimeToUnixStamp(stringDate) {
  const date = parseDate(stringDate);
  return date.getTime() / 1000;
}

export function isDateLegalAge(stringDate, ageOfMajority = 18) {
  const now = new Date();
  const parsedDate = parseDate(stringDate);
  const date = parsedDate.setFullYear(parsedDate.getFullYear() + ageOfMajority);
  return date <= now;
}

export function isValidDate(date) {
  const [day, month, year] = date.split('/');
  if (day && month && year && Number(year) >= 1900) {
    const minDate = new Date(1900, 1, 1);
    const maxDate = new Date();
    const parsedDate = stringToDate(date);
    return (
      !Number.isNaN(parsedDate.getTime()) &&
      parsedDate >= minDate &&
      parsedDate <= maxDate
    );
  }
  return false;
}

export function stringToIsoDate(stringDate) {
  return stringDate
    ? stringToDate(stringDate)
        .toISOString()
        .replace(/\.\d{3}/, '')
    : false;
}

export function getDateNowToISOString() {
  return new Date().toISOString();
}

export function getISOStringWithTimeZone(date) {
  return new Date(
    date.getTime() - date.getTimezoneOffset() * 60000,
  ).toISOString();
}

export function timeDiffCalc(dateFuture, dateNow) {
  let diffInMilliSeconds = Math.abs(dateFuture - dateNow) / 1000;

  const days = Math.floor(diffInMilliSeconds / 86400);
  diffInMilliSeconds -= days * 86400;

  const hours = Math.floor(diffInMilliSeconds / 3600) % 24;
  diffInMilliSeconds -= hours * 3600;

  const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
  diffInMilliSeconds -= minutes * 60;

  const seconds = Math.floor(diffInMilliSeconds) % 60;

  const difference = { days, hours, minutes, seconds };

  return difference;
}
