import { fromJS } from 'immutable';

export const IS_FETCHING = 'isFetching';
export const HAS_FETCHED = 'hasFetched';
export const DATA = 'data';

export const createModel = ({
  isFetching = false,
  hasFetched = false,
  data = [],
} = {}) => fromJS(createModelJS({ isFetching, hasFetched, data }));

export function createModelJS({
  isFetching = false,
  hasFetched = false,
  data = [],
}) {
  return {
    [IS_FETCHING]: isFetching,
    [HAS_FETCHED]: hasFetched,
    [DATA]: data,
  };
}

const model = createModel();

export default model;
