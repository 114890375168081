import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';

import externalLogin from '@core/reducers/user/actions/externalLogin';
import { getIsUserLogged } from '@shared/selectors';
import AuthenticatedHOC from './AuthenticatedHOC';

const mapStateToProps = createStructuredSelector({
  isUserLogged: getIsUserLogged,
});

const mapDispatchToProps = {
  externalLogin,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  AuthenticatedHOC,
);
