export const MERCADO_PAGO = 'MERCADOPAGO';
export const DECIDIR = 'DECIDIR';
export const INGENICO = 'INGENICO';
export const CREDITEL = 'CREDITEL';
export const MASTERCARD_UY = 'MASTERCARD_UY';
export const OCA = 'OCA';
export const EDENRED = 'EDENRED';
export const VISA_NET = 'VISA_NET';
export const SPREEDLY = 'SPREEDLY';
export const WEBPAY_ONE_CLICK = 'ONECLICK';
export const EXTERNAL = 'EXTERNAL';
