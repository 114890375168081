import { Map } from 'immutable';

import { TOAST_STATE } from '@shared/constants';

export const IS_SIDE_BAR_OPEN = 'isSidebarOpen';
export const IS_SHOWING_ERROR_PAGE = 'isShowingErrorPage';
export const NOTIFICATION = 'notification';
export const ERROR_CODE = 'errorCode';
export const INITIAL_DATA = 'initialData';
export const INITIAL_REFERRAL = 'initialReferral';
export const IS_DESKTOP_LAYOUT = 'isDesktopLayout';
export const MAIN_CONTENT_ID = 'mainContentId';

const ModuleStateModel = Map({
  [IS_SIDE_BAR_OPEN]: false,
  [IS_SHOWING_ERROR_PAGE]: false,
  [NOTIFICATION]: Map({
    show: false,
    title: '',
    color: TOAST_STATE.NEUTRAL,
  }),
  [ERROR_CODE]: null,
  [INITIAL_DATA]: Map(),
  [IS_DESKTOP_LAYOUT]: false,
});

export default ModuleStateModel;
