import { connect } from 'react-redux';
import { compose } from 'redux';
import { injectIntl as withIntl } from 'react-intl';
import getShop from '@core/reducers/shop/selectors/getShop';
import getMenuWithDiscounts from '@core/reducers/menu/selectors/getMenuWithDiscounts';
import { createStructuredSelector } from 'reselect';

const mapStateToProps = createStructuredSelector({
  shop: getShop,
  menu: getMenuWithDiscounts,
});

const withConnect = connect(mapStateToProps, null);

export default compose(withIntl, withConnect);
