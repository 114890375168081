// Taken from: https://github.com/w3c/IntersectionObserver/blob/master/polyfill/intersection-observer.js

function isSupported() {
  return Boolean(
    'IntersectionObserver' in window &&
      'IntersectionObserverEntry' in window &&
      'intersectionRatio' in window.IntersectionObserverEntry.prototype,
  );
}

export default isSupported;
