import React, { createContext, useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import Tooltip from '@components/Tooltip';

export const TooltipContext = createContext(null);

function TooltipProvider({ children }) {
  const [
    { target, content, open = true, onClose, pointerPosition, styles },
    setState,
  ] = useState({});

  const setTooltip = useCallback(
    ({
      target: t,
      content: c,
      open: o,
      onClose: close,
      pointerPosition: pos,
      styles: s,
    }) => {
      setState({
        target: t,
        content: c,
        children: c,
        open: t && c ? !!o : false,
        onClose: close,
        pointerPosition: pos,
        styles: s,
      });
    },
    [],
  );

  return (
    <TooltipContext.Provider value={{ setTooltip }}>
      <>
        {Boolean(open && target && content) && (
          <Tooltip
            target={target}
            content={content}
            onClose={onClose}
            pointerPosition={pointerPosition}
            styles={styles}
          />
        )}
        {children}
      </>
    </TooltipContext.Provider>
  );
}

TooltipProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default TooltipProvider;
