import querystring from 'query-string';

import { sluggify } from '@utils/string';
import { SORT_FILTER } from '@results/constants';
import { QUERY_PARAM, COUNTRY_SHORT_NAME } from '@commons/constants';
import { changePropFromQuery } from '@utils/url';
import { RESULTS_MAX } from '@core/reducers/shopList/constants';
import {
  SPREEDLY_PROVIDER_ID_UY,
  VISANET_PROVIDER_ID,
  MERCADO_PAGO_PROVIDER_ID,
  SPREEDLY_PROVIDER_ID_CH,
  SPREEDLY_PROVIDER_ID_AR,
  DECIDIR_PROVIDER_ID,
  INGENICO_PROVIDER_ID_BO,
  SPREEDLY_PROVIDER_ID_BO,
  INGENICO_PROVIDER_ID_PA,
  SPREEDLY_PROVIDER_ID_PA,
  INGENICO_PROVIDER_ID_PY,
  SPREEDLY_PROVIDER_ID_PY,
  WEBPAY_ONE_CLICK_PROVIDER_ID,
} from '@payments/selectors/constants';

const {
  CHILE,
  ARGENTINA,
  BOLIVIA,
  URUGUAY,
  PANAMA,
  PARAGUAY,
} = COUNTRY_SHORT_NAME;

const { DEFAULT } = SORT_FILTER;
const { SORT_BY, CHANNELS, PAYMENTS, ONLINE_PAYMENTS } = QUERY_PARAM;

const paymentMethodsMap = {
  [ARGENTINA]: {
    spreedlyProviderId: SPREEDLY_PROVIDER_ID_AR,
    defaultProviderId: DECIDIR_PROVIDER_ID,
  },
  [BOLIVIA]: {
    spreedlyProviderId: SPREEDLY_PROVIDER_ID_BO,
    defaultProviderId: INGENICO_PROVIDER_ID_BO,
  },
  [PANAMA]: {
    spreedlyProviderId: SPREEDLY_PROVIDER_ID_PA,
    defaultProviderId: INGENICO_PROVIDER_ID_PA,
  },
  [PARAGUAY]: {
    spreedlyProviderId: SPREEDLY_PROVIDER_ID_PY,
    defaultProviderId: INGENICO_PROVIDER_ID_PY,
  },
  [CHILE]: {
    spreedlyProviderId: SPREEDLY_PROVIDER_ID_CH,
    webPayProviderId: WEBPAY_ONE_CLICK_PROVIDER_ID,
    defaultProviderId: MERCADO_PAGO_PROVIDER_ID,
  },
  [URUGUAY]: {
    spreedlyProviderId: SPREEDLY_PROVIDER_ID_UY,
    defaultProviderId: VISANET_PROVIDER_ID,
  },
};

function removeDuplicatePayments(payments, country) {
  let filteredPayments;
  const { spreedlyProviderId, webPayProviderId, defaultProviderId } =
    paymentMethodsMap[country] || {};

  // we remove one of the filters to avoid inconsistency between the filters selected and the number shown in the red ball nex to "Filtros" button
  const hasMoreThanOneOnlinePM =
    (payments.includes(`${spreedlyProviderId}`) ||
      payments.includes(`${webPayProviderId}`)) &&
    payments.includes(`${defaultProviderId}`);

  if (hasMoreThanOneOnlinePM)
    filteredPayments = payments.filter(id => id === `${spreedlyProviderId}`);

  return filteredPayments || payments;
}

export function compareSlugglified(nameX, nameY) {
  return sluggify(nameX).toLowerCase() === sluggify(nameY).toLowerCase();
}

export function findCityByName({ cities, cityName }) {
  return cities.find(x => compareSlugglified(x.name, cityName || ''));
}

export function getFetchShopsOffset({ page, max = RESULTS_MAX }) {
  return (page - 1) * max;
}

export const QUERY_FILTERS_SEPARATOR = '.';
const splitFilters = (filters, toNumber = true) =>
  filters
    ? filters.split(QUERY_FILTERS_SEPARATOR).reduce((acc, value) => {
        acc.push(toNumber ? Number(value) : value);
        return acc;
      }, [])
    : [];

export function parseFilters(search, countryShortName) {
  const { [SORT_BY]: sortBy } = search;

  const rawPayments = splitFilters(search[PAYMENTS], false);
  const channels = splitFilters(search[CHANNELS]);
  const onlinePayments = splitFilters(search[ONLINE_PAYMENTS]);
  const sortBySum = sortBy && sortBy !== DEFAULT ? 1 : 0;
  const payments = removeDuplicatePayments(rawPayments, countryShortName);

  return {
    [SORT_BY]: sortBy || DEFAULT,
    [CHANNELS]: channels,
    [PAYMENTS]: payments,
    count: sortBySum + channels.length + payments.length,
    [ONLINE_PAYMENTS]: onlinePayments,
  };
}

export const shopListLocationQueryParams = [
  QUERY_PARAM.LONGITUDE,
  QUERY_PARAM.LATITUDE,
  QUERY_PARAM.BUSINESS_TYPE,
  QUERY_PARAM.ADDRESS,
  QUERY_PARAM.CITY_NAME,
  QUERY_PARAM.AREA_ID,
  QUERY_PARAM.AREA_NAME,
];

export function resetShopsListQuery(search, isQuery = false) {
  let searchCopy = search;
  if (isQuery) searchCopy = querystring.parse(search);

  return querystring.stringify(
    shopListLocationQueryParams.reduce((s, p) => {
      // eslint-disable-next-line no-param-reassign
      s[p] = searchCopy[p];
      return s;
    }, {}),
  );
}

export function changeVerticalFromQuery(query, vertical) {
  return changePropFromQuery(query, QUERY_PARAM.BUSINESS_TYPE, vertical);
}

export const VERTICAL_IDENTIFIER = 'businessType';

export function getVerticalIdentifier(vertical) {
  return vertical && vertical[VERTICAL_IDENTIFIER];
}

export function compareVerticalByQueryParam(vertical, businesTypeQueryParam) {
  return (
    vertical &&
    businesTypeQueryParam &&
    getVerticalIdentifier(vertical).toLowerCase() ===
      businesTypeQueryParam.toLowerCase()
  );
}

export function getDefaultVertical(verticals) {
  if (!verticals?.length) return null;
  return verticals.find(x => x.position === 1) || verticals[0];
}

export function getSelectedVertical(verticals, selectedBusinessType) {
  if (!verticals) return null;
  if (!selectedBusinessType) return getDefaultVertical(verticals);
  return (
    verticals.find(x => compareVerticalByQueryParam(x, selectedBusinessType)) ||
    getDefaultVertical(verticals)
  );
}
