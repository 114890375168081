import { fromJS } from 'immutable';

import { SEARCH_FORM_KEY as constants } from './constants';

export const SEARCH_FORM = 'searchForm';
export const COUNTRY = 'country';
export const SEARCHED_LOCATION = 'searchedLocation';
export const AUTOCOMPLETE_ADDRESSES = 'autocompleteAddresses';
export const IS_GOOGLE_MAP_LOADED = 'isGoogleMapLoaded';

export const createModel = ({
  country = null,
  searchedLocation = null,
  isGoogleMapLoaded = false,
  autocompleteAddresses = [],
  searchForm = {},
} = {}) =>
  fromJS({
    [COUNTRY]: country,
    [SEARCHED_LOCATION]: searchedLocation,
    [AUTOCOMPLETE_ADDRESSES]: autocompleteAddresses,
    [IS_GOOGLE_MAP_LOADED]: isGoogleMapLoaded,
    [SEARCH_FORM]: {
      [constants.CITY_ID]: null,
      [constants.AREA_ID]: null,
      // Can contain more props, depending on each search
      // form implementation.,
      ...searchForm,
    },
  });

const model = createModel();

export default model;
