import { error } from '@logger';

export function androidTrack(event, payload) {
  try {
    if (!event) {
      return;
    }

    const dataToTrack = {
      event,
      ...payload,
    };

    window.CourierWebFormInterface.logEvent(event, JSON.stringify(dataToTrack));
  } catch (err) {
    error('[ANDROID_TRACKING_ERROR]', { error: err, data: { event, payload } });
  }
}

export function isAndroidTrack() {
  return (
    window?.CourierWebFormInterface &&
    typeof window.CourierWebFormInterface?.logEvent === 'function'
  );
}
