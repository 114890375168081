import { LOCATION_POINT_TYPE } from '@location/constants';
import locationToString from '../mappers/toString';
import LocationPoint from './LocationPoint';

function GoogleLocationPoint(location) {
  const {
    lat,
    lng,
    door,
    doorNumber,
    street,
    type,
    address,
    ...rest
  } = location;
  const selectedDoorNumber = door || doorNumber;
  return LocationPoint({
    ...rest,
    latitude: lat,
    longitude: lng,
    address:
      address ||
      locationToString({ ...location, doorNumber: selectedDoorNumber }),
    doorNumber: selectedDoorNumber,
    street,
    type: type || LOCATION_POINT_TYPE.SEARCH, // type must be SEARCH, not GOOGLE
  });
}

export default GoogleLocationPoint;
