import { log } from '@logger';
import { ROUTES } from '@services/routes.service';
import { stringTimeToUnixStamp } from '@utils/date';

export function setPublishableKey(obj, { publicApiKey, rsaKey, userId }) {
  const shouldTrackFingerprint = window.location.pathname.includes(
    ROUTES.CHECKOUT,
  );
  log(`[providers/ravelin][setPublishableKey] start`);
  obj.setPublicAPIKey(publicApiKey);
  log(`[providers/ravelin][setPublicAPIKey] ${publicApiKey}`);
  obj.setRSAKey(rsaKey);
  log(`[providers/ravelin][setRSAKey] ${rsaKey}`);
  obj.setCustomerId(userId);
  log(`[providers/ravelin][setCustomerId] ${userId}`);
  obj.trackPage();
  if (shouldTrackFingerprint) {
    obj.trackFingerprint(userId);
    log(`[providers/ravelin][trackFingerprint] ${userId}`);
  }
}

export async function loadInstance() {
  if ('ravelinjs' in window) {
    return window.ravelinjs;
  }

  log(`[providers/ravelin][loadInstance] start`);
  await import('@assets/libs/ravelinJS');
  log(`[providers/ravelin][loadInstance] loaded`);
  if ('ravelinjs' in window) {
    return window.ravelinjs;
  }
  throw new Error(`ravelinjs not present in window object`);
}

export const getRavelinData = ({
  token,
  holderName,
  bin,
  suffix,
  type,
  expirationMonth,
  expirationYear,
  deviceFingerprint,
  registrationDate,
}) => ({
  methodType: 'card',
  paymentMethodId: token,
  nameOnCard: holderName,
  cardBin: bin,
  cardLastFour: suffix,
  cardType: type,
  expiryMonth: expirationMonth,
  expiryYear: expirationYear,
  instrumentId: deviceFingerprint,
  registrationTime: stringTimeToUnixStamp(registrationDate),
});
