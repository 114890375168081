export const MARGIN = 20;
export const PADDING = 10;

export const POSITION = {
  TOP: 'top',
  RIGHT: 'right',
  BOTTOM: 'bottom',
  LEFT: 'left',
  CENTER: 'center',
};

export const POINTER_POSITION = {
  TOP_LEFT: `${POSITION.TOP}-${POSITION.LEFT}`,
  TOP_CENTER: `${POSITION.TOP}-${POSITION.CENTER}`,
  TOP_RIGHT: `${POSITION.TOP}-${POSITION.RIGHT}`,
  RIGHT_TOP: `${POSITION.RIGHT}-${POSITION.TOP}`,
  RIGHT_CENTER: `${POSITION.RIGHT}-${POSITION.CENTER}`,
  RIGHT_BOTTOM: `${POSITION.RIGHT}-${POSITION.BOTTOM}`,
  BOTTOM_RIGHT: `${POSITION.BOTTOM}-${POSITION.RIGHT}`,
  BOTTOM_CENTER: `${POSITION.BOTTOM}-${POSITION.CENTER}`,
  BOTTOM_LEFT: `${POSITION.BOTTOM}-${POSITION.LEFT}`,
  LEFT_BOTTOM: `${POSITION.LEFT}-${POSITION.BOTTOM}`,
  LEFT_CENTER: `${POSITION.LEFT}-${POSITION.CENTER}`,
  LEFT_TOP: `${POSITION.LEFT}-${POSITION.TOP}`,
};
