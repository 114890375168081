import { createSelector } from 'reselect';
import { MODULE_NAME } from '@shared/constants';

const baseUrl = state => state.getIn([MODULE_NAME, 'baseUrl']);

const getCanonicalUrl = createSelector(
  baseUrl,
  (state, pathname) => pathname,
  (url, pathname) => `${url}${pathname}`,
);

export default getCanonicalUrl;
