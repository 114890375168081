import ServiceResponse from '@services/models/ServiceResponse';

const ENDPOINT_CONTROLLER_URL = '/clear-session';

export default async function clearSession({ axios }) {
  try {
    await axios.put(ENDPOINT_CONTROLLER_URL);
    return new ServiceResponse({ success: true });
  } catch (e) {
    return new ServiceResponse({ error: e });
  }
}
