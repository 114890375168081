import { isServer } from '@commons/utils';
import configureServerStore from '@app/configureStore/configureServerStore';
import configureClientStore from '@app/configureStore/configureClientStore';

function configureStore({
  url,
  initialState,
  axios,
  actionsToDispatch,
  reducersRegisters,
}) {
  return isServer()
    ? configureServerStore({
        url,
        initialState,
        axiosInstance: axios,
        actionsToDispatch,
        reducersRegisters,
      })
    : configureClientStore(initialState, axios);
}

export default configureStore;
