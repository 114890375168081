import React from 'react';
import styled from 'styled-components';
import { injectIntl, intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import Button from '@pedidosya/web-fenix/atoms/Button';
import { onKeyDownFunction, onKeyUpFunction } from '@utils/a11y';
import { scrollTo } from '@utils/scroll';

import { MAIN_CONTENT_ID } from './constants';
import messages from './messages';

const StyledSkipLink = styled.div`
  cursor: pointer;
  position: fixed;
  z-index: 1000;
  left: 4%;
  top: 0%;

  ${({ theme }) => theme.fontFamily.secondaryFont.smallBold};
  background: ${({ theme }) => theme.colors.red};
  color: ${({ theme }) => theme.colors.white};

  transform: translateY(-100%);
  transition: transform 0.2s ease-out, opacity 0ms linear 0.2s;
  &&&:focus {
    transform: translateY(50%);
    transition: transform 0.2s ease-out, opacity 0ms linear 0.2s;
  }
`;

const SkipLink = ({ children, ...props }) => {
  const {
    mainContentId,
    intl: { formatMessage },
    scrollToTopOnSkipLink,
  } = props;

  const handlerClick = () => {
    window.location.href = `#${mainContentId}`;
    setTimeout(() => {
      if (scrollToTopOnSkipLink)
        scrollTo({ left: 0, top: 0, behavior: 'smooth' });
    }, 0);
  };

  return (
    <StyledSkipLink>
      <Button
        size="small"
        id="skip-link"
        data-testid="skip-link"
        role="button"
        label={formatMessage(messages.titleMessage)}
        onClick={handlerClick}
        onKeyUp={e => onKeyUpFunction(e, () => handlerClick(e))}
        onKeyDown={e => onKeyDownFunction(e, () => handlerClick(e))}
      />
    </StyledSkipLink>
  );
};

SkipLink.defaultProps = {
  mainContentId: `${MAIN_CONTENT_ID}`,
  scrollToTopOnSkipLink: true,
};

SkipLink.propTypes = {
  intl: intlShape,
  mainContentId: PropTypes.string,
  children: PropTypes.node,
  scrollToTopOnSkipLink: PropTypes.bool,
};

export default injectIntl(SkipLink);
