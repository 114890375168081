import React from 'react';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';
import Helmet from 'react-helmet';

import {
  isLocationPointFromSearch,
  isLocationPointFromUserAddress,
} from '@commons/models/LocationPoint';

import BasicHelmet from '../BasicHelmet';
import shopListMessages from './messages';
import connect from './connect';

function ShopListHeaders({ intl: { formatMessage }, searchedLocation }) {
  if (
    searchedLocation &&
    (isLocationPointFromSearch(searchedLocation) ||
      isLocationPointFromUserAddress(searchedLocation))
  ) {
    const { address, cityName } = searchedLocation;
    const title = formatMessage(shopListMessages.metaTitle);
    const metaDescription = formatMessage(shopListMessages.metaDescription, {
      address,
      cityName,
    });
    return <BasicHelmet title={title} metaContent={metaDescription} />;
  }
  return <Helmet title={formatMessage(shopListMessages.metaTitle)} />;
}

ShopListHeaders.propTypes = {
  intl: intlShape,
  searchedLocation: PropTypes.object.isRequired,
};

export default connect(ShopListHeaders);
