import { createSelector } from 'reselect';
import getShopBusinessType from '@core/reducers/shop/selectors/getShopBusinessType';
import { BUSINESS_TYPE } from '@commons/constants';

export const getIsQuickCommerce = createSelector(
  getShopBusinessType,
  businessType => {
    if (!businessType) return false;

    return (
      businessType !== BUSINESS_TYPE.RESTAURANT &&
      businessType !== BUSINESS_TYPE.COFFEE &&
      businessType !== BUSINESS_TYPE.COURIER
    );
  },
);
export default getIsQuickCommerce;
