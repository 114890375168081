import getSecondsToMilliseconds from '@utils/date/getSecondsToMilliseconds';

export const MODULE_NAME = 'menu';

export const MENU_LAYOUT_NAME = {
  EXTENDED: 'EXTENDED',
  SHELVES: 'SHELVES_CARROUSEL',
};

export const MENU_LAYOUT_ID = {
  [MENU_LAYOUT_NAME.EXTENDED]: 2,
  [MENU_LAYOUT_NAME.SHELVES]: 3,
};

const CACHE_TIME_IN_SECONDS = 150;
export const EXTENDED_MENU_MIN_FILTER_LENGTH = 3;
export const SHOP_MENU_CACHE_TIME = getSecondsToMilliseconds(
  CACHE_TIME_IN_SECONDS,
);

export const WEEKLY = 'WEEKLY';
export const PICKUP = 'PICKUP';
