import { Map, fromJS } from 'immutable';
import { successAction, failureAction } from '@utils/actions';
import { INIT_STORE } from '@shared/actionTypes';
import orderFlowReducer from '@core/utils/orderFlowReducer';

import { SEND_ORDER } from './actionTypes';

const ModuleStateModel = Map({
  isSending: false,
  data: null,
});

function orderReducer(state = ModuleStateModel, action) {
  switch (action.type) {
    case INIT_STORE:
      return ModuleStateModel.mergeDeep(state);

    case SEND_ORDER:
      return state.set('isSending', true);

    case failureAction(SEND_ORDER):
      return state.set('isSending', false);

    case successAction(SEND_ORDER): {
      const { order } = action.payload;
      return state.set('data', fromJS(order)).set('isSending', false);
    }

    default:
      return state;
  }
}

export default orderFlowReducer(orderReducer, ModuleStateModel);
