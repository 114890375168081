import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';

import getCanonicalUrl from '@shared/selectors/getCanonicalUrl';

const mapStateToProps = (state, { location: { pathname } }) => ({
  canonicalUrl: getCanonicalUrl(state, pathname),
});

const withConnect = connect(
  mapStateToProps,
  null,
);

export default compose(
  withRouter,
  withConnect,
);
