import { LOCATION_CHANGE } from 'connected-react-router';

import updateLocation from '@logger/sentry/updateLocation';
import { SEARCHED_LOCATION } from '@location/actionTypes';
import getBusinessType from '@results/selectors/getBusinessType';
import updateFwfBusinessType from '@commons/fwf/update/updateFwfBusinessType';
import { QUERY_PARAM } from '@commons/constants';
import { getQueryAsObject } from '@utils/domRouter';

function isChangingVertical(currentBussinessType, newBusinessType) {
  if (!currentBussinessType || !newBusinessType) return false;

  return currentBussinessType !== newBusinessType;
}

function updateByLocationChange(store, { location }) {
  const state = store.getState();
  const currentBusinessType = getBusinessType(state);
  const { [QUERY_PARAM.BUSINESS_TYPE]: newBusinessType } = getQueryAsObject(
    location, // getQueryAsObject is used here instead of getSearchAsObject because our store is not updated yet with the new search params
  );
  const changeVertical = isChangingVertical(
    currentBusinessType,
    newBusinessType,
  );
  if (changeVertical && newBusinessType) {
    updateFwfBusinessType(newBusinessType);
  }
}

function updateBySearchedLocation(store, action) {
  updateLocation(action.payload);
}

const locationMiddleware = store => next => action => {
  try {
    switch (action.type) {
      case SEARCHED_LOCATION:
        updateBySearchedLocation(store, action);
        break;
      case LOCATION_CHANGE:
        updateByLocationChange(store, action.payload);
        break;
      default:
        break;
    }
    next(action);
  } catch (error) {
    next(action);
  }
};

export default locationMiddleware;
