export const FLAGS_COMPARE_FUNCTIONS = {
  BOOLEAN: (oldFlag, newFlag) => oldFlag.variation !== newFlag.variation,
};

export const FLAGS = {
  INJECT_TOKEN_FOR_EXTERNAL_PAYMENTS_PROVIDERS: {
    KEY: 'mweb-inject-token-for-external-payments-providers',
    COMPARE_FN: FLAGS_COMPARE_FUNCTIONS.BOOLEAN,
  },
  USER_FAVORITES_SWIMLANE: {
    KEY: 'mweb-launcher-swimlanefav',
    COMPARE_FN: FLAGS_COMPARE_FUNCTIONS.BOOLEAN,
  },
  BIN_DISCOUNT_MVP: {
    KEY: 'mweb-bin-discounts-mvp',
    COMPARE_FN: FLAGS_COMPARE_FUNCTIONS.BOOLEAN,
  },
  SHOPLIST_SEARCH_SUGGESTIONS: {
    KEY: 'web-suggestions-search',
    COMPARE_FN: FLAGS_COMPARE_FUNCTIONS.BOOLEAN,
  },
  SHOPLIST_SEARCH_SUGGESTIONS_EXCLUDED: {
    KEY: 'all-suggested-search-excluded',
    COMPARE_FN: FLAGS_COMPARE_FUNCTIONS.BOOLEAN,
  },
  SHOP_CAPACITY_CHECK: {
    KEY: 'mweb-shopdetails-controlcapacity',
    COMPARE_FN: FLAGS_COMPARE_FUNCTIONS.BOOLEAN,
  },
  SHOP_DETAIL_GOTOCART: {
    KEY: 'mweb-shopdetails-gotocart',
    COMPARE_FN: FLAGS_COMPARE_FUNCTIONS.BOOLEAN,
  },
  CHECKOUT_WALLET_MESSAGE: {
    KEY: 'ab-checkout-walletmessage-mweb',
    COMPARE_FN: FLAGS_COMPARE_FUNCTIONS.BOOLEAN,
  },
  CHECKOUT_PEYA_PAGOS: {
    KEY: 'web-fintech-peya-pagos',
    COMPARE_FN: FLAGS_COMPARE_FUNCTIONS.BOOLEAN,
  },
  JOKER: {
    KEY: 'pwa-home-joker',
    COMPARE_FN: FLAGS_COMPARE_FUNCTIONS.BOOLEAN,
  },
  JOKER_V2: {
    KEY: 'mweb-showjoker2',
    COMPARE_FN: FLAGS_COMPARE_FUNCTIONS.BOOLEAN,
  },
  CART_REDEEM_VOUCHERS: {
    KEY: 'mweb-cart-redeemvouchers',
    COMPARE_FN: FLAGS_COMPARE_FUNCTIONS.BOOLEAN,
  },
  CHECKOUT_ADD_VOUCHER_BUTTON_COLOR: {
    KEY: 'ab-mweb-checkout-add-voucher-button-color',
    COMPARE_FN: FLAGS_COMPARE_FUNCTIONS.BOOLEAN,
  },
  GREEN_CONFIGURATION: {
    KEY: 'mweb-cart-greenconfig',
    COMPARE_FN: FLAGS_COMPARE_FUNCTIONS.BOOLEAN,
  },
  CONTACTLESS_DROP_OFF: {
    KEY: 'mweb-checkout-coronaemergency',
    COMPARE_FN: FLAGS_COMPARE_FUNCTIONS.BOOLEAN,
  },

  CHECKOUT_DEFAULT_CARD: {
    KEY: 'mweb-checkout-defaultcard',
    COMPARE_FN: FLAGS_COMPARE_FUNCTIONS.BOOLEAN,
  },
  PICKUP_ADDRESS: {
    KEY: 'mweb-checkout-pickupaddress',
    COMPARE_FN: FLAGS_COMPARE_FUNCTIONS.BOOLEAN,
  },
  CHECKOUT_DONATIONS: {
    KEY: 'mweb-checkout-donations',
    COMPARE_FN: FLAGS_COMPARE_FUNCTIONS.BOOLEAN,
  },
  SHOP_DETAILS_FEEDBACK_BUTTON: {
    KEY: 'mweb_shop_detail_feedback_button',
    COMPARE_FN: FLAGS_COMPARE_FUNCTIONS.BOOLEAN,
  },
  SHOP_DETAILS_FEEDBACK_BUTTON_OPTIONS: {
    KEY: 'shop_detail_feedback_button_options',
    COMPARE_FN: FLAGS_COMPARE_FUNCTIONS.BOOLEAN,
  },
  GROCERIES_ONE_CLICK_TO_ADD: {
    KEY: 'mweb-groceries-oneclicktoadd',
    COMPARE_FN: FLAGS_COMPARE_FUNCTIONS.BOOLEAN,
  },
  PHONE_VALIDATION: {
    KEY: 'ab-mweb-phone-validation',
    COMPARE_FN: FLAGS_COMPARE_FUNCTIONS.BOOLEAN,
  },
  PHONE_VALIDATION_CHECKOUT: {
    KEY: 'ab-mweb-phone-validation-checkout',
    COMPARE_FN: FLAGS_COMPARE_FUNCTIONS.BOOLEAN,
  },
  WEBPAY: {
    KEY: 'mweb-webpay',
    COMPARE_FN: FLAGS_COMPARE_FUNCTIONS.BOOLEAN,
  },
  WEBPAY_ONE_CLICK: {
    KEY: 'mweb-webpay-oneclick',
    COMPARE_FN: FLAGS_COMPARE_FUNCTIONS.BOOLEAN,
  },
  AVALITH_BIN: {
    KEY: 'mweb-avalith-active',
    COMPARE_FN: FLAGS_COMPARE_FUNCTIONS.BOOLEAN,
  },
  PHONE_VALIDATION_WHATSAPP: {
    KEY: 'ab-mweb-phone-validation-whatsapp',
    COMPARE_FN: FLAGS_COMPARE_FUNCTIONS.BOOLEAN,
  },
  PHONE_VALIDATION_BUTTONS_PRESENTATION_ORDER: {
    KEY: 'mweb-sms-whatsapp-presentation-order',
    COMPARE_FN: FLAGS_COMPARE_FUNCTIONS.BOOLEAN,
  },
  PHONE_VALIDATION_CART: {
    KEY: 'ab-mweb-phone-validation-cart',
    COMPARE_FN: FLAGS_COMPARE_FUNCTIONS.BOOLEAN,
  },

  PRODUCT_CONF_COUNTER: {
    KEY: 'mweb-food-product-configuration-units',
    COMPARE_FN: FLAGS_COMPARE_FUNCTIONS.BOOLEAN,
  },
  NEW_CHECKOUT_BILLING: {
    KEY: 'mweb-new-checkout-billing',
    COMPARE_FN: FLAGS_COMPARE_FUNCTIONS.BOOLEAN,
  },
  SECTION_DETAILS_EXTEND_SEARCH: {
    KEY: 'mweb-sectiondetails-extendsearch',
    COMPARE_FN: FLAGS_COMPARE_FUNCTIONS.BOOLEAN,
  },
  NEED_RELIABLE_AGE_VALIDATION: {
    KEY: 'mweb-need-reliable-age-validation',
    COMPARE_FN: FLAGS_COMPARE_FUNCTIONS.BOOLEAN,
  },
  BEFORE_PRICE: {
    KEY: 'mweb-shopdetail-tagbeforeprice',
    COMPARE_FN: FLAGS_COMPARE_FUNCTIONS.BOOLEAN,
  },
  SECTION_DETAILS_FILTERS: {
    KEY: 'mweb-section-details-filters',
    COMPARE_FN: FLAGS_COMPARE_FUNCTIONS.BOOLEAN,
  },
  GROCERIES_SECTION_IMAGES: {
    KEY: 'mweb-shopdetail-sectiontabimage',
    COMPARE_FN: FLAGS_COMPARE_FUNCTIONS.BOOLEAN,
  },
  SHOP_DETAIL_SEARCH_AGE_VALIDATION: {
    KEY: 'mweb-groceries-px-search-age-validation',
    COMPARE_FN: FLAGS_COMPARE_FUNCTIONS.BOOLEAN,
  },
  SHOP_DETAIL_MENU_GRIDVIEW: {
    KEY: 'mweb-groceries-px-shopdetails-gridview',
    COMPARE_FN: FLAGS_COMPARE_FUNCTIONS.BOOLEAN,
  },
  SHOPLIST_FOOD_IMAGES: {
    KEY: 'mweb-shoplist-showfoodpictures',
    COMPARE_FN: FLAGS_COMPARE_FUNCTIONS.BOOLEAN,
  },
  CART_EDITION: {
    KEY: 'mweb-groceries-px-shopdetails-cart-edition',
    COMPARE_FN: FLAGS_COMPARE_FUNCTIONS.BOOLEAN,
  },
  CART_IMAGE_PRODUCT: {
    KEY: 'mweb-groceries-px-cart-image-product',
    COMPARE_FN: FLAGS_COMPARE_FUNCTIONS.BOOLEAN,
  },
  SECTION_DETAILS_PILLS: {
    KEY: 'mweb-groceries-px-section-details-pills',
    COMPARE_FN: FLAGS_COMPARE_FUNCTIONS.BOOLEAN,
  },
  MISSING_PRODUCTS: {
    KEY: 'mweb-groceries-px-cart-missingproducts',
    COMPARE_FN: FLAGS_COMPARE_FUNCTIONS.BOOLEAN,
  },
  PRODUCT_CONF_DELETE: {
    KEY: 'mweb-groceries-px-product-configuration-delete',
    COMPARE_FN: FLAGS_COMPARE_FUNCTIONS.BOOLEAN,
  },
  SHOP_DETAIL_FAVORITE_DISHES: {
    KEY: 'mweb-food-ox-shopdetail-favorite_dishes',
    COMPARE_FN: FLAGS_COMPARE_FUNCTIONS.BOOLEAN,
  },
  DELIVERY_DX_PDT_FROM_DTS: {
    KEY: 'mweb-delivery-dx-deliverytime-dts',
    COMPARE_FN: FLAGS_COMPARE_FUNCTIONS.BOOLEAN,
  },
  SHOP_DETAILS_PICKUP: {
    KEY: 'mweb-food-ox-shopdetail-pickup',
    COMPARE_FN: FLAGS_COMPARE_FUNCTIONS.BOOLEAN,
  },
  SHOP_CART_PICKUP: {
    KEY: 'mweb-food-ox-shopcart-pickup',
    COMPARE_FN: FLAGS_COMPARE_FUNCTIONS.BOOLEAN,
  },
  LOYALTY_NEW_TAG: {
    KEY: 'web-loyalty-program-side-menu-nuevo-tag',
    COMPARE_FN: FLAGS_COMPARE_FUNCTIONS.BOOLEAN,
  },
  SHOP_CART_CROSS_SELLING: {
    KEY: 'food-ox-shopcart-cross_selling',
    COMPARE_FN: FLAGS_COMPARE_FUNCTIONS.BOOLEAN,
  },
  SHOP_CART_UPSELLING: {
    KEY: 'mweb_cart_upselling',
    COMPARE_FN: FLAGS_COMPARE_FUNCTIONS.BOOLEAN,
  },
  SERVICE_FEE_DX: {
    KEY: 'mweb-delivery-dx-service-fee',
    COMPARE_FN: FLAGS_COMPARE_FUNCTIONS.BOOLEAN,
  },
  SHOP_CART_PLUS: {
    KEY: 'mweb-food-ox-shopcart-plus',
    COMPARE_FN: FLAGS_COMPARE_FUNCTIONS.BOOLEAN,
  },
  CHECKOUT_CHANGE_DELIVERY_TYPE_ENABLED: {
    KEY: 'mweb-checkout-change-delivery-type-enabled',
    COMPARE_FN: FLAGS_COMPARE_FUNCTIONS.BOOLEAN,
  },
  FLEET_UTILISATION_DX: {
    KEY: 'mweb-delivery-dx-fleet-utilization',
    COMPARE_FN: FLAGS_COMPARE_FUNCTIONS.BOOLEAN,
  },
  CHECKOUT_TERMS_AND_CONDITIONS: {
    KEY: 'mweb-checkout-terms-and-conditions',
    COMPARE_FN: FLAGS_COMPARE_FUNCTIONS.BOOLEAN,
  },
  SHOP_CART_CONTROLLERS: {
    KEY: 'mweb-food-ox-shopcart-controllers',
  },
  SHOPLIST_PLUS: {
    KEY: 'mweb-shoplist-plus',
    COMPARE_FN: FLAGS_COMPARE_FUNCTIONS.BOOLEAN,
  },
  SHOP_CART_SUBTOTAL: {
    KEY: 'mweb-food-ox-shopcart-subtotal',
    COMPARE_FN: FLAGS_COMPARE_FUNCTIONS.BOOLEAN,
  },
  PRODUCT_CONFIG_CROSS_SELLING: {
    KEY: 'mweb-food-ox-product_config-cross_selling',
    COMPARE_FN: FLAGS_COMPARE_FUNCTIONS.BOOLEAN,
  },
  VOUCHERS_BDUI_PAGE: {
    KEY: 'web-vouchersbdui',
    COMPARE_FN: FLAGS_COMPARE_FUNCTIONS.BOOLEAN,
  },
  WEB_TO_NATIVE_CHECKOUT: {
    KEY: 'web-to-native-checkout',
    COMPARE_FN: FLAGS_COMPARE_FUNCTIONS.BOOLEAN,
  },
};

export const FLAGS_KEYS = Object.values(FLAGS).map(x => x.KEY);
