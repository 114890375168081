import { useRef } from 'react';

import useOnce from '@hooks/useOnce';

// Must be used as first hook.
function useIsMounted() {
  const ref = useRef(false);
  useOnce(() => {
    ref.current = true;
    return () => {
      ref.current = false;
    };
  });
  return ref;
}
export default useIsMounted;
