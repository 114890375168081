export const CART_STATUS = {
  MIN_ORDER_VALUE: 'minOrderValue',
  COMPLETED: 'completed',
};

export const DELETE_METHOD = {
  LAST_PRODUCT: 'remove_last_product',
  DELETE_ORDER: 'delete_order',
};

export const CART_UPDATED = {
  MODIFY_PRODUCT: 'modify_product',
  DELETE_PRODUCT: 'delete_product',
  MORE_QUANTITY: 'more_quantity',
  LESS_QUANTITY: 'less_quantity',
};

export const SECTION_LIST_ORIGIN = {
  SECTION_LIST_ORIGIN_VALUE: 1,
  SECTION_LIST_VIEW_ALL: 'view_all_sections_list',
  SECTION_LIST_VIEW_ALL_LINK: 'view_all_link',
};

export const SECTION_TYPE = {
  DEFAULT: 'DEFAULT',
  MATCHED: 'matched',
  PAID: 'PAID',
  BRANDED: 'branded',
  NOT_SET: '(not set)',
};

export const SUGGESTED = 'SUGGESTED';

export const ORIGIN = {
  CART: 'cart',
};
