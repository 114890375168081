/* eslint-disable no-useless-escape */
import isString from '@commons/utils/string/isString';
/**
 * @description Method to format string with given values
 * @param {String} str Value to be formatted
 * @param {[Object]} args multiple parameters Object with the value to format
 * @example format('this {0} an {1}', 'is', 'example') => 'this is an example'
 */
export function format(str, ...args) {
  return str.replace(/{(\d+)}/g, (match, number) =>
    typeof args[number] !== 'undefined' ? args[number] : match,
  );
}

/**
 * @description Method to remove special chars from the string
 * @author Martin Pastorino 10/07/2018
 * @param {String} str Value to be processed to replace strange chars.
 */
export function removeSpecialChars(str) {
  return str
    .replace(/[àáâãäå]/gi, 'a')
    .replace(/[èéêë]/gi, 'e')
    .replace(/[ìíîï]/gi, 'i')
    .replace(/[òóôõö]/gi, 'o')
    .replace(/[ùúûü]/gi, 'u')
    .replace(/[ñ]/gi, 'n')
    .replace(/[ç]/gi, 'c')
    .replace(/[\'`´\"]/gi, ' ')
    .replace(/[\(\)]/gi, '');
}

export function removeCharsExceptLetters(str) {
  if (!str) return '';
  const string = removeSpecialChars(str);
  return string.replace(/[\(\)]/gi, '').replace(/[^a-zA-Z ]/g, '');
}

/**
 * @description Method to sluggify a string
 * @param {String} str Value to be sluggifyed
 */
export function sluggify(str) {
  if (!str) return '';
  const strlc = str.toLocaleLowerCase();
  return removeSpecialChars(strlc).replace(/\s/gi, '-');
}

export function capitalize(str) {
  if (str.length <= 1) return str.toUpperCase();
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

export function toTitleCase(str) {
  return str.replace(
    /\w\S*/g,
    txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(),
  );
}

export function joinAsSentence(stringArray) {
  return stringArray.join(', ').replace(/,(?!.*,)/gim, ' y');
}

export function isEmpty(value) {
  const valueTrimed = value && isString(value) && value.trim();
  return !valueTrimed;
}

export function firstCharToUpperCase(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function padString(string, pad) {
  return (pad + string.toString()).slice(-pad.length);
}

export function isGUID(value){
  if (!value && typeof value !== 'string') return false
  let regex = /^[a-z,0-9,-]{36,36}$/;
  return regex.test(value)
}