// This props are also used in app/services/models/UserAddress.js
// Do not modify any value

// CITY, IS_NEW_ADDRESS, and TYPE are custom for our app
// Do not send to backend.
export const CITY = 'city';
export const IS_NEW_ADDRESS = 'isNewAddress';
export const TYPE = 'type';

export const CITY_NAME = 'cityName';
export const LATITUDE = 'latitude';
export const LONGITUDE = 'longitude';

export const VALIDATED = 'validated';

export const STREET = 'street';
export const DOOR_NUMBER = 'doorNumber';
export const ADDRESS = 'address';
export const ID = 'id';

export const AREA = 'area';
export const AREA_NAME = 'name';

export const NOTES = 'notes';

export const STREET_TYPE = 'streetType';
export const CORNER = 'corner';

export const PHONE = 'phone';
export const COMPLEMENT = 'complement';
export const ALIAS = 'alias';
