/* eslint-disable no-underscore-dangle */
import PriorityQueue, { PRIORITY } from '@utils/dataStructures/PriorityQueue';

export { PRIORITY };

class TaskScheduler {
  // TODO: simultaneousTasks depends on network type
  constructor({ simultaneousTasks = 3 } = {}) {
    this.tasks = new PriorityQueue();
    this.simultaneousTasks = simultaneousTasks;
    this.runningTasks = 0;
  }

  _canRunTask() {
    return this.tasks.length && this.runningTasks < this.simultaneousTasks;
  }

  _runTask() {
    if (this._canRunTask()) {
      const { task, onEnd } = this.tasks.pop();
      this.runningTasks += 1;
      setTimeout(async () => {
        try {
          const data = await task();
          onEnd({ success: true, data });
        } catch (error) {
          onEnd({ success: false, error });
        } finally {
          this.runningTasks -= 1;
          this._runTask();
        }
      }, 1);
    }
  }

  _schedule(scheduleFn, task) {
    return new Promise(resolve => {
      scheduleFn({ task, onEnd: resolve });
      this._runTask();
    });
  }

  scheduleHighPriority = task =>
    this._schedule(this.tasks.pushHighPriority, task);

  scheduleMediumPriority = task =>
    this._schedule(this.tasks.pushMediumPriority, task);

  scheduleLowPriority = task =>
    this._schedule(this.tasks.pushLowPriority, task);
}

export default new TaskScheduler();
