import getCurrentUser from '@core/reducers/user/selectors/getCurrentUser';
import getSearchedLocation from '@location/selectors/getSearchedLocation';
import { getFirstReferral } from '@shared/selectors';
import { categoryType } from '@services/routes.service';
import { getLocationMethod } from '@utils/trackingUtils';
import { NOT_SET } from '@utils/tracking/constants';
import isPwa from '@utils/pwa/isPwa';

function getVirtualPageViewPayload(state, event = undefined) {
  const { location } = window;
  const searchedLocation = getSearchedLocation(state);
  const currentUser = getCurrentUser(state);
  const { connection } = navigator;

  const locationAddress = searchedLocation?.address || NOT_SET;
  const locationLat = searchedLocation?.latitude || NOT_SET;
  const locationLon = searchedLocation?.longitude || NOT_SET;
  const locationCity = searchedLocation?.cityName || NOT_SET;
  const locationMethod =
    (searchedLocation && getLocationMethod({ searchedLocation })) || NOT_SET;
  const userLoggedIn = !!currentUser;
  const userId = currentUser?.id || '';
  const pageType = event || categoryType(location);
  const pageUrlPath = location.pathname;
  const screenType = event || categoryType(location);
  const screenName = location.pathname;
  const documentReferrer = document.referrer || NOT_SET;
  const documentLocation = location.href;
  const documentReferrerInitial = getFirstReferral(state) || NOT_SET;
  const networkInformationEffectiveType = connection?.effectiveType || NOT_SET;
  const networkInformationDownlink = connection?.downlink || NOT_SET;
  const isInstalled = isPwa() ? 'installed' : 'not_installed';

  let webPushPermission = NOT_SET;
  if ('Notification' in window) webPushPermission = Notification.permission;

  const payload = {
    locationAddress,
    locationLat,
    locationLon,
    locationCity,
    locationMethod,
    userLoggedIn,
    userId,
    pageType,
    pageUrlPath,
    screenName,
    screenType,
    documentReferrer,
    documentLocation,
    documentReferrerInitial,
    networkInformationEffectiveType,
    networkInformationDownlink,
    webPushPermission,
    isInstalled,
  };
  return payload;
}

export default getVirtualPageViewPayload;
