import { connect } from 'react-redux';
import { compose } from 'redux';
import { injectIntl as withIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';

import {
  getSelectedAreaName,
  getSelectedCategoryName,
  getSelectedCityName,
} from '@cityLanding/selectors';

const mapStateToProps = createStructuredSelector({
  area: getSelectedAreaName,
  category: getSelectedCategoryName,
  city: getSelectedCityName,
});

const withConnect = connect(mapStateToProps);

export default compose(
  withRouter,
  withConnect,
  withIntl,
);
