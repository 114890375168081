import { TTLS } from './constants';
import MemoryStorage from './MemoryStorage';
import SessionStorage from './SessionStorage';
import LocalStorage from './LocalStorage';

export const MODE = { SESSION: 'session', STORAGE: 'storage' };

class Storage {
  constructor(customConfig) {
    this.config = { ttl: TTLS.WITHOUT, mode: MODE.STORAGE, ...customConfig };
    this.memory = new MemoryStorage();
    this.cache =
      this.config.mode === MODE.SESSION
        ? new SessionStorage()
        : new LocalStorage();
  }

  get(key) {
    try {
      const memoryValue = this.memory.get(key);
      if (memoryValue) {
        const removed = this._removeIfExpired(key, memoryValue.expirationTime);
        if (removed) return undefined;

        return memoryValue.value;
      }

      const record = this.cache.get(key);
      if (record) {
        const removed = this._removeIfExpired(key, record.expirationTime);
        if (removed) return undefined;

        this.memory.set(key, record);

        return record.value;
      }

      return undefined;
    } catch (error) {
      return undefined;
    }
  }

  set(key, value, { ttl } = {}) {
    try {
      const record = { value, expirationTime: this._getCacheTTL(ttl) };
      this.memory.set(key, record);
      Promise.resolve(this.cache.set(key, record));
      return true;
    } catch (error) {
      return false;
    }
  }

  remove(key) {
    try {
      this.memory.remove(key);
      Promise.resolve(this.cache.remove(key));
      return true;
    } catch (error) {
      return false;
    }
  }

  _removeIfExpired(key, expirationTime) {
    if (this._isCacheExpired(expirationTime)) {
      this.remove(key);
      return true;
    }
    return false;
  }

  _getCacheTTL(customTTL) {
    const memoryTTL = customTTL || this.config.ttl;
    return memoryTTL === TTLS.WITHOUT
      ? 0
      : Date.now() + parseInt(memoryTTL, 10);
  }

  _isCacheExpired(ttl) {
    return ttl && Date.now() >= ttl;
  }
}

export default Storage;
