export class ReducerRegistry {
  constructor() {
    // do not access these attributes directly
    this.emitChange = null;
    this.reducers = {};
  }

  getReducers() {
    return { ...this.reducers };
  }

  register(name, reducer) {
    if (this.reducers[name]) return;

    this.reducers = { ...this.reducers, [name]: reducer };
    if (this.emitChange) this.emitChange(this.getReducers());
  }

  setChangeListener(listener) {
    this.emitChange = listener;
  }
}

// singleton instance
const reducerRegistry = new ReducerRegistry();

export default reducerRegistry;
