import { createSelector } from 'reselect';
import { FLAGS } from '@commons/fwf/constants';
import { flagTracker } from '@commons/fwf/flagTracker';
import { MODULE_NAME } from './constants';
import isVariation from './utils/isVariation';

const getPropFromModule = (state, ...props) =>
  state.getIn([MODULE_NAME, ...props]);

export const getAllFlags = createSelector(
  state => getPropFromModule(state, 'flags'),
  flags => flags?.toJS(),
);

export const getFlagFromState = (state, flagName) => {
  const flags = getAllFlags(state);
  if (!flags) return null;
  return flags[flagName];
};

export const getSubscriptionSuccessFlag = createSelector(
  state => getFlagFromState(state, FLAGS.SUBSCRIPTION_SUCCESS.KEY),
  flag => flag?.variation || false,
);

export const getBinDiscountMvpFlag = createSelector(
  state => getFlagFromState(state, FLAGS.BIN_DISCOUNT_MVP.KEY),
  flag => (flag && flag.variation ? isVariation(flag) : false),
);

export const getShopListSuggestedSearchExcluded = createSelector(
  state =>
    getFlagFromState(state, FLAGS.SHOPLIST_SEARCH_SUGGESTIONS_EXCLUDED.KEY),
  flag => flag?.variation?.exclude || [],
);

export const getCapacityCheckFlag = createSelector(
  state => getFlagFromState(state, FLAGS.SHOP_CAPACITY_CHECK.KEY),
  flag => (flag && flag.variation ? isVariation(flag) : false),
);

export const getInvokeJokerServicesFlag = createSelector(
  state => getFlagFromState(state, FLAGS.JOKER.KEY),
  flag => flag?.variation || false,
);

export const getShopHeaderSectionList = createSelector(
  state => getFlagFromState(state, FLAGS.SHOP_HEADER_SECTION_LIST.KEY),
  flag => (flag && flag.variation ? isVariation(flag) : false),
);

export const getCartRedeemVouchersFlag = createSelector(
  state => getFlagFromState(state, FLAGS.CART_REDEEM_VOUCHERS.KEY),
  flag => isVariation(flag),
);

export const getGreenConfigurationFlag = createSelector(
  state => getFlagFromState(state, FLAGS.GREEN_CONFIGURATION.KEY),
  flag => isVariation(flag),
);

export const getContactlessDropOffFlag = createSelector(
  state => getFlagFromState(state, FLAGS.CONTACTLESS_DROP_OFF.KEY),
  flag => isVariation(flag),
);

export const getDefaultCreditCardFlag = createSelector(
  state => getFlagFromState(state, FLAGS.CHECKOUT_DEFAULT_CARD.KEY),
  flag => (flag && flag.variation ? isVariation(flag) : false),
);

export const getShopDetailsFeedbackConfig = createSelector(
  state =>
    getPropFromModule(
      state,
      'flags',
      FLAGS.SHOP_DETAILS_FEEDBACK_BUTTON_OPTIONS.KEY,
    ),
  flag => flag?.toJS()?.variation?.options || [],
);

export const getCheckoutDonationsFlag = createSelector(
  state => getFlagFromState(state, FLAGS.CHECKOUT_DONATIONS.KEY),
  flag => isVariation(flag),
);

export const getWebPayVisibilityFlag = createSelector(
  state => getFlagFromState(state, FLAGS.WEBPAY.KEY),
  flag => isVariation(flag),
);

export const getAvalithBinFlagEnabled = createSelector(
  state => getFlagFromState(state, FLAGS.AVALITH_BIN.KEY),
  flag => isVariation(flag),
);

export const getOneClickToAddFlagEnabled = createSelector(
  state => getFlagFromState(state, FLAGS.GROCERIES_ONE_CLICK_TO_ADD.KEY),
  flag => isVariation(flag),
);

export const getCounterProductConfFlagEnabled = createSelector(
  state => getFlagFromState(state, FLAGS.PRODUCT_CONF_COUNTER.KEY),
  flag => {
    flagTracker(flag, FLAGS.PRODUCT_CONF_COUNTER.KEY);
    return isVariation(flag);
  },
);

export const getCheckoutBillingFlag = createSelector(
  state => getFlagFromState(state, FLAGS.NEW_CHECKOUT_BILLING.KEY),
  flag => isVariation(flag),
);

export const getSectionDetailsExtendSearch = createSelector(
  state => getFlagFromState(state, FLAGS.SECTION_DETAILS_EXTEND_SEARCH.KEY),
  flag => (flag && flag.variation ? isVariation(flag) : false),
);

export const getNeedReliableAgeValidation = createSelector(
  state => getFlagFromState(state, FLAGS.NEED_RELIABLE_AGE_VALIDATION.KEY),
  flag => {
    flagTracker(flag, FLAGS.NEED_RELIABLE_AGE_VALIDATION.KEY);
    return isVariation(flag);
  },
);

export const getSectionDetailsFiltersFlag = createSelector(
  state => getFlagFromState(state, FLAGS.SECTION_DETAILS_FILTERS.KEY),
  flag => (flag && flag.variation ? isVariation(flag) : false),
);

export const getGridMenuFlag = createSelector(
  state => getFlagFromState(state, FLAGS.SHOP_DETAIL_MENU_GRIDVIEW.KEY),
  flag => {
    flagTracker(flag, FLAGS.SHOP_DETAIL_MENU_GRIDVIEW.KEY);
    return isVariation(flag);
  },
);

export const getSectionDetailsAgeValidationFlag = createSelector(
  state => getFlagFromState(state, FLAGS.SHOP_DETAIL_SEARCH_AGE_VALIDATION.KEY),
  flag => (flag && flag.variation ? isVariation(flag) : false),
);

export const getCartEditionFlag = createSelector(
  state => getFlagFromState(state, FLAGS.CART_EDITION.KEY),
  flag => {
    flagTracker(flag, FLAGS.CART_EDITION.KEY);
    return isVariation(flag);
  },
);

export const getCartItemWithImage = createSelector(
  state => getFlagFromState(state, FLAGS.CART_IMAGE_PRODUCT.KEY),
  flag => {
    flagTracker(flag, FLAGS.CART_IMAGE_PRODUCT.KEY);
    return isVariation(flag);
  },
);

export const getShowFoodPictureFlag = createSelector(
  state => getFlagFromState(state, FLAGS.SHOPLIST_FOOD_IMAGES.KEY),
  flag => {
    flagTracker(flag, FLAGS.SHOPLIST_FOOD_IMAGES.KEY);
    return isVariation(flag);
  },
);

export const getMissingProductsFlag = createSelector(
  state => getFlagFromState(state, FLAGS.MISSING_PRODUCTS.KEY),
  flag => {
    flagTracker(flag, FLAGS.MISSING_PRODUCTS.KEY);
    return isVariation(flag);
  },
);

export const getSectionDetailsPills = createSelector(
  state => getFlagFromState(state, FLAGS.SECTION_DETAILS_PILLS.KEY),
  flag => (flag && flag.variation ? isVariation(flag) : false),
);

export const getDeleteProductConfFlagEnabled = createSelector(
  state => getFlagFromState(state, FLAGS.PRODUCT_CONF_DELETE.KEY),
  flag => (flag && flag.variation ? isVariation(flag) : false),
);

export const getDeliveryTimeFromDTS = createSelector(
  state => getFlagFromState(state, FLAGS.DELIVERY_DX_PDT_FROM_DTS.KEY),
  flag => flag?.variation || false,
);

export const getIsCartWordingPickupFlag = createSelector(
  state => getFlagFromState(state, FLAGS.SHOP_CART_WORDINGS_PICKUP.KEY),
  flag => (flag && flag.variation ? isVariation(flag) : false),
);

export const getShowServiceFeeDxFlag = createSelector(
  state => getFlagFromState(state, FLAGS.SERVICE_FEE_DX.KEY),
  flag => isVariation(flag),
);

export const getShowVouchersBduiFlag = createSelector(
  state => getFlagFromState(state, FLAGS.VOUCHERS_BDUI_PAGE.KEY),
  flag => isVariation(flag),
);

