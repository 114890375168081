export const MODULE_NAME = 'payments';

export const FAKE_CC = 'FAKE';

export const PAYMENT_METHOD_ORIGIN_CHECKOUT = 'checkout';

export const PAYMENT_METHOD_ORIGIN_CARD_DELETED = 'card_deleted';

export const PAYMENT_METHOD_ORIGIN_TRANSACTION_ERROR = 'transaction_error';

export const PAYMENT_HISTORY_POINT = 'paymentHistoryPoint';

export const CHECKOUT_PATH = 'CHECKOUT';
