let callbacks = [];

function onUserFirstInteraction(cb) {
  callbacks.push(cb);
  if (callbacks.length === 1) {
    const preloadCb = () => {
      window.removeEventListener('click', preloadCb);
      window.removeEventListener('scroll', preloadCb);
      callbacks.forEach(c => c());
      callbacks = [];
    };
    window.addEventListener('scroll', preloadCb);
    window.addEventListener('click', preloadCb);
  }
}

export default onUserFirstInteraction;
