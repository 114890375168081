import {
  getSentryDSN,
  SENTRY_ENVIRONMENTS,
} from '@commons/utils/sentryConstants';
import { getEnvironment } from '@commons/config';
import applicationVersion from '@commons/config/applicationVersion';
import applicationName from '@commons/config/applicationName';
import { isLocal, isTest } from '@utils/environment';
import { PROD_ENVIRONMENT } from '@commons/config/constants';
import {
  BrowserClient,
  Scope,
  defaultStackParser,
  makeFetchTransport,
} from '@sentry/browser';
import * as Sentry from '@sentry/browser';

import isChunkLoadError from './utils/isChunkLoadError';

const uiEvents = ['ui.input', 'ui.click'];
const getWhiteListByEnviroment = () => {
  if (isLocal() || isTest()) return [];

  return [
    /https:\/\/live.pystatic.com\/webassets\/AppscoreWeb\/monolith\/.*/,
    // uncomment this to debug sentry locally
    // /\/js\/.*/,
  ];
};

function beforeBreadcrumb(breadcrumb) {
  return uiEvents.includes(breadcrumb.category) ? null : breadcrumb;
}

function beforeSend(event, hint) {
  const error = hint.originalException;

  if (isChunkLoadError(error?.message)) {
    return null;
  }

  return event;
}

export default async function initializeSentry(
  sessionId,
  { user, searchedLocation, countryName },
) {
  const environment = getEnvironment();
  const sampleRate = environment === PROD_ENVIRONMENT ? 0.1 : 1;
  const isSentryEnabled = SENTRY_ENVIRONMENTS.includes(environment);
  if (isSentryEnabled) {
    const SENTRY_DSN = getSentryDSN(environment);
    const whitelistUrls = getWhiteListByEnviroment();

    const client = new BrowserClient({
      dsn: SENTRY_DSN,
      environment,
      whitelistUrls,
      beforeBreadcrumb,
      beforeSend,
      maxBreadcrumbs: 50,
      release: `${applicationName}@${applicationVersion}`,
      traceSampleRate: sampleRate,
      sampleRate,
      transport: makeFetchTransport,
      stackParser: defaultStackParser,
      integrations: [Sentry.browserTracingIntegration()],
    });

    const scope = new Scope();
    scope.setClient(client);

    scope.setTag('country', countryName);
    scope.setTag('location', searchedLocation);
    scope.setUser(user);

    return scope;
  }

  return null;
}
