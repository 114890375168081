export const LOGIN_EXTERNAL = 'app/user/LOGIN_EXTERNAL';
export const REDIRECT_TO_LOGIN_EXTERNAL = 'app/user/REDIRECT_TO_LOGIN_EXTERNAL';

export const UPDATE_USER_INFO = 'app/user/UPDATE_USER_INFO';

export const SAVE_AGE_VALIDATION = 'app/user/SAVE_AGE_VALIDATION';
export const SAVE_IS_OF_AGE = 'app/user/SAVE_IS_OF_AGE';

export const UPDATE_COMPANY_BILLING = 'app/user/UPDATE_COMPANY_BILLING';

export const AGREE_TERMS_AND_CONDITIONS = 'app/user/AGREE_TERMS_AND_CONDITIONS';
export const GET_STATUS_TERMS_AND_CONDITIONS =
  'app/user/GET_STATUS_TERMS_AND_CONDITIONS';
