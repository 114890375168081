import getLocationPointArea from '@commons/models/LocationPoint/getters/getArea';
import {
  isLocationPointFromGPS,
  getLocationPointCityName,
} from '@commons/models/LocationPoint';
import { NOT_SET } from '@utils/tracking/constants';
/**
 * @description if value not is null or undefined, it is price format applied.
 */
export function formatPrice(value) {
  if (value === null || value === undefined || typeof value !== 'string') {
    return value;
  }
  const result = value.replace(/[^0-9.,]/g, '');
  return result.replace(',', '.');
}

/**
 * @description Extract a value property joined by comma from an array.
 */
export function getDataJoinedByComma(dataArray, propToUseToMap) {
  if (dataArray && Array.isArray(dataArray) && propToUseToMap) {
    return dataArray.map(i => i[propToUseToMap]).join(',');
  }
  if (!propToUseToMap) {
    return dataArray.map(i => i).join(',');
  }
  return '';
}

export function getPaymentMethodSelected(paymentMethod) {
  return paymentMethod?.paymentProvider || paymentMethod?.uiType || NOT_SET;
}

export function getSearchType(searchedLocation) {
  if (searchedLocation) {
    if (isLocationPointFromGPS(searchedLocation)) return 'geo_location';
    if (getLocationPointArea(searchedLocation)) return 'area';
    if (getLocationPointCityName(searchedLocation)) return 'city';
  }
  return 'point';
}

export function getLocationMethod({ searchedLocation, fromGPS } = {}) {
  return fromGPS || isLocationPointFromGPS(searchedLocation)
    ? 'geo_location'
    : 'manual';
}

export function convertDateToTrackingDate(date) {
  return date.replace(/Z/, '').replace(/T/, ' ');
}
