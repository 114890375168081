import { createSelector } from 'reselect';
import { getSearchAsObject } from '@shared/selectors';
import { QUERY_PARAM } from '@commons/constants';

const getBusinessTypeFromQuery = createSelector(
  getSearchAsObject,
  search => {
    const businessType = search[QUERY_PARAM.BUSINESS_TYPE];
    return businessType || '';
  },
);

export default getBusinessTypeFromQuery;
