export const appStoreLink =
  'https://itunes.apple.com/uy/app/pedidosya/id490099807';
export const playStoreLink =
  'https://play.google.com/store/apps/details?id=com.pedidosya';

export const SearchFrom = {
  appetito24: 'appetito24',
  glovo: 'glovo',
  hugo: 'hugo',
};
