import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';

import AuthenticatedHOC from '@user/containers/AuthenticatedHOC';

function ProtectedRoute({ component: Component, ...rest }) {
  const AuthenticatedComponent = AuthenticatedHOC(Component);

  return (
    <Route {...rest} render={props => <AuthenticatedComponent {...props} />} />
  );
}

ProtectedRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
};

export default ProtectedRoute;
