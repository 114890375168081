import React, { useContext, useState, useEffect, createContext } from 'react';
import PropTypes from 'prop-types';

import { WindowContext } from '@providers/WindowProvider';
import onWindowResizeEnds from '@utils/browser/onWindowResizeEnds';

export const ScreenSizeContext = createContext(null);

function getSize(windowObject) {
  if (windowObject) {
    const { innerWidth, innerHeight } = windowObject;
    return { screenWidth: innerWidth, screenHeight: innerHeight };
  }
  return { screenHeight: undefined, screenWidth: undefined };
}

function ScreenSizeProvider({ children }) {
  const windowObject = useContext(WindowContext);

  const [screenSize, setScreenSize] = useState(() => getSize(windowObject));

  useEffect(() => {
    if (windowObject) setScreenSize(getSize(windowObject));
  }, [windowObject]);

  useEffect(() => {
    function onResize() {
      setScreenSize(getSize(windowObject));
    }
    const removeListener = onWindowResizeEnds(onResize, windowObject);
    return () => removeListener();
  }, [windowObject]);

  return (
    <ScreenSizeContext.Provider value={screenSize}>
      {children}
    </ScreenSizeContext.Provider>
  );
}

ScreenSizeProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ScreenSizeProvider;
