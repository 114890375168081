import { fromJS } from 'immutable';

import { successAction } from '@utils/actions';
import { INIT_STORE } from '@shared/actionTypes';

import * as actions from './actionTypes';
import ModuleStateModel from './model';

const initialState = ModuleStateModel;

function fwfModuleReducer(state = initialState, action) {
  switch (action.type) {
    case INIT_STORE:
      return ModuleStateModel.mergeDeep(state);

    case successAction(actions.FLAGS_UPDATED): {
      const { flags } = action.payload;
      const currentFlags = state.get('flags');
      const newFlags = currentFlags.mergeDeep(fromJS(flags));

      return state.set('flags', newFlags);
    }

    default:
      return state;
  }
}

export default fwfModuleReducer;
