import { LOCATION_POINT_TYPE } from '@location/constants';
import LocationPoint from './LocationPoint';

function SearchAreaLocationPoint({
  cityName,
  area,
  validated,
  latitude, // only server side.
  longitude, // only server side.
  complement,
  notes,
}) {
  return LocationPoint({
    cityName,
    area,
    latitude,
    longitude,
    validated,
    complement,
    notes,
    type: LOCATION_POINT_TYPE.SEARCH_AREA,
  });
}

export default SearchAreaLocationPoint;
