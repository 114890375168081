import getInstance from './instance';

export default function updateUser(sessionId, user, sentry) {
  const instance = sentry || getInstance();

  instance.configureScope(scope => {
    const userData = { ...(scope._user || {}), id: sessionId };

    if (user) {
      userData.userId = user.id;
      userData.email = user.email;
    }

    scope.setUser(userData);
  });
}
