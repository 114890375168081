import debounce from './debounce';

/**
 * This will set a meta tag on Android devices, to prevent the resize of the
 * screen when the keyboard is shown
 */
export function setFixedHeightOnAndroid() {
  const isAndroid = navigator.userAgent.toLowerCase().indexOf('android') > -1;
  if (isAndroid) {
    const meta = document.createElement('meta');
    meta.name = 'viewport';
    // prettier-ignore
    meta.content = `width=device-width,height=${window.innerHeight},initial-scale=1.0, user-scalable=false`;
    const initialHeight = window.innerHeight;
    meta.httpEquiv = 'X-UA-Compatible';
    document.getElementsByTagName('head')[0].appendChild(meta);
    window.addEventListener(
      'resize',
      debounce(() => {
        if (initialHeight < window.innerHeight) {
          meta.content = `width=device-width,height=${window.innerHeight}, initial-scale=1, minimum-scale=1, maximum-scale=1`;
        }
      }, 100),
    );
  }
}

export function getLocale(country) {
  let language = 'es'; // Location by default
  if (country) {
    language = `${country.language}-${country.shortName.toUpperCase()}`;
  }
  return language;
}
