import { createSelector } from 'reselect';

import { getCountryShortName } from '@shared/selectors';
import { getLocale } from '@commons/utils';
import getCountryLanguage from './getCountryLanguage';

const getCountryCulture = createSelector(
  getCountryLanguage,
  getCountryShortName,
  (language, shortName) => getLocale({ language, shortName }),
);

export default getCountryCulture;
