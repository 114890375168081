import { fromJS } from 'immutable';

import { INIT_STORE } from '@shared/actionTypes';

import * as actions from './actionTypes';
import ModuleStateModel, { SEARCH_FORM, AUTOCOMPLETE_ADDRESSES } from './model';
import { SEARCH_FORM_KEY } from './constants';

const { CITY_ID, AREA_ID, CITY_NAME } = SEARCH_FORM_KEY;

const initialState = ModuleStateModel;

function locationReducer(state = initialState, action) {
  switch (action.type) {
    case INIT_STORE:
      return ModuleStateModel.mergeDeep(state);

    case actions.SELECT_CITY: {
      const { id, name } = action.payload;
      return state
        .setIn([SEARCH_FORM, CITY_ID], id)
        .setIn([SEARCH_FORM, CITY_NAME], name);
    }

    case actions.SELECT_AREA: {
      const { areaId } = action.payload;
      return state.setIn([SEARCH_FORM, AREA_ID], areaId);
    }

    case actions.AUTOCOMPLETE_ADDRESSES: {
      const { predictions } = action.payload;
      return state.setIn([AUTOCOMPLETE_ADDRESSES], fromJS(predictions));
    }

    case actions.CLEAR_AUTOCOMPLETE_ADDRESSES: {
      return state.setIn([AUTOCOMPLETE_ADDRESSES], fromJS([]));
    }

    case actions.SEARCHED_LOCATION: {
      const { payload } = action;

      return state.set('searchedLocation', fromJS(payload));
    }
    case actions.GOOGLE_MAP_LOADED:
      return state.set('isGoogleMapLoaded', true);

    case actions.UPDATE_SEARCH_FORM: {
      const { payload } = action;
      const actualValue = state.get(SEARCH_FORM);
      return state.set(SEARCH_FORM, actualValue.mergeDeep(fromJS(payload)));
    }

    default:
      return state;
  }
}

export default locationReducer;
