import { toTitleCase } from '@utils/string';

function LocationPoint({ address, street, validated, ...rest }) {
  const point = { ...rest, validated: !!validated };

  if (address) point.address = toTitleCase(address);
  if (street) point.street = toTitleCase(street);

  return point;
}

export default LocationPoint;
