import { createSelector } from 'reselect';

import { BUSINESS_TYPE } from '@commons/constants';
import getPropFromDomain from '@results/selectors/getPropFromResultsModule';

const getVerticals = createSelector(
  state => getPropFromDomain(state, 'verticals'),
  verticals => {
    const verticalsList = verticals
      ? verticals.sortBy(x => x.get('position')).toJS()
      : [];
    return verticalsList.filter(x => x.businessType !== BUSINESS_TYPE.COURIER);
  },
);

export default getVerticals;
