import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'app.shopDetails.containers.ShopDetails.title',
    defaultMessage: '{shopName} a Domicilio ¡Pide Delivery! | PedidosYa',
  },
  metaDescriptionWithTopCategories: {
    id:
      'app.shopDetails.containers.ShopDetails.metaDescription.with.topcategories',
    defaultMessage:
      'Pedí {topCategories} a {shopName} y Hacé tu Pedido Online Ahora por PedidosYa',
  },
  metaDescription: {
    id: 'app.shopDetails.containers.ShopDetails.metaDescription',
    defaultMessage:
      'Pedí a {shopName} y Hacé tu Pedido Online Ahora por PedidosYa',
  },
});
