import { toTitleCase } from '@utils/string';
import getLocationPointStreet from '../getters/getStreet';
import getLocationPointDoorNumber from '../getters/getDoorNumber';
import getLocationPointAddress from '../getters/getAddress';
import getLocationPointAreaName from '../getters/getAreaName';
import getLocationPointType from '../getters/getType';
import isLocationPointFromSearchArea from '../validations/isFromSearchArea';
import getLocationStreetType from '../getters/getStreetType';
import toStringColombianFormat from './toStringColombianFormat';
import getLocationCorner from '../getters/getCorner';

function getFullAddress(street, doorNumber) {
  return doorNumber ? `${street} ${doorNumber}` : street;
}

function searchedLocationToString(location) {
  if (!location) return '';

  const areaName = getLocationPointAreaName(location);
  const address = getLocationPointAddress(location);
  const street = getLocationPointStreet(location);
  const doorNumber = getLocationPointDoorNumber(location);
  const type = getLocationPointType(location);
  const streetType = getLocationStreetType(location);

  let locationAddress = '';

  if (streetType) {
    const corner = getLocationCorner(location);
    locationAddress = toStringColombianFormat({
      streetType,
      street,
      doorNumber,
      corner,
    });
  } else if (address || street) {
    locationAddress = address || getFullAddress(street, doorNumber);
  } else if (
    areaName &&
    (type ? isLocationPointFromSearchArea(location) : true)
  ) {
    locationAddress = areaName;
  }
  return toTitleCase(locationAddress);
}

export default searchedLocationToString;
