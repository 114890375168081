import { fromJS } from 'immutable';
import {
  IS_FETCHING_JOKER,
  HAS_FETCHED_JOKER,
  JOKER_OFFERS,
  ID,
  REMAINING_TIME,
  TIERS,
  TOKEN,
  SELECTED_SHOP_ID,
  INVOCATION_TIME,
  IS_STARTED_FLOW,
  IS_SHOW_MODAL,
  IS_TIME_OVER,
  CAN_SHOW_JOKER,
  CURRENT_TIER,
  SELECTED_OFFER,
} from './constants';

export const createModel = ({
  isFetchingJoker = false,
  hasFetched = false,
  jokerOffers = [],
  id = null,
  remainingTime = null,
  tiers = [],
  token = null,
  selectedShopId = null,
  invocationTime = null,
  isStartedFlow = false,
  isShowModal = false,
  isTimeOver = false,
  canShowJoker = false,
  currentTier = null,
  selectedOffer = {},
} = {}) =>
  fromJS({
    [IS_FETCHING_JOKER]: isFetchingJoker,
    [HAS_FETCHED_JOKER]: hasFetched,
    [JOKER_OFFERS]: jokerOffers,
    [ID]: id,
    [REMAINING_TIME]: remainingTime,
    [TIERS]: tiers,
    [TOKEN]: token,
    [SELECTED_SHOP_ID]: selectedShopId,
    [INVOCATION_TIME]: invocationTime,
    [IS_STARTED_FLOW]: isStartedFlow,
    [IS_SHOW_MODAL]: isShowModal,
    [IS_TIME_OVER]: isTimeOver,
    [CAN_SHOW_JOKER]: canShowJoker,
    [CURRENT_TIER]: currentTier,
    [SELECTED_OFFER]: selectedOffer,
  });

export function createModelJS({
  isFetchingJoker = false,
  hasFetched = false,
  jokerOffers = [],
  id = null,
  remainingTime = null,
  tiers = [],
  token = null,
  invocationTime = null,
  selectedShopId = null,
  isStartedFlow = false,
  isShowModal = false,
  isTimeOver = false,
  canShowJoker = false,
  currentTier = null,
  selectedOffer = {},
}) {
  return {
    [IS_FETCHING_JOKER]: isFetchingJoker,
    [HAS_FETCHED_JOKER]: hasFetched,
    [JOKER_OFFERS]: jokerOffers,
    [ID]: id,
    [REMAINING_TIME]: remainingTime,
    [TIERS]: tiers,
    [TOKEN]: token,
    [INVOCATION_TIME]: invocationTime,
    [SELECTED_SHOP_ID]: selectedShopId,
    [IS_STARTED_FLOW]: isStartedFlow,
    [IS_SHOW_MODAL]: isShowModal,
    [IS_TIME_OVER]: isTimeOver,
    [CAN_SHOW_JOKER]: canShowJoker,
    [CURRENT_TIER]: currentTier,
    [SELECTED_OFFER]: selectedOffer,
  };
}

const model = createModel();

export default model;
