import { LOCATION_POINT_TYPE } from '@location/constants';
import GoogleLocationPoint from './GoogleLocationPoint';
import GpsLocationPoint from './GpsLocationPoint';
import SearchAreaLocationPoint from './SearchAreaLocationPoint';
import SearchLocationPoint from './SearchLocationPoint';
import UserAddressLocationPoint from './UserAddressLocationPoint';
import SearchColombianFormatLocationPoint from './SearchColombianFormatLocationPoint';

function Constructor(type = '') {
  switch (type) {
    case LOCATION_POINT_TYPE.USER_ADDRESS:
      return UserAddressLocationPoint;
    case LOCATION_POINT_TYPE.SEARCH:
      return SearchLocationPoint;
    case LOCATION_POINT_TYPE.GOOGLE:
      return GoogleLocationPoint;
    case LOCATION_POINT_TYPE.SEARCH_AREA:
      return SearchAreaLocationPoint;
    case LOCATION_POINT_TYPE.SEARCH_COLOMBIAN_FORMAT:
      return SearchColombianFormatLocationPoint;
    default:
      return GpsLocationPoint;
  }
}

export default Constructor;
