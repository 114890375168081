import { updateUser } from '@commons/fwf/instance';
import { logError } from '@logger/sentry';

async function updateFwfBusinessType(businessType) {
  try {
    const contextData = {
      businessType,
    };
    await updateUser({ contextData });
  } catch (error) {
    logError('[updateFwfBusinessType] fails to updateUser');
  }
}

export default updateFwfBusinessType;
