import { connect } from 'react-redux';
import { compose } from 'redux';

import { injectIntl as withIntl } from 'react-intl';

import { createStructuredSelector } from 'reselect';
import getSearchedLocation from '@location/selectors/getSearchedLocation';

const mapStateToProps = createStructuredSelector({
  searchedLocation: getSearchedLocation,
});

const withConnect = connect(
  mapStateToProps,
  null,
);

export default compose(
  withIntl,
  withConnect,
);
