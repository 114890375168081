import { isNullOrUndefined } from '@utils/object';

const RATIO = 2;

const spaces = {
  space_0: '0px',
  space_2: '2px',
  space_3: '3px',
  space_4: '4px',
  space_6: '6px',
  space_8: '8px',
  space_10: '10px',
  space_12: '12px',
  space_16: '16px',
  space_18: '18px',
  space_20: '20px',
  space_24: '24px',
  space_32: '32px',
  space_40: '40px',
};

function spaceRatio(space) {
  return `${space * RATIO}px`;
}

export function spacesFn(top, right, bottom, left) {
  const space = [];
  if (!isNullOrUndefined(top)) space.push(spaceRatio(top));
  if (!isNullOrUndefined(right)) space.push(spaceRatio(right));
  if (!isNullOrUndefined(bottom)) space.push(spaceRatio(bottom));
  if (!isNullOrUndefined(left)) space.push(spaceRatio(left));
  return space.join(' ');
}

export default spaces;
