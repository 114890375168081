import React from 'react';
import { intlShape } from 'react-intl';
import PropTypes from 'prop-types';

import BasicHelmet from '../BasicHelmet';
import homeMessages from './messages';
import connect from './connect';

function HomeHeaders({ intl: { formatMessage }, store }) {
  return (
    <>
      <BasicHelmet
        title={formatMessage(homeMessages.HomeMetaTitle)}
        metaContent={formatMessage(homeMessages.HomeMetaDescription)}
        defaultOgHeaders
        store={store}
      />
    </>
  );
}

HomeHeaders.propTypes = {
  intl: intlShape,
  store: PropTypes.object,
};

export default connect(HomeHeaders);
