import setLongitudeLatitude from './setLongitudeLatitude';
import setPlatformVersion from './setPlatformVersion';

const commonTrackingDataMiddleware = store => next => action => {
  const state = store.getState();
  setLongitudeLatitude(state, action);
  setPlatformVersion();
  next(action);
};

export default commonTrackingDataMiddleware;
