import {
  SHOP_DELIVERY_TYPE,
  SHOP_OPENIN,
  SHOP_IS_OPEN,
  SHOP_IS_CLOSED,
} from '@shared/constants';

export function sortFoodCategory(x, y) {
  if (x.sortingIndex && y.sortingIndex) {
    return x.sortingIndex - y.sortingIndex;
  }

  return y.percentage - x.percentage;
}

export function getTopCategories(shop) {
  if (!shop?.categories) return [];
  return shop.categories.sort(sortFoodCategory).slice(0, 3);
}

export function isOnlyPickUp(delivery) {
  return (
    delivery?.modes?.length === 1 &&
    delivery?.modes.includes(SHOP_DELIVERY_TYPE.PICK_UP)
  );
}

export function getDeliveryTypes(deliveryType) {
  if (!deliveryType) return [];

  return deliveryType === SHOP_DELIVERY_TYPE.ALL
    ? [SHOP_DELIVERY_TYPE.DELIVERY, SHOP_DELIVERY_TYPE.PICK_UP]
    : [deliveryType];
}

export function getShopAreaId(shop) {
  return shop?.address?.area?.id || null;
}

export function getShopCity(shop) {
  return shop?.address?.area?.city || null;
}

export function getShopLink(shop) {
  return shop?.link || null;
}

export function isShippingTypeDelivery(deliveryType) {
  return deliveryType === SHOP_DELIVERY_TYPE.DELIVERY;
}

export function isShippingTypePickUp(deliveryType) {
  return deliveryType === SHOP_DELIVERY_TYPE.PICK_UP;
}

export function isShippingWithPickupAndDelivery(deliveryType) {
  return deliveryType === SHOP_DELIVERY_TYPE.ALL;
}

export function hasPickUpShippingTypeOnly(shop) {
  return isOnlyPickUp(shop?.delivery);
}

export function openedStateIsOpen(opened) {
  return opened === SHOP_IS_OPEN;
}

export function isOpen(shop) {
  return openedStateIsOpen(shop?.opened);
}

export function openedStateIsOpenIn(opened) {
  return opened === SHOP_OPENIN;
}

export function willOpenLater(shop) {
  return openedStateIsOpenIn(shop?.opened);
}

export function willOpenLaterCanPreOrder(shop) {
  return willOpenLater(shop) && Boolean(shop?.acceptsPreOrder);
}
export function canOrder(shop) {
  return isOpen(shop) || willOpenLaterCanPreOrder(shop);
}
export function getShopCashPaymentMethod(shop) {
  if (!shop?.paymentMethods) return null;
  return shop.paymentMethods.find(x => x.uiType === 'CASH');
}

export function capacityCheck(shop) {
  return Boolean(shop?.capacityCheck);
}

export function openedStateIsClosed(opened) {
  return opened === SHOP_IS_CLOSED;
}

export function isClosed(shop) {
  return openedStateIsClosed(shop?.opened);
}

export function hasSkuLimit(shop) {
  return shop?.maxProductQuantity > 0;
}

export function getDeliveryTimeMinMaxMinutes({
  deliveryTimeMinMinutes,
  deliveryTimeMaxMinutes,
}) {
  return deliveryTimeMinMinutes && deliveryTimeMaxMinutes
    ? `${deliveryTimeMinMinutes} - ${deliveryTimeMaxMinutes} min`
    : '';
}

export function getShopFullAddress(shop) {
  const { address } = shop || {};
  if (address?.doorNumber && address?.street)
    return `${address.street} ${address.doorNumber}`;
  return null;
}

export function isShopSupportPickup(shop) {
  const deliveryTypes = getDeliveryTypes(shop?.deliveryType);
  return deliveryTypes.includes(SHOP_DELIVERY_TYPE.PICK_UP);
}
