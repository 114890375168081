/* eslint-disable no-use-before-define */
import querystring from 'query-string';

import { capitalize } from '@utils/string';
import { iteratorToObject } from '@utils/object';
import toLowerCase from '@commons/utils/string/toLowerCase';

import cache, { KEYS, TTLS } from './cache';

const isEncoded = url => url.includes('%3D');

export function unencode(url) {
  let encoded = isEncoded(url);
  let unencodedQuery = encoded ? decodeURIComponent(url) : url;

  while (encoded) {
    unencodedQuery = unencode(unencodedQuery);
    encoded = isEncoded(unencodedQuery);
  }

  return unencodedQuery;
}

export function getMarketingCampaignData() {
  return cache.get(KEYS.MARKETING_CAMPAIGNS);
}

export function getOrderMarketingData(searchGuid) {
  try {
    const marketingData = getMarketingCampaignData();

    if (!marketingData || !searchGuid) return null;

    const { gclid, query, creationDate } = marketingData;

    const orderData = {
      searchGuid,
      gclid,
      rawTracking: query,
      clickDate: creationDate,
    };

    const parsedTracking = { ...marketingData };
    delete parsedTracking.query;
    delete parsedTracking.creationDate;
    orderData.parsedTracking = querystring.stringify(parsedTracking);

    return orderData;
  } catch (error) {
    return null;
  }
}

export function readCampaignsParams(
  href = window.location.href,
  referer = document.referer,
) {
  try {
    const unencodedQuery = unencode(href);

    const actualData = getMarketingCampaignData();
    const url = new URL(unencodedQuery);

    const query = querystring.parse(url.search);

    const isExternalVisit = getIsExternalVisit(referer);

    const saleDeliveryParams = {};

    // if (forwardURI && forwardURI.includes('promo')) {
    //   saleDeliveryParams.pycat = 'offline';
    //   saleDeliveryParams.utm_source = 'flyer';
    //   saleDeliveryParams.utm_medium = 'flyer';
    //   saleDeliveryParams.utm_capaign = 'sale_delivery';
    // }

    const params = { ...query, ...saleDeliveryParams };
    const newData = createTrackingInfo(
      params,
      referer,
      actualData,
      isExternalVisit,
    );

    const shouldUpdateData =
      !actualData ||
      (newData.campaignCategory && newData.campaignCategory !== 'Referer');
    if (shouldUpdateData) {
      newData.query = url.search.slice(1);
      return cache.set(KEYS.MARKETING_CAMPAIGNS, newData, {
        ttl: TTLS.MARKETING_CAMPAIGNS,
      });
    }
    return null;
  } catch (e) {
    return null;
  }
}

export function createTrackingInfo(
  params,
  referer = '',
  actualData = null,
  isExternalVisit = true,
) {
  const { gclid } = params;
  const pycat = toLowerCase(params.pycat);
  const info = { creationDate: new Date().toISOString() };

  const mobileReferer = params.referer
    ? decodeURIComponent(params.referer)
    : null;

  if (!pycat && gclid) {
    // SEM (Generic)
    info.campaignCategory = 'SEM';
    info.campaignSource = 'Google';
    info.campaignName = 'UNKNOWN';
    info.adGroup = 'UNKNOWN';
    info.keyword = 'UNKNOWN';
    info.matchType = 'UNKNOWN';
    info.network = 'UNKNOWN';
    info.device = 'UNKNOWN';
    info.branded = 'UNKNOWN';
    info.gclid = gclid;
  } else if (pycat === 'googlesearch') {
    // SEM (Google Search)
    info.campaignCategory = 'SEM';
    info.campaignSource = 'Google';
    info.campaignName = params.cmp;
    info.adGroup = params.ag;
    info.keyword = params.kwd;
    info.matchType = getMatchType(params.mt);
    info.network = getNetwork(params.net);
    info.device = getDevice(params.dev);
    info.branded = getBranded(params.br);
    info.gclid = gclid;
  } else if (pycat === 'bingadssearch') {
    // SEM (Bing/Yahoo Search)
    info.campaignCategory = 'SEM';
    info.campaignSource = 'Bing';
    info.campaignName = params.cmp;
    info.adGroup = params.ag;
    info.keyword = params.kwd;
    info.matchType = getMatchType(params.mt);
    info.device = getDevice(params.dev);
    info.branded = getBranded(params.br);
  } else if (pycat === 'googledisplay') {
    // Display (Adwords)
    info.campaignCategory = 'Display';
    info.campaignSource = 'Google';
    info.campaignName = params.cmp;
    info.adGroup = params.ag;
    info.keyword = params.kwd;
    info.placement = params.plc;
    info.device = getDevice(params.dev);
    info.gclid = params.gclid;
  } else if (pycat === 'display') {
    // Display
    info.campaignCategory = 'Display';
    info.campaignSource = params.utm_source;
    info.campaignName = params.utm_campaign;
    info.campaignMedium = params.utm_medium;
  } else if (pycat === 'social') {
    // Social Media
    info.campaignCategory = 'Social Media';
    info.campaignSource = params.utm_source;
    info.campaignName = params.utm_campaign;
    info.campaignMedium = params.utm_medium;
  } else if (pycat === 'googleadmob') {
    // Mobile (Adwords)
    info.campaignCategory = 'Mobile';
    info.campaignSource = 'Google';
    info.campaignName = params.cmp;
    info.adGroup = params.ag;
    info.keyword = params.kwd;
    info.device = getDevice(params.dev);
    info.app = params.plc;
    info.gclid = params.gclid;
  } else if (['retention', 'offline'].includes(pycat)) {
    info.campaignCategory = capitalize(pycat);
    info.campaignSource = params.utm_source;
    info.campaignName = params.utm_campaign;
    info.campaignMedium = params.utm_medium;
  } else if (pycat === 'mobile') {
    // Adjust Mobile
    info.campaignCategory = 'Mobile';
    info.campaignSource = params.utm_source;
    info.campaignName = params.cmp;
    info.adGroup = params.pub_id;
    info.click = params.click_time;
    info.install = params.installed_at;
  } else if (params.my_campaign) {
    const myCampaign = toLowerCase(params.my_campaign);
    info.campaignCategory = myCampaign === 'adwordsdisplay' ? 'Display' : 'SEM';
    info.campaignSource = 'Google';
    info.campaignName = params.campaign;
    info.device = params.device;
    info.subPublisher = params.sub_publisher;
    info.branded = myCampaign === 'adwordssearchbrand' || false;
    info.gclid = params.gclid_mat;
    info.click = params.click;
    info.install = params.install;
    info.user = params.user;
  } else if (toLowerCase(params.utm_campaign) === 'widget') {
    // Affiliate
    info.campaignCategory = 'Affiliate';
    info.campaignSource = params.utm_source || referer;
    info.campaignName = params.utm_campaign;
  } else if (params.ref) {
    // Affiliate
    info.campaignCategory = 'Affiliate';
    info.campaignSource = params.utm_source || referer;
    info.campaignName = 'reseller';
  } else if (isSearchRefererDomain(referer) && !pycat && !gclid) {
    // SEO
    info.campaignCategory = 'SEO';
    info.campaignSource = getSeoReferer(referer);
    info.searchTerm = getSeoSearchTerm(referer);
  } else if (pycat === 'seo') {
    // Adjust SEO (llega decoded menos referer)
    info.campaignCategory = 'SEO';
    info.click = params.click_time || null;
    info.install = params.installed_at || null;
    if (!mobileReferer) {
      info.campaignSource = 'NOT PROVIDED';
      info.searchTerm = 'NOT PROVIDED';
    } else if (!isSearchRefererDomain(mobileReferer)) {
      info.campaignSource = 'UNKNOWN';
      info.searchTerm = 'UNKNOWN';
    } else {
      info.campaignSource = mobileReferer
        ? getSeoReferer(mobileReferer)
        : 'UNKNOWN';
      info.searchTerm = mobileReferer
        ? getSeoSearchTerm(mobileReferer)
        : 'UNKNOWN';
    }
  } else if (params.fb_campaign_group_name && params.fb_campaign_name) {
    info.campaignCategory = 'Mobile';
    info.campaignSource = 'Facebook';
    info.campaignName = params.fb_campaign_group_name;
    info.adGroup = params.fb_campaign_name;
    info.click = params.click_time;
    info.install = params.installed_at;
  } else if (params.campaign_name && params.tweet_id) {
    info.campaignCategory = 'Mobile';
    info.campaignSource = 'Twitter';
    info.campaignName = params.campaign_name;
    info.adGroup = params.tweet_id;
    info.click = params.click_time;
    info.install = params.installed_at;
  } else if (!referer && !actualData) {
    // Direct
    info.campaignCategory = 'Direct';
    info.campaignSource = 'Direct';
    info.campaignName = 'Not set';
  } else if (
    !isSearchRefererDomain(referer) &&
    !isFacebookConnect(referer) &&
    isExternalVisit
  ) {
    // Referer
    info.campaignCategory = 'Referer';
    info.campaignSource = referer;
  }

  return info;
}

function getIsExternalVisit(url) {
  const internalUrls = ['pedidosya', 'localhost', '192.168'];
  // TODO: IF REQUEST HAS PLATFORM RETURN FALSE

  return url ? !internalUrls.some(u => url.includes(u)) : true;
}

export function getDevice(dev) {
  switch (dev) {
    case 'c':
      return 'computer';
    case 't':
      return 'tablet';
    default:
      return 'mobile';
  }
}

export function getBranded(br) {
  return br === '1';
}

export function getNetwork(network) {
  switch (network) {
    case 'g':
      return 'Google Search';
    case 's':
      return 'Google Search Partners';
    default:
      return network;
  }
}

export function getMatchType(mt) {
  if (['b', 'bb'].includes(mt)) return 'broad';

  if (['p', 'bp'].includes(mt)) return 'phrase';

  if (['e', 'be'].includes(mt)) return 'exact';

  return mt;
}

function isSearchRefererDomain(referer) {
  const regexp = new RegExp(/.*(google\.\w{2,}|yahoo\.com|bing\.com).*\.*/);
  return regexp.test(referer);
}

export function getSeoReferer(referer) {
  const providers = [
    { name: 'Google', regex: /.*(google\.\w{2,}).*\.*/ },
    { name: 'Yahoo', regex: /.*(yahoo\.com).*\.*/ },
    { name: 'Bing', regex: /.*(bing\.com).*\.*/ },
  ];

  const seoReferer = providers.find(p => p.regex.test(referer));

  return seoReferer ? seoReferer.name : '';
}

export function getSeoSearchTerm(referer) {
  let searchTerm = '(not set)';

  const params = parse(referer);

  const provider = getSeoReferer(referer);

  const queryTerm = ['Yahoo'].includes(provider) ? params.p : params.q;
  if (queryTerm && queryTerm.trim().size() > 0) {
    searchTerm = queryTerm.replace('+', ' ');
  }
  return searchTerm;
}

export function getCookieData(cookie) {
  const c = cookie || {};
  const campaignCategory = toLowerCase(c.campaignCategory);
  const campaignSource = toLowerCase(c.campaignSource);
  const { query } = c;
  return {
    campaignSource,
    query,
    isCampaignCategory: campaign => campaignCategory === campaign,
    isCampaignSource: source => campaignSource === source,
  };
}

function parse(url) {
  const parts = url.split('?');

  let search = parts[0];
  if (parts.length > 1) {
    // eslint-disable-next-line prefer-destructuring
    [search] = parts[1];
  }

  const params = new URLSearchParams(search);

  return iteratorToObject(params.entries());
}

function isFacebookConnect(referer) {
  const regexp = new RegExp(/.*:\/\/.*(l.facebook.com).*\/.*/);
  return regexp.test(referer);
}
