import {
  isConversionRoute,
  isMyOrdersRoute,
  isCourierRoute,
  isValidationAccountRoute,
} from '@services/routes.service';
import { isRouterLocationChangeAction } from '@utils/redux/router';
import clearOrderFlow from '@shared/actions/clearOrderFlow';

const isLeavingOrderFlow = action => {
  const isConversionPrevRoute = isConversionRoute(
    action.payload.prevLocation?.pathname,
  );
  const isConversionNewRoute = isConversionRoute(
    action.payload.location.pathname,
  );
  const isCourierPrevRoute = isCourierRoute(
    action.payload.prevLocation?.pathname,
  );
  const isCourierNewRoute = isCourierRoute(action.payload.location.pathname);

  const isValidationNewRoute = isValidationAccountRoute(
    action.payload.location.pathname,
  );

  // Before navigation to repeatOrder we must check that the shop delivers
  // to user location. If it doesn't and the user closes the modal, the shop
  // reducer remains with data and it must be cleaned when leaving MyOrders
  const isRouteMyOrder = isMyOrdersRoute(action.payload.prevLocation?.pathname);

  if (isConversionPrevRoute && isValidationNewRoute) return false;
  if (isCourierPrevRoute && isValidationNewRoute) return false;
  return (
    (isConversionPrevRoute && !isConversionNewRoute) ||
    (isRouteMyOrder && !isConversionNewRoute) ||
    (isCourierPrevRoute && !isCourierNewRoute)
  );
};

const shouldResetOrderFlow = action =>
  isRouterLocationChangeAction(action) && isLeavingOrderFlow(action);

const orderFlowMiddleware = store => next => action => {
  next(action);
  if (shouldResetOrderFlow(action)) {
    store.dispatch(clearOrderFlow());
  }
};

export default orderFlowMiddleware;
