import toLowerCase from '@commons/utils/string/toLowerCase';
import isObject from '@commons/utils/object/isObject';

export const networkErrorMessage = 'network error';

function isNetworkError(e) {
  if (!isObject(e)) return false;

  const { networkUrl, networkStatus, message } = e;

  const isNetworkErrorMessage =
    toLowerCase(message).indexOf(networkErrorMessage) !== -1;
  const hasNetworkUrl = Boolean(networkUrl);
  const hasNetworkStatus = Boolean(networkStatus);

  return isNetworkErrorMessage || (hasNetworkUrl && !hasNetworkStatus);
}

export default isNetworkError;
