export const MODULE_NAME = 'joker';

export const IS_FETCHING_JOKER = 'isFetching';
export const HAS_FETCHED_JOKER = 'hasFetched';
export const JOKER_OFFERS = 'jokerOffers';
export const ID = 'id';
export const REMAINING_TIME = 'remainingTime';
export const TIERS = 'tiers';
export const TOKEN = 'token';
export const SELECTED_SHOP_ID = 'selectedShopId';
export const INVOCATION_TIME = 'invocationTime';
export const IS_STARTED_FLOW = 'isStartedFlow';
export const IS_SHOW_MODAL = 'isShowModal';
export const IS_TIME_OVER = 'isTimeOver';
export const CAN_SHOW_JOKER = 'canShowJoker';
export const CURRENT_TIER = 'currentTier';
export const SELECTED_OFFER = 'selectedOffer';

export const IMAGE_SIZE = {
  SMALL: 'small',
  BIG: 'big',
};
