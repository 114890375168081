import {
  loadInstance as sdkcLoadInstance,
  setPublishableKey as sdkcSetPublishableKey,
} from './payments/antiFraud/ravelin/ravelin';
import { log } from '@logger';

let type;
let ravelinConfig;
let ravelinInstance;

export const setRavelinConfig = config => {
  log(`[paymentGateway][setRavelinConfig] invocation`);
  log(`[paymentGateway][setRavelinConfig] selected type: ${type}`);
  if (!config) throw new Error('Missing Ravelin config');
  ravelinConfig = config;
  log(`[paymentGateway][setRavelinConfig] keys stored`);
  if (ravelinInstance && ravelinConfig) {
    log(`[paymentGateway][setRavelinConfig] call sdkcSetPublishableKey`);
    sdkcSetPublishableKey(ravelinInstance, ravelinConfig);
  }
};

export async function loadRavelin() {
  log(`[loadRavelin] call sdkcLoadInstance`);
  const sdkInstancePromise = sdkcLoadInstance();
  log(`[loadRavelin] sdkcLoadInstance finished`);
  sdkInstancePromise.then(instance => {
    log(`[loadRavelin] sdkcLoadInstance promise resolved`);
    ravelinInstance = instance;
    if (ravelinConfig) {
      log(`[loadRavelin] keyExist() == true call sdkcSetPublishableKey`);
      sdkcSetPublishableKey(ravelinInstance, ravelinConfig);
    }
  });
}
