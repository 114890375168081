import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { getIsUserLogged } from '@shared/selectors';

import getSearchedLocation from '@location/selectors/getSearchedLocation';
import getShop from '@core/reducers/shop/selectors/getShop';
import getCart from '@core/reducers/cart/selectors/getCart';

import ConversionRoute from './ConversionRoute';

const mapStateToProps = createStructuredSelector({
  isUserLogged: getIsUserLogged,
  cart: getCart,
  shop: getShop,
  searchedLocation: getSearchedLocation,
});

export default connect(mapStateToProps)(ConversionRoute);
