import { createSelector } from 'reselect';

import { SEARCHED_LOCATION } from '@location/model';
import getPropFromModule from './getPropFromModule';

// If we want to be sure that it has city, we should use
// getSearchedLocationWithCity action
const getSearchedLocation = createSelector(
  state => getPropFromModule(state, SEARCHED_LOCATION),
  searchedLocation => searchedLocation?.toJS(),
);

export default getSearchedLocation;
