import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';

import { pushPage, goBack } from '@shared/actions';
import { getQueryAsObject } from '@utils/domRouter';
import { QUERY_PARAM } from '@commons/constants';

function mapStateToProps(state, { location }) {
  const search = getQueryAsObject(location);
  const page = search[QUERY_PARAM.MODAL_PAGE] || '';
  return { page };
}

const mapDispatchToProps = { pushPage, goBack };

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withRouter,
  withConnect,
);
