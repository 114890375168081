import { addLocaleData } from 'react-intl';
import es from 'react-intl/locale-data/es';

import axios from '@services/axios';
import { getCountryShortName, getCountryName } from '@shared/selectors';
import getCurrentUser from '@core/reducers/user/selectors/getCurrentUser';
import getSearchedLocation from '@location/selectors/getSearchedLocation';
import getCountryCulture from '@location/selectors/getCountryCulture';
import { setEnvironment } from '@commons/config';
import { initializeInstance as initializeFWF } from '@commons/fwf/instance';
import { updateFlags } from '@funWithFlags/actions';
import parseToJSON from '@commons/utils/string/parse';
import { logError } from '@logger/sentry';
import { initialize as initializeLogger } from '@app/logger';
import configureStore from '@app/configureStore';
import { loadRequiredPolyfills } from '@app/polyfills';

import {
  getLocationPointCity,
  getLocationPointCityId,
} from '@commons/models/LocationPoint/index';

function getServerValue(key, elementId) {
  const value = window[key];
  delete window[key];

  const element = document.getElementById(elementId);
  if (element) element.remove();

  return parseToJSON(value);
}

function onError(msg, error) {
  const e = new Error(`load.js ${msg}:  ${error.message}`);
  e.stack = error.stack;
  logError(e);
}

async function load({ fwfPromise }) {
  const requiredPolyfillsPromise = loadRequiredPolyfills();
  const stateFromServer = getServerValue('__INITIAL_STATE__', 'initialState');
  const {
    environment,
    gtmConfig,
    sessionId,
    paymentKeys,
    ravelinConfig,
    device,
    googleMapKey,
  } = getServerValue('__CONTEXT_DATA__', 'contextData');

  setEnvironment(environment);

  let store;
  try {
    store = await configureStore({
      initialState: stateFromServer || {},
      axios: axios(),
    });
  } catch (error) {
    onError('configureStore error', error);
  }

  const state = store.getState();
  const lng = getCountryCulture(state);
  const countryShortName = getCountryShortName(state);
  const countryName = getCountryName(state);
  const user = getCurrentUser(state);
  const searchedLocation = getSearchedLocation(state);
  const searchedCity = getLocationPointCity(getSearchedLocation(state));
  const cityId = getLocationPointCityId(searchedLocation);

  const [, messages] = await Promise.all([
    initializeLogger({
      sessionId,
      user,
      searchedLocation,
      countryName,
    }),
    import(
      /* webpackChunkName: "messages-[request]" */ `../../build/translations/${lng}.json`
    ),
  ]);

  try {
    addLocaleData([...es]);
  } catch (error) {
    onError('AddLocaleData set error', error);
  }

  try {
    initializeFWF(
      fwfPromise,
      {
        sessionId,
        userData: { userId: user?.id, userEmail: user?.email },
        contextData: {
          country: countryShortName,
          city: searchedCity?.name,
          cityId,
          device,
        },
      },
      flags => {
        store.dispatch(updateFlags(flags));
      },
    );
  } catch (error) {
    onError('FWF initialize error', error);
  }

  try {
    await requiredPolyfillsPromise;
  } catch (error) {
    onError('polyfills error', error);
  }

  return {
    gtmConfig,
    paymentKeys,
    ravelinConfig,
    device,
    messages,
    store,
    lng,
    googleMapKey,
    countryShortName,
  };
}

export default load;
