import { Map } from 'immutable';

import { INIT_STORE } from '@shared/actionTypes';
import { successAction } from '@utils/actions';

import * as actions from '@core/reducers/user/actionTypes';

const ModuleStateModel = Map({
  ageValidationBirth: null,
});

const initialState = ModuleStateModel;

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.SAVE_AGE_VALIDATION: {
      const { date } = action.payload;
      return state.set('ageValidationBirth', date);
    }

    case actions.SAVE_IS_OF_AGE: {
      const { date } = action.payload;
      return state.set('ageValidationBirth', date);
    }

    case successAction(actions.UPDATE_USER_INFO):
      if (state.get('ageValidationBirth'))
        return state.set('ageValidationBirth', null);
      return state;

    case INIT_STORE:
      return ModuleStateModel.mergeDeep(state);

    default:
      return state;
  }
};
