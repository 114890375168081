import { fromJS } from 'immutable';

export const IS_FETCHING = 'isFetching';
export const HAS_FETCHED = 'hasFetchedMenu';
export const DATA = 'data';
export const FETCHED_DATE = 'fetchedDate';
export const FILTER = 'filter';
export const TOOLTIP_FAVORITE_DISH = 'tooltipFavoriteDish';

export const createModel = ({
  isFetching = false,
  hasFetched = false,
  data = null,
  fetchedDate = null,
  filter = '',
  tooltipFavoriteDish = { show: false, title: '' },
} = {}) =>
  fromJS({
    [DATA]: data,
    [IS_FETCHING]: isFetching,
    [HAS_FETCHED]: hasFetched,
    [FETCHED_DATE]: fetchedDate,
    [FILTER]: filter,
    [TOOLTIP_FAVORITE_DISH]: tooltipFavoriteDish,
  });

const model = createModel();

export default model;
