/* eslint-disable no-underscore-dangle */

const HIGH = 'HIGH';
const MEDIUM = 'MEDIUM';
const LOW = 'LOW';

export const PRIORITY = { HIGH, MEDIUM, LOW };

class PriorityQueue {
  constructor() {
    this._queues = { [HIGH]: [], [MEDIUM]: [], [LOW]: [] };
    this.length = 0;
  }

  _shift(priority) {
    const queue = this._queues[priority];
    this.length -= 1;
    return queue.shift();
  }

  _push(item, priority) {
    this._queues[priority].push(item);
    this.length += 1;
  }

  pop = () => {
    if (this._queues[HIGH].length) return this._shift(HIGH);
    if (this._queues[MEDIUM].length) return this._shift(MEDIUM);
    return this.length ? this._shift(LOW) : undefined;
  };

  pushHighPriority = item => {
    this._push(item, HIGH);
  };

  pushMediumPriority = item => {
    this._push(item, MEDIUM);
  };

  pushLowPriority = item => {
    this._push(item, LOW);
  };
}

export default PriorityQueue;
