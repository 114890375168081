import { LOCATION_POINT_TYPE } from '@location/constants';
import { isNullOrUndefined } from '@utils/object';
import isObject from '@commons/utils/object/isObject';
import getNestedProp from '@commons/utils/object/getNestedProp';
import LocationPoint from './constructors/LocationPoint';
import mapToLocationPoint from './mappers/mapToLocationPoint';
import getLocationPointArea from './getters/getArea';
import getLocationPointType from './getters/getType';
import getLocationPointId from './getters/getId';
import getLocationPointAddress from './getters/getAddress';
import getLocationPointStreetType from './getters/getStreetType';

const LocationPointModel = LocationPoint;
export default LocationPointModel;

// ********************** Checkers **********************
export function isLocationPointFromSearch(point) {
  return getLocationPointType(point) === LOCATION_POINT_TYPE.SEARCH;
}

export function isLocationPointFromGoogle(point) {
  return getLocationPointType(point) === LOCATION_POINT_TYPE.GOOGLE;
}

export function isLocationPointFromUserAddress(point) {
  return getLocationPointType(point) === LOCATION_POINT_TYPE.USER_ADDRESS;
}

export function isLocationPointFromGPS(point) {
  return getLocationPointType(point) === LOCATION_POINT_TYPE.GPS;
}

export function isLocationPointFromAddressForm(point) {
  return (
    isLocationPointFromUserAddress(point) &&
    (isNullOrUndefined(point.latitude) || isNullOrUndefined(point.longitude))
  );
}

// ********************** Getters **********************

export function getLocationPointCityName(location) {
  return (
    getNestedProp(location, 'cityName') ||
    getNestedProp(location, 'city.addressName') ||
    getNestedProp(location, 'city.name')
  );
}

export function getLocationPointCityId(location) {
  return getNestedProp(location, 'city.id');
}

export function getLocationPointCity(location) {
  return getNestedProp(location, 'city');
}

export function getLocationPointAreaId(location) {
  const area = getLocationPointArea(location);
  return isObject(area) ? getNestedProp(area, 'id') : area;
}

export function getLocationPointWithGuessedType(point) {
  if (!point || getLocationPointType(point)) return point;

  let type = LOCATION_POINT_TYPE.GPS;

  if (getLocationPointId(point)) {
    type = LOCATION_POINT_TYPE.USER_ADDRESS;
  } else if (getLocationPointStreetType(point)) {
    type = LOCATION_POINT_TYPE.SEARCH_COLOMBIAN_FORMAT;
  } else if (getLocationPointAddress(point)) {
    type = LOCATION_POINT_TYPE.SEARCH;
  } else if (getLocationPointAreaId(point)) {
    type = LOCATION_POINT_TYPE.SEARCH_AREA;
  }

  const guessedPoint = mapToLocationPoint(point, type);
  guessedPoint.guessed = true;
  return guessedPoint;
}
