import React from 'react';
import PropTypes from 'prop-types';

// pages
import MyAddresses from '@location/containers/MyAddresses/Loadable';
import CreateAddressForm from '@location/containers/CreateAddressForm/Loadable';
import UpdateAddressForm from '@location/containers/UpdateAddressForm/Loadable';

import { ROUTES } from '@services/routes.service';
import { getLastUrlParam } from '@utils/url';
import { isNumber } from '@utils/number';

import ModalManager, {
  ModalManagerPage,
} from '@shared/components/ModalManager';

function MyAddressesRouter({ location }) {
  if (!location) return null;
  const { pathname } = location;
  const currentAddressId = getLastUrlParam(pathname);

  const isUpdateAddressesActive =
    isNumber(currentAddressId) &&
    pathname === `${ROUTES.MY_ADDRESSES}/${currentAddressId}`;

  return (
    <ModalManager>
      <ModalManagerPage default id="addresses" render={() => <MyAddresses />} />
      <ModalManagerPage
        isActive={pathname === `${ROUTES.MY_ADDRESSES}/new`}
        id="create-address"
        render={() => <CreateAddressForm location={location} />}
      />
      <ModalManagerPage
        isActive={isUpdateAddressesActive}
        id="update-address"
        render={() => (
          <UpdateAddressForm addressId={Number(currentAddressId)} />
        )}
      />
    </ModalManager>
  );
}

MyAddressesRouter.propTypes = {
  location: PropTypes.object.isRequired,
};

export default MyAddressesRouter;
