import getInstance, { setInstance } from './instance';
import initializeSentry from './initialize';
import { INFO_LEVEL } from '../constants';

let buffer = [];

export function logError(error, data) {
  const instance = getInstance();
  if (instance) {
    if (data) instance.captureException(error, data);
    else instance.captureException(error);
  } else {
    buffer.push({ error, data });
  }
}

// We use prefix, to avoid confusing events that aren't from live env.
export function logEvent(event, { data, level = INFO_LEVEL } = {}) {
  const instance = getInstance();
  const message = `${event}`;
  if (instance) {
    instance.withScope(scope => {
      scope.setLevel(level);
      scope.setFingerprint([message]);
      if (data) scope.setExtra('data', data);
      instance.captureMessage(message);
    });
  } else {
    buffer.push({ error: false, data, message });
  }
}

function onSentryInitialized() {
  buffer.forEach(({ error, data, message }) => {
    if (error) logError(error, data);
    else logEvent(message, data);
  });
  buffer = [];
}

export async function initialize(sessionId, extraData) {
  const instance = await initializeSentry(sessionId, extraData);
  setInstance(instance);
  onSentryInitialized();
}
