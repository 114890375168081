import { fromJS } from 'immutable';

import { INIT_STORE } from '@shared/actionTypes';
import { successAction, failureAction } from '@utils/actions';

import ModuleStateModel from './model';

import {
  FETCH_JOKER,
  SET_SELECTED_SHOP_ID,
  RESET_STATE,
  SET_HAS_FETCHED_TO_TRUE,
  START_FLOW,
  OUT_JOKER_FLOW,
  SHOW_COUNT_DOWN_MODAL,
  HIDE_COUNT_DOWN_MODAL,
  SET_IS_TIME_OVER,
  FINISH_ORDER_JOKER,
  SET_SELECTED_OFFER,
  SET_CURRENT_TIER,
} from './actionTypes';
import {
  IS_FETCHING_JOKER,
  HAS_FETCHED_JOKER,
  ID,
  REMAINING_TIME,
  TIERS,
  TOKEN,
  JOKER_OFFERS,
  SELECTED_SHOP_ID,
  INVOCATION_TIME,
  IS_STARTED_FLOW,
  IS_SHOW_MODAL,
  IS_TIME_OVER,
  CAN_SHOW_JOKER,
  SELECTED_OFFER,
  CURRENT_TIER,
} from './constants';

const initialState = ModuleStateModel;

function jokerModuleReducer(state = initialState, action) {
  switch (action.type) {
    case INIT_STORE:
      return ModuleStateModel.mergeDeep(state);

    case FETCH_JOKER:
      return state.set(IS_FETCHING_JOKER, true);

    case successAction(FETCH_JOKER): {
      const {
        id,
        remainingTime,
        tiers,
        token,
        offers,
        invocationTime,
      } = action.payload;
      return state
        .set(ID, id)
        .set(REMAINING_TIME, remainingTime)
        .set(TIERS, fromJS(tiers))
        .set(TOKEN, token)
        .set(JOKER_OFFERS, fromJS(offers))
        .set(IS_FETCHING_JOKER, false)
        .set(SELECTED_SHOP_ID, null)
        .set(INVOCATION_TIME, invocationTime);
    }

    case failureAction(FETCH_JOKER):
      return state.set(IS_FETCHING_JOKER, false).set(HAS_FETCHED_JOKER, false);

    case successAction(SET_SELECTED_SHOP_ID): {
      return state.set(SELECTED_SHOP_ID, action.payload);
    }

    case successAction(RESET_STATE): {
      return initialState;
    }

    case SET_HAS_FETCHED_TO_TRUE: {
      return state.set(HAS_FETCHED_JOKER, true);
    }

    case START_FLOW: {
      return state.set(IS_STARTED_FLOW, true);
    }

    case successAction(OUT_JOKER_FLOW): {
      return state
        .set(TIERS, null)
        .set(ID, null)
        .set(IS_STARTED_FLOW, false)
        .set(HAS_FETCHED_JOKER, true)
        .set(IS_TIME_OVER, false);
    }

    case failureAction(OUT_JOKER_FLOW): {
      return state.set(HAS_FETCHED_JOKER, true);
    }

    case SHOW_COUNT_DOWN_MODAL: {
      return state.set(IS_SHOW_MODAL, true);
    }

    case HIDE_COUNT_DOWN_MODAL: {
      return state.set(IS_SHOW_MODAL, false);
    }

    case SET_IS_TIME_OVER: {
      return state.set(IS_TIME_OVER, true);
    }

    case FINISH_ORDER_JOKER: {
      return state
        .set(TIERS, initialState[TIERS])
        .set(ID, initialState[ID])
        .set(IS_STARTED_FLOW, initialState[IS_STARTED_FLOW])
        .set(HAS_FETCHED_JOKER, true)
        .set(IS_TIME_OVER, false)
        .set(JOKER_OFFERS, initialState[JOKER_OFFERS])
        .set(CAN_SHOW_JOKER, false);
    }
    case SET_SELECTED_OFFER: {
      return state.set(SELECTED_OFFER, fromJS(action.payload));
    }
    case SET_CURRENT_TIER: {
      return state.set(CURRENT_TIER, action.payload);
    }
    default:
      return state;
  }
}

export default jokerModuleReducer;
