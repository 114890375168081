import { createSelector } from 'reselect';
import getIsQuickCommerce from '@core/reducers/menu/selectors/getIsQuickCommerce';
import { MENU_LAYOUT_NAME } from '../constants';
import getMenuLayout from './getMenuLayout';

const getIsShelvesMenuLayout = createSelector(
  getMenuLayout,
  getIsQuickCommerce,
  (layout, isQuickCommerce) => {
    if (isQuickCommerce) {
      return true;
    }

    return layout ? layout === MENU_LAYOUT_NAME.SHELVES : false;
  },
);

export default getIsShelvesMenuLayout;
