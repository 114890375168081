import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { pushPage } from '@shared/actions';
import { getSearchAsObject } from '@shared/selectors';

const mapStateToProps = createStructuredSelector({
  search: getSearchAsObject,
});

const mapDispatchToProps = { pushPage };

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect);
