import { LOGIN_EXTERNAL } from '@core/reducers/user/actionTypes';
import { successAction } from '@utils/actions';
import getSessionId from '@core/reducers/user/selectors/getSessionId';
import { updateUser as updateFWFUser } from '@commons/fwf/instance';
import updateSentryUser from '@logger/sentry/updateUser';

const actionTypes = [successAction(LOGIN_EXTERNAL)];

const userMiddleware = store => next => action => {
  try {
    if (actionTypes.includes(action.type)) {
      const { user } = action.payload;

      const state = store.getState();
      const sessionId = getSessionId(state);

      updateFWFUser({
        sessionId,
        userData: { userEmail: user.email, userId: user.id },
      });
      updateSentryUser(sessionId, user);
    }

    next(action);
  } catch (error) {
    next(action);
  }
};

export default userMiddleware;
