function User({
  name,
  lastName,
  email,
  nickname,
  avatar,
  mobile,
  companyName,
  companyNumber,
}) {
  return {
    name,
    lastName,
    email,
    nickname,
    avatar,
    notifications: {
      mobile,
    },
    companyName,
    companyNumber,
  };
}

export default User;
