export function checkIsLoaded({ id }) {
  return !!document.getElementById(id);
}

function loadScript({ src, callback, domElement = document.head, id }) {
  if (id && checkIsLoaded({ id })) {
    if (callback) callback();
  } else {
    const script = document.createElement('script');

    script.type = 'text/javascript';
    script.src = src;
    script.defer = true;

    if (callback) {
      script.onreadystatechange = callback;
      script.onload = callback;
    }

    if (id) script.id = id;

    domElement.appendChild(script);
  }
}

export default loadScript;
