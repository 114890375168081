import { fromJS } from 'immutable';

import { INIT_STORE } from '@shared/actionTypes';
import { failureAction, successAction } from '@utils/actions';
import User from './models/User';
import * as actions from './actionTypes';
import model, {
  DATA,
  IS_UPDATING_USER,
  GETTING_TC,
  AGREEING_TC,
  IS_POSTING_LOGIN,
} from './model';

const COMPANY_NAME = 'companyName';
const COMPANY_NUMBER = 'companyNumber';

function coreUserReducer(state = model, action) {
  switch (action.type) {
    case actions.LOGIN_EXTERNAL:
      return state.set(IS_POSTING_LOGIN, true);

    case successAction(actions.LOGIN_EXTERNAL):
      return state
        .set(IS_POSTING_LOGIN, false)
        .set(DATA, fromJS(action.payload.user));

    case failureAction(actions.LOGIN_EXTERNAL):
      return state.set(IS_POSTING_LOGIN, false);

    case actions.UPDATE_USER_INFO:
      return state.set(IS_UPDATING_USER, true);

    case successAction(actions.UPDATE_USER_INFO):
      return state
        .set(DATA, state.get(DATA).mergeDeep(fromJS(User(action.payload))))
        .set(IS_UPDATING_USER, false);

    case failureAction(actions.UPDATE_USER_INFO):
    case failureAction(actions.UPDATE_COMPANY_BILLING):
      return state.set(IS_UPDATING_USER, false);

    case actions.UPDATE_COMPANY_BILLING:
      return state.set(IS_UPDATING_USER, true);

    case successAction(actions.UPDATE_COMPANY_BILLING):
      return state
        .set(IS_UPDATING_USER, false)
        .setIn([DATA, COMPANY_NAME], action.payload.companyName)
        .setIn([DATA, COMPANY_NUMBER], action.payload.companyNumber);

    case actions.GET_STATUS_TERMS_AND_CONDITIONS:
      return state.set(GETTING_TC, true);

    case successAction(actions.GET_STATUS_TERMS_AND_CONDITIONS):
    case failureAction(actions.GET_STATUS_TERMS_AND_CONDITIONS):
      return state.set(GETTING_TC, false);

    case actions.AGREE_TERMS_AND_CONDITIONS:
      return state.set(AGREEING_TC, true);

    case successAction(actions.AGREE_TERMS_AND_CONDITIONS):
    case failureAction(actions.AGREE_TERMS_AND_CONDITIONS):
      return state.set(AGREEING_TC, false);

    case INIT_STORE:
      return model.mergeDeep(state);

    default:
      return state;
  }
}

export default coreUserReducer;
