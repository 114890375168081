import { fromJS } from 'immutable';

export const SHOPS = 'shops';
export const IS_FETCHING = 'isFetching';
export const TOTAL_RESULTS = 'totalResults';
export const LOCATION = 'location';
export const CITY = 'city';
export const AREA = 'area';
export const CATEGORY = 'category';

export const createModel = ({
  shops = [],
  isFetching = false,
  totalResults = 0,
  city = null,
  area = null,
  category = null,
} = {}) =>
  fromJS(
    createModelJS({
      shops,
      isFetching,
      totalResults,
      city,
      area,
      category,
    }),
  );

export function createModelJS({
  shops = [],
  isFetching = false,
  totalResults = 0,
  city = null,
  area = null,
  category = null,
}) {
  return {
    [SHOPS]: shops,
    [IS_FETCHING]: isFetching,
    [TOTAL_RESULTS]: totalResults,
    [LOCATION]: {
      [CITY]: city,
      [AREA]: area,
      [CATEGORY]: category,
    },
  };
}

const model = createModel();

export default model;
