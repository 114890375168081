import sortObjectKeys from './sortKeys';

function sortObject(obj) {
  if (!obj) return obj;
  return sortObjectKeys(obj).reduce((o, k) => {
    o[k] = obj[k];
    return o;
  }, {});
}

export default sortObject;
