const position = {
  // Modal zIndex is 1000, the rest should be less
  // so that the modal stays on top
  modal: 1000,
  mainHeader: 901,
  headerWithSidebar: 999,
  headerSearchPage: 999,
  fixedHeader: 100,
  sidebar: 10000,
  defaultToast: 9000,
  defaultHeader: 1,
  one: 1,
  zero: 0,
  coachMark: 9999,
  get secondHeader() {
    return this.mainHeader + 2;
  },
  get tooltip() {
    return this.mainHeader + 1;
  },
  get noLocationPointToast() {
    return this.mainHeader;
  },
  get bottomSheet() {
    return this.mainHeader + 1;
  },
};

export default position;
