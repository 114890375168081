import { createSelector } from 'reselect';

import getPropFromModule from './getPropFromModule';

const getShop = createSelector(
  state => getPropFromModule(state, 'data'),
  shop => shop?.toJS(),
);

export default getShop;
