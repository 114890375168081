function replace(str, values) {
  return str.replace(/\$(\w+_*d*)/g, (_, $1) => values?.[$1] || '');
}

export function mapValues(obj, values) {
  Object.keys(obj).forEach(key => {
    // eslint-disable-next-line no-param-reassign
    if (typeof obj[key] === 'string') obj[key] = replace(obj[key], values);
    else if (typeof obj[key] === 'object') mapValues(obj[key], values);
  });
}
