import Constructor from '../constructors';

function mapToLocationPoint(points, type) {
  if (!points) return points;
  const constructor = Constructor(type);
  return Array.isArray(points)
    ? points.map(p => constructor(p))
    : constructor(points);
}

export default mapToLocationPoint;
