import { LOCATION_POINT_TYPE, SEARCH_FORM_KEY } from '@location/constants';
import LocationPoint from './LocationPoint';
import toStringColombianFormat from '../mappers/toStringColombianFormat';

const { STREET_TYPE } = SEARCH_FORM_KEY;

function SearchColombianFormatLocationPoint({
  [STREET_TYPE]: streetType,
  street,
  corner,
  doorNumber,
  cityName,
  validated,
  latitude, // only server side.
  longitude, // only server side.
}) {
  return LocationPoint({
    address: toStringColombianFormat({
      streetType,
      street,
      corner,
      doorNumber,
    }),
    cityName,
    latitude,
    longitude,
    validated,
    street,
    corner,
    doorNumber,
    [STREET_TYPE]: streetType,
    type: LOCATION_POINT_TYPE.SEARCH_COLOMBIAN_FORMAT,
  });
}

export default SearchColombianFormatLocationPoint;
