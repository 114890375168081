import { isRouterLocationChangeAction } from '@utils/redux/router';
import { generateURL } from '@utils/url';
import isNetworkError from '@utils/network/isNetworkError';
import { log, error } from './index';

const getActionLogMessage = action => {
  const { type } = action;
  const logBody = action.log || '';
  if (isRouterLocationChangeAction(action)) {
    return `${type} to: ${generateURL(action.payload.location)}`;
  }

  if (logBody) {
    return `${type} - ${JSON.stringify(logBody)}`;
  }

  return type;
};

export const Logger = () => next => action => {
  try {
    if (action.error) {
      if (!isNetworkError(action.error)) {
        error(action.type, { error: action.error, data: action });
      }
    } else {
      logAction(action);
    }

    return next(action);
  } catch (err) {
    error('[REDUX_LOGGER_EXCEPTION]', { error: err });
    throw err;
  }
};

function logAction(action) {
  const message = getActionLogMessage(action);
  log(message);
}
