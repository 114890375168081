import { createSelector } from 'reselect';

import getPropFromModule from './getPropFromModule';

const getCurrentUser = createSelector(
  state => getPropFromModule(state, 'data'),
  user => user && user.toJS(),
);

export default getCurrentUser;
