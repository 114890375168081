import { createSelector } from 'reselect';

import getBusinessTypeFromQuery from '@shared/selectors/getBusinessTypeFromQuery';
import { getCountryShortName, getCountryVerticals } from '@shared/selectors';
import { getCountryCategorySuffix } from '@cityLanding/utils';
import { getSelectedVertical } from '@utils/shopsList';
import { BUSINESS_TYPE } from '@commons/constants';

import { MODULE_NAME } from './constants';
import {
  SHOPS,
  IS_FETCHING,
  LOCATION,
  TOTAL_RESULTS,
  CITY,
  AREA,
  CATEGORY,
} from './model';

const getPropFromModule = (state, ...props) =>
  state.getIn([MODULE_NAME, ...props]);

export const getShops = createSelector(
  state => getPropFromModule(state, SHOPS),
  shops => shops.toJS(),
);

export const getIsFetchingShops = createSelector(
  state => getPropFromModule(state, IS_FETCHING),
  isFetching => isFetching,
);

export const getTotalResults = createSelector(
  state => getPropFromModule(state, TOTAL_RESULTS),
  totalResults => totalResults,
);

const getSelectedAreaImmutable = createSelector(
  state => getPropFromModule(state, LOCATION),
  location => location && location.get(AREA),
);

export const getSelectedArea = createSelector(
  getSelectedAreaImmutable,
  area => area?.toJS(),
);

export const getSelectedAreaId = createSelector(
  getSelectedArea,
  area => area?.id,
);

export const getSelectedAreaName = createSelector(
  getSelectedArea,
  area => area?.name,
);

const getSelectedCategoryImmutable = createSelector(
  state => getPropFromModule(state, LOCATION),
  location => location && location.get(CATEGORY),
);

export const getSelectedCategory = createSelector(
  getSelectedCategoryImmutable,
  category => category?.toJS(),
);

export const getSelectedCategoryId = createSelector(
  getSelectedCategory,
  category => category?.id,
);

export const getSelectedCategoryName = createSelector(
  getSelectedCategory,
  category => category?.name,
);

const getSelectedCityImmutable = createSelector(
  state => getPropFromModule(state, LOCATION),
  location => location && location.get(CITY),
);

export const getSelectedCity = createSelector(
  getSelectedCityImmutable,
  city => city?.toJS(),
);

export const getSelectedCityId = createSelector(
  getSelectedCity,
  city => city?.id,
);

export const getSelectedCityName = createSelector(
  getSelectedCity,
  city => city?.name,
);

export const getCategorySuffix = createSelector(
  getCountryShortName,
  country => getCountryCategorySuffix(country),
);

export const getBusinessType = createSelector(
  getBusinessTypeFromQuery,
  getCountryVerticals,
  (businessType, verticals) => {
    const selectedVertical = getSelectedVertical(verticals, businessType);
    return selectedVertical?.businessType || BUSINESS_TYPE.RESTAURANT;
  },
);
