import { initialize as initializeGeolocation } from '@utils/navigatorGeolocation';
import { readCampaignsParams } from '@services/marketing.service';
import { setPaymentGatewayKeys } from '@services/paymentGateway.service';
import { setRavelinConfig } from '@services/ravelin.service';
import loadGoogleMapsScript from '@utils/loadGoogleMapsScript';
import { checkForExternalLogin } from '@shared/actions';
import { getCountryShortName } from '@shared/selectors';
import onGoogleMapLoaded from '@location/actions/onGoogleMapLoaded';
import getCountryLanguage from '@location/selectors/getCountryLanguage';
import { setFixedHeightOnAndroid } from '@utils/utils';
import TaskScheduler from '@utils/browser/TaskScheduler';
import { initialize as initializeTracking } from '@tracking';
import preloadApp from '@app/preloadApp';

async function afterHydrate({
  paymentKeys,
  ravelinConfig,
  store,
  gtmConfig,
  googleMapKey,
  trackerPromise,
}) {
  await initializeGeolocation();

  setPaymentGatewayKeys(paymentKeys);
  setRavelinConfig(ravelinConfig);

  window.onMapLoad = () => {
    store.dispatch(onGoogleMapLoaded());
    delete window.onMapLoad;
  };

  const state = store.getState();

  const countryLanguage = getCountryLanguage(state);
  const countryShortName = getCountryShortName(state);
  if (countryLanguage && countryShortName) {
    // allow rollout
    const mapKeyValue = googleMapKey;
    TaskScheduler.scheduleHighPriority(() =>
      loadGoogleMapsScript({
        countryLanguage,
        googleMapKey: mapKeyValue,
      }),
    );
  }

  // Check for external login
  store.dispatch(checkForExternalLogin());

  setFixedHeightOnAndroid();
  initializeTracking(gtmConfig, trackerPromise);

  preloadApp();

  readCampaignsParams();
}

export default afterHydrate;
