export const INIT_STORE = 'app/shared/INIT_STORE';

export const OPEN_SIDEBAR = 'app/shared/OPEN_SIDEBAR';
export const CLOSE_SIDEBAR = 'app/shared/CLOSE_SIDEBAR';

export const SHOW_TOAST = 'app/shared/SHOW_TOAST';
export const HIDE_TOAST = 'app/shared/HIDE_TOAST';

export const SHOW_ERROR_PAGE = 'app/shared/ERROR_PAGE';

export const GOOGLE_MAP_LOADED = 'app/shared/GOOGLE_MAP_LOADED';

export const CLEAR_ORDER_FLOW = 'app/shared/CLEAR_ORDER_FLOW';
export const UPDATE_IS_DESKTOP_LAYOUT = 'app/shared/UPDATE_IS_DESKTOP_LAYOUT';

export const SET_MAIN_CONTENT_ID = 'app/shared/SET_MAIN_CONTENT_ID';
