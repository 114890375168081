import { fromJS } from 'immutable';

import { successAction, failureAction } from '@utils/actions';
import toObject from '@utils/array/toObject';
import { INIT_STORE } from '@shared/actionTypes';
import AddressModel from './models/Address';

import * as actions from './actionTypes';
import model, {
  DATA,
  IS_FETCHING_ADDRESSES,
  IS_FETCHING_CREATE,
  IS_FETCHING_DELETE,
  IS_FETCHING_UPDATE,
  HAS_FETCHED_ADDRESSES,
} from './model';

function coreAddressesReducer(state = model, action) {
  switch (action.type) {
    case INIT_STORE:
      return model.mergeDeep(state);

    case actions.FETCH_ADDRESSES:
      return state
        .set(IS_FETCHING_ADDRESSES, true)
        .set(HAS_FETCHED_ADDRESSES, false);

    case successAction(actions.FETCH_ADDRESSES):
      return state
        .set(IS_FETCHING_ADDRESSES, false)
        .set(HAS_FETCHED_ADDRESSES, true)
        .set(DATA, fromJS(toObject(action.payload, { mapFn: AddressModel })));

    case failureAction(actions.FETCH_ADDRESSES):
      return state
        .set(IS_FETCHING_ADDRESSES, false)
        .set(HAS_FETCHED_ADDRESSES, false);

    case actions.CREATE_ADDRESS:
      return state.set(IS_FETCHING_CREATE, true);

    case successAction(actions.CREATE_ADDRESS): {
      const id = `${action.payload.id}`;
      return state
        .set(IS_FETCHING_CREATE, false)
        .set(HAS_FETCHED_ADDRESSES, false)
        .setIn([DATA, id], fromJS(AddressModel(action.payload)));
    }

    case failureAction(actions.CREATE_ADDRESS):
      return state.set(IS_FETCHING_CREATE, false);

    case actions.UPDATE_ADDRESS:
      return state.set(IS_FETCHING_UPDATE, true);

    case successAction(actions.UPDATE_ADDRESS): {
      const { payload } = action;
      const id = `${payload.id}`;
      const updatedAddress = state.getIn([DATA, id]);
      return state
        .set(IS_FETCHING_UPDATE, false)
        .set(HAS_FETCHED_ADDRESSES, false)
        .setIn(
          [DATA, id],
          updatedAddress.mergeDeep(fromJS(AddressModel(payload))),
        );
    }

    case failureAction(actions.UPDATE_ADDRESS):
      return state.set(IS_FETCHING_UPDATE, false);

    case actions.DELETE_ADDRESS:
      return state.set(IS_FETCHING_DELETE, true);

    case successAction(actions.DELETE_ADDRESS):
      return state
        .set(IS_FETCHING_DELETE, false)
        .deleteIn([DATA, `${action.payload.addressId}`]);

    case failureAction(actions.DELETE_ADDRESS):
      return state.set(IS_FETCHING_DELETE, false);

    default:
      return state;
  }
}

export default coreAddressesReducer;
