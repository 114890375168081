import { fromJS, List, Map } from 'immutable';

export const SWIMLANES = 'swimlanes';
export const IS_FETCHING_SWIMLANES = 'isFetchingSwimlanes';
export const INFO = 'info';
export const EVENTS = 'events';
export const FETCHED_DATE = 'fetchedDate';
export const FILTERS = 'filters';
export const VENDORS_LIST = 'vendorsData';
export const IS_FETCHING_VENDORS = 'isFetchingVendors';
export const TOTAL_VENDORS_LIST = 'totalVendorsList';
export const HAS_FETCHED_VENDORS = 'hasFetchedVendors';
export const MIDAS_REQUEST_ID = 'midasRequestId';

export const createModel = ({
  swimlanes = List(),
  isFetchingSwimlanes = false,
  info = Map(),
  events = List(),
  fetchedDate = null,
  filters = Map({
    data: Map({
      groups: List(),
    }),
    loading: false,
  }),
  vendorsData = List(),
  isFetchingVendors = false,
  totalVendorsList = 0,
  hasFetchedVendors = false,
} = {}) =>
  fromJS({
    [SWIMLANES]: swimlanes,
    [IS_FETCHING_SWIMLANES]: isFetchingSwimlanes,
    [INFO]: info,
    [EVENTS]: events,
    [FETCHED_DATE]: fetchedDate,
    [FILTERS]: filters,
    [VENDORS_LIST]: vendorsData,
    [IS_FETCHING_VENDORS]: isFetchingVendors,
    [TOTAL_VENDORS_LIST]: totalVendorsList,
    [HAS_FETCHED_VENDORS]: hasFetchedVendors,
  });

const model = createModel();

export default model;
