export const INIT_CART = 'app/core/reducers/cart/INIT_CART';
export const DELETE_CART = 'app/core/reducers/cart/DELETE_CART';
export const ADD_LINE = 'app/core/reducers/cart/ADD_LINE';
export const UPDATE_LINE = 'app/core/reducers/cart/UPDATE_LINE';
export const DELETE_LINES = 'app/core/reducers/cart/DELETE_LINES';
export const DELETE_NOT_AVAILABLE_LINES =
  'app/core/reducers/cart/DELETE_NOT_AVAILABLE_LINES';
export const SEND_ORDER = 'app/core/reducers/cart/SEND_ORDER';
export const CHANGE_ADDRESS = 'app/core/reducers/cart/CHANGE_ADDRESS';
export const UPDATE_CART_DELIVERY_TYPE =
  'app/core/reducers/cart/UPDATE_CART_DELIVERY_TYPE';
export const UPDATE_CART_PHONE = 'app/core/reducers/cart/UPDATE_CART_PHONE';
export const UPDATE_CART_DELIVERY_TIME =
  'app/core/reducers/cart/UPDATE_CART_DELIVERY_TIME';
export const UPDATE_CART_NOTES = 'app/core/reducers/cart/UPDATE_CART_NOTES';
export const MATCH_ADDRESS = 'app/core/reducers/cart/MATCH_ADDRESS';
export const SET_FROM_ORDER_ID = 'app/core/reducers/cart/SET_FROM_ORDER_ID';
export const RESET_OUT_OF_DELIVERY_ERROR =
  'app/core/reducers/cart/RESET_OUT_OF_DELIVERY_ERROR';
export const AGE_VALIDATION_PRODUCTS =
  'app/core/reducers/cart/AGE_VALIDATION_PRODUCTS';
export const USER_VALIDATE_RELIABLE_DNI =
  'app/core/reducers/cart/USER_VALIDATE_RELIABLE_DNI';
export const PRODUCT_OPTIONS_REPLACE =
  'app/core/reducers/cart/PRODUCT_OPTIONS_REPLACE';
export const UPDATE_CART_MISSING_PRODUCT_OPTION =
  'app/core/reducers/cart/UPDATE_CART_MISSING_PRODUCT_OPTION';
