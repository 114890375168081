import { fromJS } from 'immutable';

import { getShopDetailsRoute } from '@services/routes.service';
import { INIT_STORE } from '@shared/actionTypes';
import {
  TOGGLE_FAVORITES,
  FETCH_SHOP,
  SET_SHOP,
  FETCH_VENDOR_CONFIG_NOTES,
} from '@core/reducers/shop/actionTypes';
import { successAction, failureAction } from '@utils/actions';
import { isNullOrUndefined } from '@utils/object';
import { getShopCity, getShopLink } from '@utils/shop';
import orderFlowReducer from '@core/utils/orderFlowReducer';

import model, {
  DATA,
  IS_FETCHING,
  HAS_FETCHED,
  IS_FETCHING_VENDOR_CONFIG_NOTES,
  HIDE_NOTES,
} from './model';

function shopReducer(state = model, action) {
  switch (action.type) {
    case INIT_STORE:
      return model.mergeDeep(state);

    case FETCH_SHOP:
      return state.set(IS_FETCHING, true).set(HAS_FETCHED, false);

    case SET_SHOP:
      return state.set(DATA, fromJS(action.payload.shop));

    case successAction(FETCH_SHOP): {
      const { shop } = action.payload;
      let iShop = fromJS(shop);
      const shopFromSearch = state.get(DATA);
      if (shopFromSearch) {
        if (!isNullOrUndefined(shopFromSearch.get('opened')))
          iShop = iShop.set('opened', shopFromSearch.get('opened'));

        if (!isNullOrUndefined(shopFromSearch.get('url')))
          iShop = iShop.set('url', shopFromSearch.get('url'));

        if (!isNullOrUndefined(shopFromSearch.get('closedReason')))
          iShop = iShop.set('closedReason', shopFromSearch.get('closedReason'));
      } else {
        const city = getShopCity(shop);
        const link = getShopLink(shop);
        if (link && city?.slug) {
          const url = getShopDetailsRoute({ link, cityName: city.slug });
          iShop = iShop.set('url', url);
        }
      }

      return state
        .set(DATA, iShop)
        .set(IS_FETCHING, false)
        .set(HAS_FETCHED, true);
    }

    case failureAction(FETCH_SHOP):
      return state.set(IS_FETCHING, false).set(HAS_FETCHED, false);

    case TOGGLE_FAVORITES: {
      if (state.get(DATA)) {
        const isFavorite = state.getIn([DATA, 'favoriteByUser']);
        return state.setIn([DATA, 'favoriteByUser'], !isFavorite);
      }
      return state;
    }

    case failureAction(TOGGLE_FAVORITES): {
      if (state.get(DATA)) {
        const isFavorite = state.getIn([DATA, 'favoriteByUser']);
        return state.setIn([DATA, 'favoriteByUser'], !isFavorite);
      }
      return state;
    }

    case FETCH_VENDOR_CONFIG_NOTES:
      return state.set(IS_FETCHING_VENDOR_CONFIG_NOTES, true);

    case successAction(FETCH_VENDOR_CONFIG_NOTES): {
      const data = action.payload;
      return state
        .set(HIDE_NOTES, data)
        .set(IS_FETCHING_VENDOR_CONFIG_NOTES, false);
    }

    case failureAction(FETCH_VENDOR_CONFIG_NOTES):
      return state.set(IS_FETCHING_VENDOR_CONFIG_NOTES, false);

    default:
      return state;
  }
}

export default orderFlowReducer(shopReducer, model);
