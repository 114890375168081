import { Map } from 'immutable';

import ModuleStateModel, * as moduleKeys from './model';
import * as actions from './actionTypes';

const initialState = ModuleStateModel;

function sharedReducer(state = initialState, action) {
  switch (action.type) {
    case actions.INIT_STORE:
      return ModuleStateModel.mergeDeep(state);

    case actions.SHOW_TOAST:
      return state.set(
        moduleKeys.NOTIFICATION,
        Map({ show: true, title: action.title, color: action.color }),
      );

    case actions.HIDE_TOAST:
      return state.set(
        moduleKeys.NOTIFICATION,
        Map({ show: false, title: '' }),
      );

    case actions.OPEN_SIDEBAR:
      return state.set(moduleKeys.IS_SIDE_BAR_OPEN, true);

    case actions.CLOSE_SIDEBAR:
      return state.set(moduleKeys.IS_SIDE_BAR_OPEN, false);

    case actions.SHOW_ERROR_PAGE:
      return state
        .set(moduleKeys.IS_SHOWING_ERROR_PAGE, true)
        .set(moduleKeys.ERROR_CODE, action.errorCode);

    case actions.UPDATE_IS_DESKTOP_LAYOUT:
      return state.set(moduleKeys.IS_DESKTOP_LAYOUT, action.payload);

    case actions.SET_MAIN_CONTENT_ID:
      return state.set(moduleKeys.MAIN_CONTENT_ID, action.payload);

    default:
      return state;
  }
}

export default sharedReducer;
