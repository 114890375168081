import { error, event as logEvent } from '@logger';

import getSearchedLocation from '@location/selectors/getSearchedLocation';
import { getLocationPointCityName } from '@commons/models/LocationPoint';
import { categoryType } from '@services/routes.service';
import saveTracking from '@tracking/services/saveData';
import { getLocationMethod } from '@utils/trackingUtils';
import { getUserId, getIsUserLogged } from '@shared/selectors';
import getAddress from '@commons/models/LocationPoint/getters/getAddress';
import getLatitude from '@commons/models/LocationPoint/getters/getLatitude';
import getLongitude from '@commons/models/LocationPoint/getters/getLongitude';
import { isAndroidTrack, androidTrack } from './platformTracking/androidTrack';
import {
  isWebTrack,
  webTrack,
  formatDataForWebTrack,
} from './platformTracking/webTrack';

let trackingData = [];
let tracker = null;
let initialized = false;

function isExternalTracker() {
  return !!(tracker && tracker.track);
}

export function logError(event, err) {
  error('[TRACKING_ERROR]', { error: err, data: { event } });
}

async function internalInitialize(config) {
  const TagManager = await import(
    /* webpackChunkName: "react-gtm-module" */ 'react-gtm-module'
  );
  TagManager.initialize(config);
}

function addCommonPayloadToOriginalPayload(originalPayload, commonPayload) {
  return Object.keys(commonPayload).reduce((obj, key) => {
    const value = obj[key];
    // eslint-disable-next-line no-param-reassign
    obj[key] = value === undefined ? commonPayload[key] : value;
    return obj;
  }, originalPayload);
}

export function addCommonTrackingData(originalPayload, state) {
  const { location } = window;
  const pageType = categoryType(location);

  const pageUrlPath = location.pathname;
  const searchedLocation = getSearchedLocation(state);
  const locationCity = getLocationPointCityName(searchedLocation);

  const locationAddress = getAddress(searchedLocation);
  const locationLat = getLatitude(searchedLocation);
  const locationLon = getLongitude(searchedLocation);

  const locationMethod =
    searchedLocation && getLocationMethod({ searchedLocation });

  const userId = getUserId(state) || '';
  const userLoggedIn = getIsUserLogged(state);

  const commonPayload = {
    pageType,
    pageUrlPath,
    locationAddress,
    locationCity,
    locationLon,
    locationLat,
    locationMethod,
    userId,
    userLoggedIn,
  };

  return addCommonPayloadToOriginalPayload(originalPayload, commonPayload);
}

function saveForLater(event, payload) {
  trackingData.push({ event, ...payload });
}

function internalTrack(event, payload, { excludeFromCommon = [] } = {}) {
  if (isAndroidTrack()) {
    androidTrack(event, payload);
  } else if (isWebTrack()) {
    webTrack(event, payload, { excludeFromCommon });
  } else {
    saveForLater(event, payload);
  }
}

function externalTrack(event, payload, { excludeFromCommon }) {
  const formattedPayload = formatDataForWebTrack(payload, {
    excludeFromCommon,
    format: false,
  });
  tracker.track(event, formattedPayload);
  saveTracking({
    event,
    ...formattedPayload,
  });
}

export function track(
  event,
  payload,
  { sendToLogs = false, logEventKey = '', excludeFromCommon = [] } = {},
) {
  if (!initialized)
    saveForLater(event, payload, {
      sendToLogs,
      logEventKey,
      excludeFromCommon,
    });
  else if (isExternalTracker())
    externalTrack(event, payload, {
      sendToLogs,
      logEventKey,
      excludeFromCommon,
    });
  else
    internalTrack(event, payload, {
      sendToLogs,
      logEventKey,
      excludeFromCommon,
    });
  if (sendToLogs) {
    logEvent(logEventKey || event, { data: payload });
  }
}

function onTrackingInitialized() {
  trackingData.forEach(d => track(d.event, d));
  trackingData = [];
}

export async function initialize(config, trackerPromise) {
  try {
    if (!config) throw new Error('Missing config');
    if (trackerPromise) tracker = await trackerPromise;
    else await internalInitialize(config);
    initialized = true;
    onTrackingInitialized();
  } catch (err) {
    error('[ERROR][Tracking][initialize]', err);
  }
}

export function clear() {
  trackingData = [];
  tracker = null;
}
