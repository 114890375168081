import {
  MERCADO_PAGO,
  DECIDIR,
  INGENICO,
  EXTERNAL,
  SPREEDLY,
  WEBPAY_ONE_CLICK,
} from '@commons/utils/payment/paymentGatewayTypeConstants';
import { log } from '@logger';

let getProviderOnlinePaymentData;
let selectedPaymentProvider;

function getServicePath(paymentMethod) {
  log(`[token/index][getServicePath] for paymentMethod: ${paymentMethod}`);
  switch (paymentMethod) {
    case DECIDIR:
      return './decidir';
    case MERCADO_PAGO:
      return './mercadoPago';
    case INGENICO:
      return './ingenico';
    case EXTERNAL:
      return './external';
    case SPREEDLY:
      return './spreedly';
    case WEBPAY_ONE_CLICK:
      return './oneclick';
    default:
      throw new Error('paymentMethod is required');
  }
}

export async function getOnlinePaymentData(
  paymentMethod,
  gatewayType,
  instance,
) {
  log(
    `[token/index][getOnlinePaymentData] for paymentMethod != null: ${!!paymentMethod}, gatewayType: ${gatewayType} and instance !== null: ${!!instance}`,
  );
  if (
    !getProviderOnlinePaymentData ||
    selectedPaymentProvider !== gatewayType
  ) {
    selectedPaymentProvider = gatewayType;
    const path = getServicePath(gatewayType);
    log(`[token/index][getOnlinePaymentData] getServicePath return ${path}`);
    const { getOnlinePaymentData: getOnlinePaymentDataFunc } = await import(
      `${path}`
    );
    log(
      `[token/index][getOnlinePaymentData] getOnlinePaymentDataFunc imported ${!!getOnlinePaymentDataFunc}`,
    );
    getProviderOnlinePaymentData = getOnlinePaymentDataFunc;
  }
  log(`[token/index][getOnlinePaymentData] call getProviderOnlinePaymentData`);
  return getProviderOnlinePaymentData(paymentMethod, instance);
}
