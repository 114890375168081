import { logError, track } from '@tracking';

const EVENT_NAME = 'migration.loaded';

const trackMigrationLoaded = ({ origin }) => {
  try {
    track(EVENT_NAME, { origin });
  } catch (error) {
    logError(EVENT_NAME, error);
  }
};

export default trackMigrationLoaded;
