import { createSelector } from 'reselect';

import filterProducts from '@utils/menu/filterMenu';

import getPropFromModule from './getPropFromModule';
import getIsShelvesMenuLayout from './getIsShelvesMenuLayout';
import getFilterMenu from './getFilterMenu';
import { EXTENDED_MENU_MIN_FILTER_LENGTH } from '../constants';

const getMenu = createSelector(
  state => getPropFromModule(state, 'data'),
  getIsShelvesMenuLayout,
  getFilterMenu,
  (menu, isShelvesLayout, filter) => {
    if (!menu) return null;

    const applyFilter =
      !isShelvesLayout && filter?.length >= EXTENDED_MENU_MIN_FILTER_LENGTH;

    /**
     * We can't do toJS to menu because sections and products are OrderedMaps,
     * and toJS does not preserve the order of the items
     */
    return {
      id: menu.get('id'),
      total: menu.get('total'),
      count: menu.get('count'),
      filter,
      sections: menu.get('sections').reduce((sectionsArray, section) => {
        let products;
        let totalProducts;

        if (applyFilter) {
          products = filterProducts(section, filter);
          totalProducts = products.length;
        } else {
          products = section
            .get('products')
            .reduce(
              (productsArray, product) => [...productsArray, product.toJS()],
              [],
            );
          totalProducts = section.get('totalProducts');
        }

        if (products.length) {
          const parsedSection = {
            id: section.get('id'),
            name: section.get('name'),
            index: section.get('index'),
            requiresAgeCheck: section.get('requiresAgeCheck'),
            image: section.get('image'),
            customImage: section.get('customImage'),
            totalProducts,
            products,
          };
          sectionsArray.push(parsedSection);
        }
        return sectionsArray;
      }, []),
    };
  },
);

export default getMenu;
