import { LOCATION_POINT_TYPE } from '@location/constants';
import locationToString from '../mappers/toString';
import LocationPoint from './LocationPoint';

function SearchLocationPoint({
  address,
  cityName,
  validated,
  latitude, // only server side.
  longitude, // only server side.
  street,
  doorNumber,
  complement,
  notes,
}) {
  return LocationPoint({
    address: locationToString({ address }),
    cityName,
    latitude,
    longitude,
    validated,
    type: LOCATION_POINT_TYPE.SEARCH,
    street,
    doorNumber,
    complement,
    notes,
  });
}

export default SearchLocationPoint;
