const cart = {
  foodButton: {
    zIndex: 101,
    background: '$white',
  },
  subizedProductColor: '$night_blue_1',
  ageValidationReliable: {
    zIndex: 1,
    marginBottom: '$space_10',
    opacity: '0.7',
  },
};

export default cart;
