const KEY_SPACE = 32;
const KEY_ENTER = 13;

export const onKeyDownFunction = (event, callback) => {
  if (event.keyCode === KEY_SPACE) {
    event.preventDefault();
  }
  // If enter is pressed, activate the button
  else if (event.keyCode === KEY_ENTER) {
    event.preventDefault();
    callback();
  }
};

export const onKeyUpFunction = (event, callback) => {
  if (event.keyCode === KEY_SPACE) {
    event.preventDefault();
    callback();
  }
};
