import { Map } from 'immutable';

export const IS_FETCHING = 'isFetching';
export const DATA = 'data';

const ModuleStateModel = Map({
  [DATA]: Map(),
  [IS_FETCHING]: Map(),
});

export default ModuleStateModel;
