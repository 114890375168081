import cache, { KEYS } from '@services/cache';
import { event } from '@logger';

const GPS_PERMISSION_STATUS = {
  GRANTED: 'GRANTED',
  PROMPT: 'PROMPT',
  DENIED: 'DENIED',
  UNSUPPORTED: 'UNSUPPORTED',
};

function getGPSPermissionStatusResult(success) {
  return success ? GPS_PERMISSION_STATUS.GRANTED : GPS_PERMISSION_STATUS.DENIED;
}

const mapCoords = coords => ({
  accuracy: coords.accuracy,
  altitude: coords.altitude,
  altitudeAccuracy: coords.altitudeAccuracy,
  heading: coords.heading,
  latitude: coords.latitude,
  longitude: coords.longitude,
  speed: coords.speed,
});

function getErrorType(error) {
  switch (error.code) {
    case error.PERMISSION_DENIED:
      return 'PERMISSION_DENIED';
    case error.POSITION_UNAVAILABLE:
      return 'POSITION_UNAVAILABLE';
    case error.TIMEOUT:
      return 'TIMEOUT';
    case error.UNKNOWN_ERROR:
      return 'UNKNOWN_ERROR';
    default:
      return error.code;
  }
}

const GPS_TIMEOUT = 30000;
const OUR_TIMEOUT = GPS_TIMEOUT + 1000;
const MAX_AGE = 1000 * 60 * 15; // 15 minutes
const getGPSCurrentPositionOptions = {
  enableHighAccuracy: false,
  maximumAge: MAX_AGE,
  timeout: GPS_TIMEOUT,
};

function getGPSCurrentPosition() {
  return new Promise(resolve => {
    if (navigator.geolocation) {
      const getCurrentPositionTimeout = setTimeout(() => {
        event('[GPS_ERROR] OUR_TIMEOUT');
        resolve({ code: true });
      }, OUR_TIMEOUT);

      navigator.geolocation.getCurrentPosition(
        result => {
          clearTimeout(getCurrentPositionTimeout);
          resolve({ coords: mapCoords(result.coords) });
        },
        error => {
          clearTimeout(getCurrentPositionTimeout);
          event(`[GPS_ERROR] ${getErrorType(error)}`);
          resolve(error);
        },
        getGPSCurrentPositionOptions,
      );
    } else {
      event('[GPS_ERROR] UNSUPPORTED');
      resolve({ unsupported: true });
    }
  });
}

function supportsNavigatorPermissions() {
  return 'permissions' in navigator;
}

export const isGPSPermissionGranted = status =>
  status === GPS_PERMISSION_STATUS.GRANTED;

export const isGPSPermissionDenied = status =>
  status === GPS_PERMISSION_STATUS.DENIED;

export const gpsPermissionShouldBePrompted = status =>
  status === GPS_PERMISSION_STATUS.PROMPT;

const NOT_SUPPORTED_MESSAGE = 'Funcionalidad no soportada';
const NO_PERMISSION_MESSAGE =
  'Debes activar la localización de tu navegador para poder buscar por tu ubicación';
const GENERIC_ERROR_MESSAGE = 'Ocurrió un error al obtener su ubicación';
export async function getGPSLocation() {
  try {
    const result = await getGPSCurrentPosition();
    let message = '';
    let success = true;

    if (result.unsupported) {
      message = NOT_SUPPORTED_MESSAGE;
      success = false;
    } else if (result.code) {
      message = NO_PERMISSION_MESSAGE;
      success = false;
    }

    // Set OS permission in storage, so in case user denied permission,
    // we avoid showing the GPS enabling modal again
    setOSGPSPermissionStatusInStorage(success);
    setUserGPSPermissionStatusInStorage(success);

    return { coords: result.coords, message, success };
  } catch (e) {
    return { message: GENERIC_ERROR_MESSAGE, success: false };
  }
}

// permission getters
function getUserGPSPermissionStatus() {
  return cache.get(KEYS.USER_GPS_PERMISSION_STATUS);
}

function getOSGPSPermissionStatus() {
  return cache.get(KEYS.OS_GPS_PERMISSION_STATUS);
}

// permission setters
function setGPSPermissionStatusInStorage(key, value) {
  let newValue = value;

  if (typeof value !== 'string') newValue = getGPSPermissionStatusResult(value);

  cache.set(key, newValue);
  return newValue;
}

export function setUserGPSPermissionStatusInStorage(value) {
  return setGPSPermissionStatusInStorage(
    KEYS.USER_GPS_PERMISSION_STATUS,
    value,
  );
}

function setOSGPSPermissionStatusInStorage(value) {
  return setGPSPermissionStatusInStorage(KEYS.OS_GPS_PERMISSION_STATUS, value);
}

async function getGPSPermissionStatusSupported() {
  try {
    const { state } = await navigator.permissions.query({
      name: 'geolocation',
    });

    let status = GPS_PERMISSION_STATUS.DENIED;

    if (state === 'granted') status = GPS_PERMISSION_STATUS.GRANTED;
    else if (state === 'prompt') status = GPS_PERMISSION_STATUS.PROMPT;

    setOSGPSPermissionStatusInStorage(status);
    if (!gpsPermissionShouldBePrompted(status)) {
      setUserGPSPermissionStatusInStorage(status);
    }
    return status;
  } catch (error) {
    return GPS_PERMISSION_STATUS.DENIED;
  }
}

function getGPSPermissionStatusNotSupported() {
  const status = getOSGPSPermissionStatus();
  return status === undefined
    ? setOSGPSPermissionStatusInStorage(GPS_PERMISSION_STATUS.PROMPT)
    : status;
}

function getGPSPermissionStatus() {
  return supportsNavigatorPermissions()
    ? getGPSPermissionStatusSupported()
    : getGPSPermissionStatusNotSupported();
}

export function isGPSEnabled() {
  return isGPSPermissionGranted(getOSGPSPermissionStatus());
}

function loadUserGPSPermissionStatusFromStorage() {
  const status = getUserGPSPermissionStatus();
  return status === undefined
    ? setUserGPSPermissionStatusInStorage(GPS_PERMISSION_STATUS.PROMPT)
    : status;
}

// Initialize actual permission status on app init
export function initialize() {
  return Promise.all([
    loadUserGPSPermissionStatusFromStorage(),
    getGPSPermissionStatus(),
  ]);
}

// Show notification pop up to tell user that the GPS is not enabled
export function showEnablingGPSNotification() {
  return !isGPSEnabled() && !isGPSPermissionDenied(getOSGPSPermissionStatus());
}

// Show option to enable GPS in Location Header
export function showEnablingGPSOption() {
  return !isGPSEnabled();
}

// Show modal to enable GPS
export function shouldPromptGPSEnabling() {
  const osGPSPermissionStatus = getOSGPSPermissionStatus();
  const permissionIsNotGranted = !isGPSPermissionGranted(osGPSPermissionStatus);
  const permissionIsNotDenied = !isGPSPermissionDenied(osGPSPermissionStatus);

  const userHasNotDenied = !isGPSPermissionDenied(getUserGPSPermissionStatus());

  return permissionIsNotDenied && permissionIsNotGranted && userHasNotDenied;
}
