import { createSelector } from 'reselect';
import queryString from 'query-string';
import { insert } from '@utils/array/insert';

import getCurrentUser from '@core/reducers/user/selectors/getCurrentUser';
import { QUERY_PARAM } from '@commons/constants';
import { COUNTRY as LOCATION_COUNTRY } from '@location/model';
import { FILTERS } from '@core/reducers/shopList/model';
import getPropFromShoplistModule from '@core/reducers/shopList/selectors/getPropFromModule';
import { MODULE_NAME, CHANNEL_KEYNAME, COUNTRY } from './constants';
import { MODULE_NAME as locationModuleName } from '../location/constants';
import * as modelKeys from './model';

const ROUTER_MODULE_NAME = 'router';

const getPropFromModule = (state, ...props) =>
  state.getIn([MODULE_NAME, ...props]);

const getPropFromRouterDomain = (state, ...props) =>
  state.getIn([ROUTER_MODULE_NAME, 'location', ...props]);

const getPropFromRouterDomainPrevLocation = (state, ...props) =>
  state.getIn([ROUTER_MODULE_NAME, 'prevLocation', ...props]);

export const getRouterPrevLocation = createSelector(
  state => getPropFromRouterDomainPrevLocation(state),
  prevLocation => prevLocation?.toJS(),
);

export const getCountry = createSelector(
  state => state.getIn([locationModuleName, LOCATION_COUNTRY]),
  country => (country ? country.toJS() : null),
);

export const getChannels = createSelector(
  state => getPropFromModule(state, modelKeys.INITIAL_DATA, 'channels'),
  channels => channels?.toJS(),
);

export const getCategories = createSelector(
  state => getPropFromShoplistModule(state, FILTERS, 'data'),
  filtersData => {
    const filters = filtersData?.toJS() || {};
    const otherGroup = filters.groups?.find(group => group.id === 'OTHERS');

    const cuisineFilters = otherGroup?.filters?.find(
      filter => filter.id === 'FOOD_CATEGORIES',
    );

    return (
      cuisineFilters?.values
        ?.filter(({ metadata }) => !metadata.group)
        ?.map(({ id, metadata }) => ({
          id: Number(id),
          name: metadata.name,
          image: metadata.image,
          visible: true,
        })) || []
    );
  },
);

export const getOnlinePaymentChannelId = createSelector(
  getChannels,
  channels =>
    channels?.find(c => c.keyName === CHANNEL_KEYNAME.ONLINE_PAYMENT)?.id,
);

export const getCountryVerticals = createSelector(
  state => getPropFromModule(state, modelKeys.INITIAL_DATA, 'verticals'),
  verticals => (verticals ? verticals.toJS() : []),
);

export const getIsUserLogged = createSelector(
  getCurrentUser,
  user => user != null,
);

export const getUserId = createSelector(
  getIsUserLogged,
  getCurrentUser,
  (isUserLogged, user) => (isUserLogged && user.id) || null,
);

export const getCountryName = createSelector(
  getCountry,
  country => country?.name || '',
);

export const getCountryShortName = createSelector(
  getCountry,
  country => country?.shortName || '',
);

export const getCountryId = createSelector(getCountry, country => country?.id);

export const getCurrencySymbol = createSelector(
  getCountry,
  country => country?.currencySymbol || '$',
);

export const getTimeFormat = createSelector(
  getCountry,
  country => country?.timeFormat || 'HH:mm',
);

// ONLY FOR CLIENT SIDE USAGE.
export const getRouterLocation = createSelector(
  state => getPropFromRouterDomain(state),
  location => location?.toJS(),
);

export const getPathname = state => getPropFromRouterDomain(state, 'pathname');

export const getSearch = state => getPropFromRouterDomain(state, 'search');

export const getRouterState = createSelector(
  state => getPropFromRouterDomain(state, 'state'),
  routerState => routerState?.toJS(),
);

export const getSearchAsObject = createSelector(getSearch, search =>
  queryString.parse(search),
);

export const getNotification = createSelector(
  state => getPropFromModule(state, modelKeys.NOTIFICATION),
  notification => notification?.toJS(),
);

export const getFullUserName = createSelector(
  getIsUserLogged,
  getCurrentUser,
  (isUserLogged, user) =>
    (isUserLogged && `${user.name} ${user.lastName}`) || '',
);

export const getUserName = createSelector(
  getIsUserLogged,
  getCurrentUser,
  (isUserLogged, user) =>
    (isUserLogged && `${user.nickname || user.name}`) || '',
);

export const getShopCardChannels = createSelector(
  getChannels,
  getCountryName,
  (channels, countryName) => {
    if (!channels) return [];

    let shopCardChannels = channels
      .filter(x => x.cardVisible)
      .sort((a, b) => {
        if (a.index < b.index) return -1;
        if (a.index > b.index) return 1;
        return 0;
      });

    // For countries other than Uruguay, this function inserts
    // into the channels array, a custom online payment channel.
    // Afterwards, it is shown in the shop card

    if (!shopCardChannels) return [];
    if (
      countryName === COUNTRY.URUGUAY ||
      shopCardChannels.some(x => x.keyName === CHANNEL_KEYNAME.ONLINE_PAYMENT)
    ) {
      return shopCardChannels;
    }

    const onlinePayment = { keyName: CHANNEL_KEYNAME.ONLINE_PAYMENT };

    const discountIndex = shopCardChannels.findIndex(
      x => x.keyName === CHANNEL_KEYNAME.DISCOUNT,
    );

    shopCardChannels = insert(
      shopCardChannels,
      discountIndex + 1,
      onlinePayment,
    );

    return shopCardChannels;
  },
);

export const getIsExpressChannelEnabled = createSelector(
  getChannels,
  channels =>
    channels
      ? channels.some(x => x.keyName === CHANNEL_KEYNAME.EXPRESS)
      : false,
);

export const getUserEmail = createSelector(
  getIsUserLogged,
  getCurrentUser,
  (isUserLogged, user) => (isUserLogged && user.email) || '',
);

export const getIsShowingErrorPage = state =>
  getPropFromModule(state, modelKeys.IS_SHOWING_ERROR_PAGE);

export const getErrorCode = state =>
  getPropFromModule(state, modelKeys.ERROR_CODE);

export const getFirstReferral = state =>
  getPropFromModule(state, modelKeys.INITIAL_REFERRAL);

export const getPageFromQuery = createSelector(
  getSearchAsObject,
  search => Number(search[QUERY_PARAM.PAGE]) || 1,
);

export const getIsDesktopLayout = state =>
  getPropFromModule(state, modelKeys.IS_DESKTOP_LAYOUT) || false;

export const getMainContentId = state =>
  getPropFromModule(state, modelKeys.MAIN_CONTENT_ID);
