import { createSelector } from 'reselect';

import { getCountry } from '@shared/selectors';

const getCountryLanguage = createSelector(
  getCountry,
  country => country?.language,
);

export default getCountryLanguage;
