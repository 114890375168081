import { useState } from 'react';
import useOnce from '@hooks/useOnce';
import { isProduction } from '@utils/environment';

const EVENT_NAME = 'long-press';

function useTrackingPage() {
  const [showTrackingPage, setShowTrackingPage] = useState(false);

  // eslint-disable-next-line consistent-return
  useOnce(() => {
    if (!isProduction()) {
      const cb = () => setShowTrackingPage(true);

      import(/* webpackChunkName: "long-press" */ '@utils/long-press').then(
        () => {
          document.addEventListener(EVENT_NAME, cb);
        },
      );

      return () => document.removeEventListener(EVENT_NAME, cb);
    }
  });

  return [showTrackingPage, () => setShowTrackingPage(false)];
}

export default useTrackingPage;
