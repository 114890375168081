import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';

import {
  ROUTES,
  getResultsRoute,
  isCheckout,
  isCart,
} from '@services/routes.service';
import hasItems from '@utils/cart/hasItems';
import needsToAddMoreItems from '@utils/cart/needsToAddMoreItems';
import { getPathname } from '@utils/domRouter';

export const RENDER_COMPONENT = '';

const renderComponentOrCartRoute = renderComponent =>
  renderComponent ? RENDER_COMPONENT : ROUTES.CART;

export function getRoute({
  isUserLogged,
  cart,
  shop,
  location,
  searchedLocation,
}) {
  if (!shop || !cart) {
    // TODO: ask to have cityName in shop, so we can redirect to shop details.
    return searchedLocation ? getResultsRoute(searchedLocation) : ROUTES.HOME;
  }

  const pathname = getPathname(location);
  const inCartRoute = isCart(pathname);

  if (needsToAddMoreItems(cart) || !hasItems(cart))
    return renderComponentOrCartRoute(inCartRoute);

  if (!isUserLogged) {
    // If user is not logged and is in checkout, we have to show login.
    const inCheckoutRoute = isCheckout(pathname);
    return renderComponentOrCartRoute(inCartRoute || inCheckoutRoute);
  }

  return RENDER_COMPONENT;
}

const ConversionRoute = ({
  component: Component,
  isUserLogged,
  cart,
  shop,
  location,
  searchedLocation,
  ...rest
}) => (
  <Route
    {...rest}
    location={location}
    render={props => {
      const route = getRoute({
        isUserLogged,
        cart,
        shop,
        location,
        searchedLocation,
      });

      return route === RENDER_COMPONENT ? (
        <Component {...props} />
      ) : (
        <Redirect to={route} />
      );
    }}
  />
);

ConversionRoute.propTypes = {
  component: PropTypes.object.isRequired,
  isUserLogged: PropTypes.bool.isRequired,
  cart: PropTypes.object,
  shop: PropTypes.object,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  searchedLocation: PropTypes.object,
};

export default ConversionRoute;
