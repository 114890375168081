import { error as logError } from '@logger';

export const SCROLL_BEHAVIOR = {
  AUTO: 'auto',
  SMOOTH: 'smooth',
};

export const DIRECTION = {
  RIGHT: 'right',
  LEFT: 'left',
};

export const HORIZONTAL_SCROLL = 300;

export function scrollTo({
  left = 0,
  top = 0,
  behavior = SCROLL_BEHAVIOR.AUTO,
}) {
  if (!window) return;

  try {
    window.scrollTo({ left, top, behavior });
  } catch {
    window.scrollTo(left, top);
  }
}

export function scrollElementHorizontally(elementId, scroll, direction) {
  try {
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollTo({
        left:
          direction === DIRECTION.RIGHT
            ? element.scrollLeft + scroll
            : element.scrollLeft - scroll,
        behavior: 'smooth',
      });
    }
  } catch (error) {
    logError(`[SCROLL_UTIL] ${error}`);
  }
}
