import styled from 'styled-components';

import { PADDING } from '../constants';

const Content = styled.div`
  color: ${({ theme }) => theme.commons.tooltip.textColor};
  font-family: ${({ theme }) => theme.fonts.secondaryFont};
  font-size: 14px;
  border-radius: 6px;
  background-color: ${({ theme }) => theme.commons.tooltip.background};
  line-height: 18px;
  position: relative;
  padding: ${PADDING}px;
`;

export default Content;
