import { getQuery, getQueryAsObject } from '@utils/domRouter';
import isString from '@commons/utils/string/isString';
import { QUERY_PARAM } from '@commons/constants';
import { changePropFromQuery } from '@utils/url';
import isObject from '@commons/utils/object/isObject';
import { log } from '@logger';
import { ID, DEFAULT, IS_ACTIVE, RENDER } from './ModalManagerPage';

export const MODAL_KEY = QUERY_PARAM.MODAL_PAGE;

export const findDefaultChild = children =>
  children.find(({ props }) => props[DEFAULT]);

export const findChildById = (children, id) =>
  children.find(({ props }) => props[ID] === id) || findDefaultChild(children);

export const findActiveChild = children =>
  children.find(({ props }) => props[IS_ACTIVE]);

const comesFromModalPageAndNextHasQuery = (route, location) =>
  Boolean(isObject(route) && getQueryAsObject(location)[MODAL_KEY]);

export function goToPage(route, location, pushPage) {
  const goesToModalPage = isString(route) && route.charAt(0) !== '/';
  let nextRoute = route;
  if (goesToModalPage) {
    nextRoute = {
      ...location,
      search: changePropFromQuery(getQuery(location), MODAL_KEY, route),
    };
  } else if (comesFromModalPageAndNextHasQuery(route, location)) {
    nextRoute = {
      ...route,
      search: changePropFromQuery(route.search, MODAL_KEY, null),
    };
  }

  pushPage(nextRoute);
}

export function getComponentToRender(children, page, loading) {
  const active = !!children.find(({ props }) => {
    if (!props) {
      log(`[MODAL_MANAGER_PROPS_NOT_FOUND]:  ${children}`);
      return false;
    }
    return props[IS_ACTIVE];
  });

  if (active) {
    const currentActive = findActiveChild(children);
    return currentActive?.props[RENDER];
  }
  const current =
    loading || !page
      ? findDefaultChild(children)
      : findChildById(children, page);
  return current?.props[RENDER];
}

export function getDefaultComponentToRender(children) {
  const current = findDefaultChild(children);
  return current?.props[RENDER];
}
