import { createSelector } from 'reselect';

import getShop from '@core/reducers/shop/selectors/getShop';
import { MENU_LAYOUT_ID } from '../constants';

const getMenuLayout = createSelector(
  getShop,
  shop => shop?.menu?.layout || null,
);

export const getMenuLayoutId = createSelector(
  getMenuLayout,
  layout => MENU_LAYOUT_ID[layout] || null,
);

export default getMenuLayout;
