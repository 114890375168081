export const MODULE_NAME = 'shared';
export const NOTIFICATION_TIMEOUT = 4000;

export const SHOP_OPENIN = 2;
export const SHOP_IS_CLOSED = 5;
export const SHOP_IS_OPEN = 1;

// new shoplist stack
export const VENDOR_STATUS = {
  OPEN: 1,
  CLOSED: 5,
  OPEN_IN: 2,
  PICKUP: 1,
  PRE_ORDER: 5,
};

export const USER_TOKEN_QUERY_PARAM = 'at';

export const COUNTRY = { URUGUAY: 'Uruguay' };

export const CHANNEL_KEYNAME = {
  EXPRESS: 'express',
  FAVORITES: 'favorites',
  DISCOUNT: 'discount',
  ONLINE_PAYMENT: 'online_payment',
  VOUCHER: 'cupon',
  BEST: 'best',
  TRACKING: 'tracking',
};

export const SHOP_DELIVERY_TYPE = {
  PICK_UP: 'PICK_UP',
  DELIVERY: 'DELIVERY',
  ALL: 'ALL',
};

export const TRACK_DELIVERY_TYPES = {
  PICK_UP: 'only_pickup',
  DELIVERY: 'only_delivery',
  ALL: 'both',
};

export const TRACK_NEW_STATUS = {
  NEW: 'new',
  OLD: 'old',
};

export const TOAST_STATE = {
  SUCCESS: 'success',
  ERROR: 'error',
  NEUTRAL: 'neutral',
};

export const STATUS_CODE = {
  INTERNAL_ERROR: 500,
  NOT_FOUND: 404,
  FORBIDDEN: 403,
  METHOD_NOT_ALLOWED: 405,
  SERVICE_UNAVAILABLE: 503,
};

export const URI_PARAM = {
  ADDRESS_ID: 'addressId',
  CATEGORY: 'category',
  CHAIN: 'chain',
  CITY: 'city',
  AREA: 'area',
  SHOP: 'restaurant',
  SHOP_MENU: 'restaurant-menu',
  PAGE: 'page',
  SHOP_GROUP: 'restaurantGroup',
  ORDER_ID: 'orderId',
  VOUCHER_ID: 'voucherId',
};

export const COMPANY_BILLING_TYPES = {
  FINAL_CONSUMER: 'FINAL_CONSUMER',
  COMPANY_NAME_AND_NUMBER: 'COMPANY_NAME_AND_NUMBER',
};

export const CATEGORY_TYPES = {
  TREE: 'TREE',
  LIST: 'LIST',
};
