import { error as logError, event } from '@logger';
import isSupported from './isSupported';

/**
 * https://caniuse.com/#search=intersectionobserver
 * Browsers that we support that does not support IntersectionObserver:
 * => Firefox 54, Safari 10-12, Safari iOS 10-12.1
 * Browsers that we support that does not support IntersectionObserver.isIntersecting:
 * => Chrome 51-57, Samsung Internet 5-6.4, Edge 15
 *
 * We only load the polyfill when isn't supported, because we don't use
 * IntersectionObserver.isIntersecting property.
 */

const eventKey = '[INTERSECTION_OBSERVER_POLYFILL]';

async function loadIntersectionObserverPolyfill() {
  if (!isSupported()) {
    try {
      await import(
        /* webpackChunkName: "intersection-observer-polyfill" */ 'intersection-observer'
      );
      event(`${eventKey} Loaded`);
    } catch (error) {
      logError(`${eventKey} Error`, { error });
      throw error;
    }
  }
}

export default loadIntersectionObserverPolyfill;
