export const FETCH_JOKER = 'app/joker/FETCH_JOKER';
export const SET_SELECTED_SHOP_ID = 'app/joker/SET_SELECTED_SHOP_ID';
export const RESET_STATE = 'app/joker/RESET_STATE';
export const SET_HAS_FETCHED_TO_TRUE = 'app/joker/SET_HAS_FETCHED_TO_TRUE';
export const ACCEPT_JOKER_OFFER = 'app/joker/ACCEPT_JOKER_OFFER';
export const REJECT_JOKER_OFFERS = 'app/joker/REJECT_JOKER_OFFERS';
export const START_FLOW = 'app/joker/START_FLOW';
export const OUT_JOKER_FLOW = 'app/joker/OUT_JOKER_FLOW';
export const SHOW_COUNT_DOWN_MODAL = 'app/joker/SHOW_COUNT_DOWN_MODAL';
export const HIDE_COUNT_DOWN_MODAL = 'app/joker/HIDE_COUNT_DOWN_MODAL';
export const SET_IS_TIME_OVER = 'app/joker/IS_TIME_OVER';
export const EXPIRE_JOKER_CART = 'app/joker/EXPIRE_JOKER_CART';
export const FINISH_ORDER_JOKER = 'app/joker/FINISH_ORDER_JOKER';
export const SET_SELECTED_OFFER = 'app/joker/SET_SELECTED_OFFER';
export const SET_CURRENT_TIER = 'app/joker/SET_CURRENT_TIER';
