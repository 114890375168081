import { stringify } from 'querystring';

import getImageDimensions from './getImageDimensions';

function getFullImageUrl({ url, image, height, width, quality = 90 }) {
  const props = { quality, ...getImageDimensions({ height, width }) };
  return `${url}${image}?${stringify(props)}`;
}

export default getFullImageUrl;
