import DynamicImportScheduler from '@utils/browser/DynamicImportScheduler';

async function preloadAppBase() {
  DynamicImportScheduler.preload({
    id: 'base',
    mediumTasks: [
      () => import('@shared/containers/Error/Loadable'),
      () => import('@results/router/Loadable'),
      () => import('@shopDetails/router/Loadable'),
    ],
    lowTasks: [],
  });
}

function preloadAppProtected() {
  DynamicImportScheduler.preload({
    id: 'baseProtected',
    mediumTasks: [() => import('@location/containers/MyAddresses/Loadable')],
  });
}

async function preloadApp() {
  preloadAppBase();
  preloadAppProtected();
}

export default preloadApp;
