import { isProduction } from '@utils/environment';

// TODO: Check if we have to add more desktop widths
export const SCREEN_WIDTH = {
  DESKTOP_X_LARGE: 2560,
  DESKTOP_LARGE: 1920,
  DESKTOP_MEDIUM: 1680,
  DESKTOP_SMALL: 1440,
  TABLET_LARGE: 1280,
  TABLET_IPAD_PRO: 1024,
  TABLET_MEDIUM: 768,
  TABLET_SMALL: 600,
  MOBILE_X_LARGE: 550,
  MOBILE_LARGE: 480,
  MOBILE_IPHONE_LARGE: 414,
  MOBILE_MEDIUM: 375,
  MOBILE_SMALL: 320,
};

export const IMAGE_SIZE = {
  FULL: 'FULL',
  BIG: 'BIG',
  MEDIUM: 'MEDIUM',
  SMALL: 'SMALL',
  X_SMALL: 'X_SMALL',
};

export const IMAGE_SIZE_PERCENTAGE = {
  FULL: 1,
  BIG: 0.92,
  MEDIUM: 0.6,
  SMALL: 0.35,
  X_SMALL: 0.23,
};

export const IMAGE_SIZES_BY_SCREEN = (() => {
  const screenWidthKeys = Object.keys(SCREEN_WIDTH);
  const imagePercentageKeys = Object.keys(IMAGE_SIZE_PERCENTAGE);
  return imagePercentageKeys.reduce((acc, k) => {
    const percentageNumber = IMAGE_SIZE_PERCENTAGE[k];

    acc[k] = screenWidthKeys.reduce((arr, p) => {
      const screenWidth = SCREEN_WIDTH[p];
      arr.push(Math.round(screenWidth * percentageNumber));
      return arr;
    }, []);

    return acc;
  }, {});
})();

const WEB_VIEWS_APP_SUFFIX = isProduction() ? '' : 'stg-';

export const PY_STATIC_URL = 'https://img.pystatic.com';
export const DH_URL = `https://pedidosya.dhmedia.io/image/pedidosya`;
export const WEB_VIEWS_APP = `https://${WEB_VIEWS_APP_SUFFIX}web-apps.pedidosya.com/nutrition-info/`;

export const URLS = {
  // DH
  RESTAURANTS: `${DH_URL}/restaurants/`,
  PRODUCT: `${DH_URL}/products/`,
  MENU_SECTION: `${DH_URL}/menu-sections/`,
  FEATURED_PRODUCTS: `${DH_URL}/featured-products/`, // NOT USED
  ONLINE_PAYMENT_PROFILE: `${DH_URL}/payment-methods/online-payments/profile/`,
  ONLINE_PAYMENT_CHECKOUT: `${DH_URL}/payment-methods/online-payments/checkout/`,
  HEADER_BACKGROUNDS: `${DH_URL}/header-backgrounds/mobile/`,
  PROFILE_HEADERS: `${DH_URL}/profile-headers/`,
  ONLINE_PAYMENT_PROVIDER: `${DH_URL}/payment-methods/online-payments/`,
  HOME_CATEGORIES: `${DH_URL}/seo/home/categories/`,
  HOME_CITIES: `${DH_URL}/seo/home/cities/`,
  HOME_BACKGROUNDS: `${DH_URL}/home-backgrounds/`,
  CATEGORY_BACKGROUND: `${DH_URL}/header-backgrounds/`,
  CUSINE_FILTERS: `${DH_URL}/header-backgrounds/categories/`,
  SEO: `${DH_URL}/SEO/`,
  LOGIN: `${DH_URL}/login/web/`,
  PARTNER_HEADER_DEFAULT: `${DH_URL}/header-backgrounds/mobile/`,
  PARTNER_HEADER_CUSTOM: `${DH_URL}/profile-headers/`,
  COUNTRY_FLAG: `${DH_URL}/flags/v2/`,
  CHANNELS: `${DH_URL}/channels/`,
  APP_STORES: `${DH_URL}/app-stores/`,

  // PY_STATIC
  USER: `${PY_STATIC_URL}/users/`, // NOT USED
  PAYMENT_METHOD: `${PY_STATIC_URL}/payment-methods/`, // NOT USED
  ONLINE_PAYMENT: `${PY_STATIC_URL}/payment-methods/online-pay/`, // NOT USED
  ONLINE_PAYMENT_RESULTS: `${PY_STATIC_URL}/payment-methods/online-payments/results/`, // NOT USED
  CITY: `${PY_STATIC_URL}/cities/`, // NOT USED
  CATEGORIES: `${PY_STATIC_URL}/categories/`, // NOT USED
  CUSTOM_LANDINGS: `${PY_STATIC_URL}/landings/`, // NOT USED
  PROMO_DISCOUNTS: `${PY_STATIC_URL}/promos/`, // NOT USED
  SPECIAL_TAGS: `${PY_STATIC_URL}/special-tags/web/`, // NOT USED
  HOME_SEO_CHAINS: `${PY_STATIC_URL}/home-chains/`,
};
