/*
 * CityLanding Messages
 *
 * This contains all the text for the CityLanding component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  cityMetaTitle: {
    id: 'app.results.containers.CityLanding.city.meta.title',
    defaultMessage: 'Delivery {city} ¡Pedí Online Ahora! | PedidosYa',
  },
  cityMetaDescription: {
    id: 'app.results.containers.CityLanding.city.meta.description',
    defaultMessage:
      '¿Qué Querés Comer Hoy? En PedidosYa encontrá los Mejores Restaurantes con Delivery en {city}',
  },
  cityTitle: {
    id: 'app.results.containers.CityLanding.city.title',
    defaultMessage: 'Pedí delivery en {city} ahora',
  },

  areaCityMetaTitle: {
    id: 'app.results.containers.CityLanding.area.meta.title',
    defaultMessage: 'Delivery en {area}, {city} ¡Pedí Online Ya! | PedidosYa',
  },
  areaCityMetaDescription: {
    id: 'app.results.containers.CityLanding.area.meta.description',
    defaultMessage:
      '¿Qué Querés Comer Hoy? En PedidosYa encontrá Restaurantes que entregan Delivery en {area}',
  },
  areaCityTitle: {
    id: 'app.results.containers.CityLanding.area.title',
    defaultMessage: 'Pedí delivery en {area}, {city} online',
  },

  categoryCityMetaTitle: {
    id: 'app.results.containers.CityLanding.city.category.meta.title',
    defaultMessage: '{category} en {city} ¡Pedí Online Ya! | PedidosYa',
  },
  categoryCityMetaDescription: {
    id: 'app.results.containers.CityLanding.city.category.meta.description',
    defaultMessage:
      '¿Con Hambre? Pedí {category} en los Mejores Restaurantes de {city} en PedidosYa',
  },
  categoryCityTitle: {
    id: 'app.results.containers.CityLanding.city.category.title',
    defaultMessage: 'Pedí {category} en {city}',
  },

  categoryAreaCityMetaTitle: {
    id: 'app.results.containers.CityLanding.area.category.meta.title',
    defaultMessage: '{category} en {area} ¡Pedí Online! | PedidosYa',
  },
  categoryAreaCityMetaDescription: {
    id: 'app.results.containers.CityLanding.area.category.meta.description',
    defaultMessage:
      '¿Con Hambre? Pedí {category} en los Mejores Restaurantes de {area}, {city} por PedidosYa',
  },
  categoryAreaCityTitle: {
    id: 'app.results.containers.CityLanding.area.category.title',
    defaultMessage: 'Pedí {category} en {area}, {city} ahora',
  },

  landingButton: {
    id: 'app.results.containers.CityLanding.message.button',
    defaultMessage: 'Buscar por mi ubicación',
  },
});
