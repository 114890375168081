import styled, { css } from 'styled-components';

import { MARGIN, PADDING } from '../constants';
import {
  isTop,
  isRight,
  isBottom,
  isLeft,
  horizontalTranslation,
  verticalTranslation,
} from '../helpers';

const TooltipContainer = styled.div`
  margin: ${MARGIN}px;
  position: absolute;
  z-index: ${({ theme }) => theme.commons.tooltip.zIndex};

  ${({ targetEl, firstPosition, secondPosition }) => css`
    ${isTop(firstPosition) &&
      `
      top: ${targetEl.bottom}px;
      left: ${targetEl.left}px;
      transform: translateX(${horizontalTranslation(secondPosition)});
      `}
    ${isRight(firstPosition) &&
      `
      top: ${targetEl.top}px;
      left: ${targetEl.left}px;
      transform: translateX(calc(-100% - ${MARGIN +
        PADDING}px)) translateY(${verticalTranslation(secondPosition)});
      `}
    ${isBottom(firstPosition) &&
      `
      top: ${targetEl.top}px;
      left: ${targetEl.left}px;
      transform: translateX(${horizontalTranslation(
        secondPosition,
      )}) translateY(calc(-100% - ${MARGIN + PADDING}px));
      `}
    ${isLeft(firstPosition) &&
      `
      top: ${targetEl.top}px;
      left: ${targetEl.right}px;
      transform: translateY(${verticalTranslation(secondPosition)});
      `}
  `}
`;

export default TooltipContainer;
