import querystring from 'query-string';
import getNestedProp from '@commons/utils/object/getNestedProp';

export function getPathname(location) {
  return location.pathname;
}

export function getQuery(location) {
  return location.search;
}

export function getQueryAsObject(location) {
  return querystring.parse(getQuery(location));
}

export function getHash(location) {
  const { hash } = location;
  const containsHashChar = hash.charAt(0) === '#';
  return containsHashChar ? hash.slice(1, hash.length) : hash;
}

export function getState(location) {
  return location.state || {};
}

export function getQueryParams(location, paramKey) {
  return getNestedProp(getQueryAsObject(location), paramKey);
}
