import { error as logError } from '@logger';

import { getClientInstance } from './instance';
const flagTracking = {};

function flagHasChanged(prevValue, newValue) {
  return !prevValue || prevValue.variation !== newValue.variation;
}

export async function flagTracker(flag, flagKey) {
  if (flag && flag.key) {
    try {
      const flagTrackedValue = flagTracking[flag.key];
      if (flagHasChanged(flagTrackedValue, flag)) {
        flagTracking[flag.key] = flag;
        const fwf = await getClientInstance();
        if (fwf) {
          fwf.trackFeature(flag.key, flag);
        }
      }
    } catch (error) {
      const data = { flagKey };
      logError('[FWF_TRACKING]', { error, data });
    }
  }
}
