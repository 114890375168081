import { SEARCHED_LOCATION } from '@location/actionTypes';
import getSearchedLocation from '@location/selectors/getSearchedLocation';
import { error as logError } from '@logger';
import { set, get } from '../services/commonTrackingData';
import { LATITUDE, LONGITUDE } from '../services/constants';

const getLongitudeLatidude = actionPayload => {
  const result = { latitude: null, longitude: null };
  if (actionPayload?.latitude && actionPayload?.longitude) {
    result.longitude = actionPayload.longitude;
    result.latitude = actionPayload.latitude;
    return result;
  }
  if (actionPayload?.area?.latitude && actionPayload?.area?.longitude) {
    result.longitude = actionPayload.area.longitude;
    result.latitude = actionPayload.area.latitude;
    return result;
  }
  return result;
};

const proccessAddressSearchedEvent = (action, addressSearched) => {
  const { latitude, longitude } = getLongitudeLatidude(action.payload);
  if (!latitude || !longitude) return;
  if (addressSearched) {
    const {
      latitude: prevLatitude,
      longitude: prevLongitude,
    } = addressSearched;
    if (prevLatitude !== latitude && prevLongitude !== longitude) {
      set(LATITUDE, latitude);
      set(LONGITUDE, longitude);
    }
  } else {
    set(LATITUDE, latitude);
    set(LONGITUDE, longitude);
  }
};

// when page reload there will be a location on the rehydrated but not on memory, until next action SEARCHED_LOCATION
// this function should take care of this
const proccessPageReload = addressSearched => {
  if (addressSearched?.latitude && addressSearched?.longitude) {
    const inMemoryLatitude = get(LATITUDE);
    const inMemorylongitude = get(LONGITUDE);
    if (!inMemoryLatitude || !inMemorylongitude) {
      const { latitude, longitude } = addressSearched;
      set(LATITUDE, latitude);
      set(LONGITUDE, longitude);
    }
  }
};

const setLongitudeLatitude = (state, action) => {
  try {
    const addressSearched = getSearchedLocation(state);
    if (action.type === SEARCHED_LOCATION) {
      proccessAddressSearchedEvent(action, addressSearched);
    } else {
      proccessPageReload(addressSearched);
    }
  } catch (error) {
    logError(error);
  }
};

export default setLongitudeLatitude;
