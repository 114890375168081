import { createSelector } from 'reselect';

import { calcDiscount } from '@utils/number';

import getShop from '@core/reducers/shop/selectors/getShop';
import getMenu from './getMenu';

const getMenuWithDiscounts = createSelector(getShop, getMenu, (shop, menu) => {
  if (!shop || !menu) return null;
  const { discount } = shop;
  if (discount === 0) return menu;

  const sectionsWithDiscountApplied = menu.sections.reduce(
    (sections, section) => {
      const productsWithDiscount = section.products.reduce(
        (products, product) => {
          if (!product.applyDiscount) return [...products, product];

          const priceWithDiscount = calcDiscount(
            discount,
            product.applyDiscount,
            product.price,
          );

          return [...products, { ...product, priceWithDiscount }];
        },
        [],
      );

      return [...sections, { ...section, products: productsWithDiscount }];
    },
    [],
  );

  return { ...menu, sections: sectionsWithDiscountApplied };
});

export default getMenuWithDiscounts;
