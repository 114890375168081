import {
  MERCADO_PAGO,
  DECIDIR,
  INGENICO,
  SPREEDLY,
  EXTERNAL,
  WEBPAY_ONE_CLICK,
} from '@commons/utils/payment/paymentGatewayTypeConstants';
import { log } from '@logger';

let loadProviderSdk;
let setProviderPublishableKey;
let selectedPaymentProvider;

function getProviderPath(paymentMethod) {
  log(
    `[providers/index][getProviderPath] start, paymentMethod: ${paymentMethod}`,
  );
  switch (paymentMethod) {
    case DECIDIR:
      log(`[providers/index][getProviderPath] return, path: './decidir'`);
      return './decidir';
    case MERCADO_PAGO:
      log(`[providers/index][getProviderPath] return, path: './mercadoPago'`);
      return './mercadoPago';
    case INGENICO:
      log(`[providers/index][getProviderPath] return, path: './ingenico'`);
      return './ingenico';
    case EXTERNAL:
      log(`[providers/index][getProviderPath] return, path: './external'`);
      return './external';
    case SPREEDLY:
      log(`[providers/index][getProviderPath] return, path: './spreedly'`);
      return './spreedly';
    case WEBPAY_ONE_CLICK:
      log(`[providers/index][getProviderPath] return, path: './oneclick'`);
      return './oneclick';
    default:
      log(`[providers/index][getProviderPath] throw error`);
      throw new Error('paymentMethod is required');
  }
}

export async function initialize(gatewayType) {
  log(`[providers/index][initialize] start, gatewayType: ${gatewayType}`);
  if (
    loadProviderSdk &&
    setProviderPublishableKey &&
    selectedPaymentProvider === gatewayType
  ) {
    return Promise.resolve();
  }
  log(`[providers/index][initialize] no instance yet`);
  selectedPaymentProvider = gatewayType;
  const path = getProviderPath(gatewayType);
  const {
    loadInstance: loadInstanceProvider,
    setPublishableKey: setPublishableKeyProvider,
  } = await import(`${path}`);
  log(
    `[providers/index][initialize] was loadInstanceProvider loaded? ${!!loadInstanceProvider}`,
  );
  log(
    `[providers/index][initialize] was setPublishableKeyProvider loaded? ${!!setPublishableKeyProvider}`,
  );
  loadProviderSdk = loadInstanceProvider;
  setProviderPublishableKey = setPublishableKeyProvider;
  log(`[providers/index][initialize]return resolve promise`);
  return Promise.resolve();
}

export async function loadSDK() {
  log(`[providers/index][loadSDK] start`);
  if (!loadProviderSdk) {
    log(`[providers/index][loadSDK] no loadProviderSdk, throw error`);
    throw Error('must call initialize() before get the loadSDK');
  }
  log(`[providers/index][loadSDK] return result of loadProviderSdk()`);
  return loadProviderSdk();
}

export function setPublishableKey(instance, key) {
  log(`[providers/index][setPublishableKey] start`);
  if (!setProviderPublishableKey) {
    log(
      `[providers/index][setPublishableKey] !setProviderPublishableKey, throw error `,
    );
    throw Error(`must call initialize() before get the setPublishableKey`);
  }
  log(
    `[providers/index][setPublishableKey] call setProviderPublishableKey(instance, key)`,
  );
  setProviderPublishableKey(instance, key);
}
