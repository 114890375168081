import { LOCATION_POINT_TYPE } from '@location/constants';
import LocationPoint from './LocationPoint';

function GpsLocationPoint({ latitude, longitude, validated }) {
  return LocationPoint({
    latitude,
    longitude,
    type: LOCATION_POINT_TYPE.GPS,
    validated,
  });
}

export default GpsLocationPoint;
