export const SET_SELECTED_PAYMENT_METHOD =
  'app/checkout/payments/SET_SELECTED_PAYMENT_METHOD';

export const SET_SELECTED_AMOUNT_TO_PAY_WITH =
  'app/checkout/payments/SET_SELECTED_AMOUNT_TO_PAY_WITH';

export const SET_TICKET_NOTES = 'app/checkout/payments/SET_TICKET_NOTES';

export const CONFIRM_PAYMENT_METHOD =
  'app/checkout/payments/CONFIRM_PAYMENT_METHOD';

export const CLEAN_UNCONFIRMED_PAYMENT_METHOD =
  'app/checkout/payments/CLEAN_UNCONFIRMED_PAYMENT_METHOD';

export const ADD_ONLINE_CARD = 'app/checkout/payments/ADD_ONLINE_CARD';

export const DELETE_CONFIRMED_PAYMENT_METHOD =
  'app/checkout/payments/DELETE_CONFIRMED_PAYMENT_METHOD';

export const FETCH_USER_CREDIT_CARDS =
  'app/checkout/payments/FETCH_USER_CREDIT_CARDS';

export const DELETE_USER_CREDIT_CARD =
  'app/checkout/payments/DELETE_USER_CREDIT_CARD';

export const CLOSE_ONLINE_PAYMENT_ERROR_MODAL =
  'app/checkout/payments/CLOSE_ONLINE_PAYMENT_ERROR_MODAL';

export const FETCH_SHOP_BIN_DISCOUNTS =
  'app/checkout/payments/FETCH_SHOP_BIN_DISCOUNTS';

export const CONFIRM_SECURITY_CODE =
  'app/checkout/payments/CONFIRM_SECURITY_CODE';

export const CLEAR_RESTAURANT_CLOSED_ERROR =
  'app/checkout/payments/CLEAR_RESTAURANT_CLOSED_ERROR';

export const INIT_WEBPAY = 'app/checkout/payments/INIT_WEBPAY';

export const INIT_WEBPAY_ONE_CLICK =
  'app/checkout/payments/INIT_WEBPAY_ONE_CLICK';

export const FINISH_WEBPAY_ONE_CLICK =
  'app/checkout/payments/FINISH_WEBPAY_ONE_CLICK';

export const CLEAR_WEBPAY_ONE_CLICK_CARD =
  'app/checkout/payments/CLEAR_WEBPAY_ONE_CLICK_CARD';

export const ADD_CARD_BY_INSTRUMENT_ID =
  'app/checkout/payments/ADD_CARD_BY_INSTRUMENT_ID';

export const FETCH_EXTERNAL_PAYMENTS_URL =
  'app/checkout/payments/FETCH_EXTERNAL_PAYMENTS_URL';

export const FETCH_ADD_CARD_FORM_URL =
  'app/checkout/payments/FETCH_ADD_CARD_FORM_URL';

export const FETCH_SECURITY_CODE_FORM_URL =
  'app/checkout/payments/FETCH_SECURITY_CODE_FORM_URL';

export const SET_INSTRUMENT_CART = 'app/checkout/payments/SET_INSTRUMENT_CART';
