import { fromJS } from 'immutable';

import { successAction, failureAction } from '@utils/actions';
import { INIT_STORE } from '@shared/actionTypes';
import { FETCH_CITIES } from './actionTypes';
import ModuleStateModel, { DATA, IS_FETCHING, HAS_FETCHED } from './model';

function citiesReducer(state = ModuleStateModel, action) {
  switch (action.type) {
    case INIT_STORE:
      return ModuleStateModel.mergeDeep(state);

    case FETCH_CITIES:
      return state.set(IS_FETCHING, true).set(HAS_FETCHED, false);

    case successAction(FETCH_CITIES): {
      const { cities } = action.payload;
      return state
        .set(DATA, fromJS(cities))
        .set(IS_FETCHING, false)
        .set(HAS_FETCHED, true);
    }

    case failureAction(FETCH_CITIES):
      return state.set(IS_FETCHING, false).set(HAS_FETCHED, false);

    default:
      return state;
  }
}

export default citiesReducer;
