import { COMMON_DATA_KEYS } from './constants';

const errorLabel = '[COMMON_TRACKING_DATA]';
const data = {};

export const getCommonData = () =>
  Object.keys(COMMON_DATA_KEYS).reduce((result, currentKey) => {
    // eslint-disable-next-line no-param-reassign
    result[currentKey] = get(currentKey);
    return result;
  }, {});

const getRegisteredKey = key => {
  const registeredKey = COMMON_DATA_KEYS[key];
  if (!registeredKey)
    throw new Error(
      `${errorLabel} not valid COMMON_DATA_KEYS, you need to register ${key} before used it`,
    );
  return registeredKey;
};

export const set = (key, value) => {
  const registeredKey = getRegisteredKey(key);
  data[registeredKey] = value;
};

export const get = key => {
  const registeredKey = getRegisteredKey(key);
  return data[registeredKey] || null;
};
