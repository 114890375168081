import { LOCATION_CHANGE } from 'connected-react-router';

import cloneDeep from '@utils/cloneDeep';
import { getRouterLocation } from '@shared/selectors';

export const LOCATION_CHANGE_ACTION_TYPE = LOCATION_CHANGE;

export const isRouterLocationChangeAction = action =>
  action.type === LOCATION_CHANGE_ACTION_TYPE;

function setRouterPrevLocation(state, action) {
  let usedAction = action;
  const prevLocation = getRouterLocation(state);

  // in a full refresh, we don't want to set the prevLocation to the current location
  if (
    prevLocation?.pathname !== window.location.pathname ||
    prevLocation?.search !== window.location.search
  ) {
    usedAction = cloneDeep(action);
    usedAction.payload.prevLocation = getRouterLocation(state);
  }
  return usedAction;
}

export const setRouterPrevLocationMiddleware = store => next => action => {
  let usedAction = action;
  if (isRouterLocationChangeAction(usedAction)) {
    usedAction = setRouterPrevLocation(store.getState(), usedAction);
  }
  next(usedAction);
};
