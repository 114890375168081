import React from 'react';
import PropTypes from 'prop-types';
import { SkipLink, MAIN_CONTENT_ID } from '@components/SkipLink';

const SkipLinkConnected = ({ mainContentId }) => (
  <SkipLink mainContentId={mainContentId} />
);

SkipLinkConnected.propTypes = {
  mainContentId: PropTypes.string,
};

SkipLinkConnected.defaultProps = {
  mainContentId: MAIN_CONTENT_ID,
};

export default SkipLinkConnected;
