import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { getAllFlags } from '@funWithFlags/selectors';

import FlaggedRoute from './FlaggedRoute';

const mapStateToProps = createStructuredSelector({
  flags: getAllFlags,
});

export default connect(mapStateToProps, null)(FlaggedRoute);
