export const updateItemById = (id, list, updater) => {
  const itemIndex = list.findIndex(item => item.get('id') === id);
  if (itemIndex !== -1) return list.update(itemIndex, updater);
  return list;
};

export const updateFavoriteByUserOfShop = (shopId, shopList) =>
  updateItemById(shopId, shopList, item =>
    item.set('favoriteByUser', !item.get('favoriteByUser')),
  );

export const updateFavoriteByUserOfSwimlane = (shopId, swimlane) =>
  swimlane.set(
    'items',
    updateItemById(shopId, swimlane.get('items'), item =>
      item.set('isFavorite', !item.get('isFavorite')),
    ),
  );
