import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { routerMiddleware } from 'connected-react-router/immutable';

import { createServerHistory } from '@utils/history';
import reducerRegistry from '@utils/redux/ReducerRegistry';
import { INIT_STORE } from '@shared/actionTypes';
import fromJSGreedy from '@app/configureStore/fromJSGreedy';
import createReducer, { createRootReducer } from '@app/reducers';

async function configureServerStore({
  url,
  initialState = {},
  axiosInstance,
  reducersRegisters,
  actionsToDispatch = [],
}) {
  const immutableState = fromJSGreedy(initialState);

  const history = createServerHistory({
    initialEntries: [url],
  });

  const enhancers = [
    applyMiddleware(
      routerMiddleware(history),
      thunk.withExtraArgument(axiosInstance),
    ),
  ];

  const store = createStore(
    createReducer(initialState, history, reducersRegisters),
    immutableState,
    compose(...enhancers),
  );

  reducerRegistry.setChangeListener(reducers => {
    const [...stateKeys] = store.getState().keys();
    store.replaceReducer(createRootReducer(reducers, stateKeys));
  });

  store.dispatch({ type: INIT_STORE });

  await Promise.all(actionsToDispatch.map(action => action(store.dispatch)));

  return store;
}

export default configureServerStore;
