import { updateUser } from '@commons/fwf/instance';

function updateFwfCurrentDevice(device) {
  try {
    const contextData = {
      device,
    };
    updateUser({ contextData });
  } catch (error) {
    const updatedError = new Error(
      '[updateFwfCurrentDevice] fails to update device',
    );
    if (error.stack) updatedError.stack = error.stack;
    throw updatedError;
  }
}

export default updateFwfCurrentDevice;
