export const MODULE_NAME = 'results';
export const DELIVERY_TIME_MIN_MINUTES = '15';

export const USER_FAVORITES_SWIMLANE_MAX_RESULTS = 12;

export const SHOP_LIST_FILTER_CATEGORIES_MIN_CHARS = 3;
export const SHOP_LIST_FILTER_CATEGORIES_DEBOUNCE = 1000;

export const SWIMLANES_TYPES = {
  PARTNERS: 'partners',
  CATEGORIES: 'categories',
  PRODUCTS: 'products',
  ANY_PRODUCT: 'any_product',
  VENDOR: 'Vendor',
};

export const SWIMLANES_CODES = {
  USER_FAVORITES: 'userFavorites',
  FEATURED_PRODUCTS: 'featuredProducts',
};

export const SORT_FILTER = {
  DEFAULT: 'DEFAULT',
  RATING: 'RATING',
  DISTANCE: 'DISTANCE',
  TIME: 'DELIVERY_TIME',
};

export const MODAL_ID = {
  ShopsList: '',
  ChannelLanding: '',
  DefaultFilters: 'filters',
  Search: 'search',
  Cuisine: 'cuisine',
  Partners: 'partners',
};

export const TRACKING = {
  ENTER_CHANNEL: 'enter_channel',
  SEARCH: 'search',
  NO_RESULTS: 'no_results',
  SWIMLANE_LIST: 'swimlane_list',
  REMOVE_SEARCH: 'remove_search',
  SEARCH_AUTOCOMPLETE: 'search_autocomplete',
};

export const MIDAS_PRODUCTS_TYPES = {
  CUISINE: 'cuisine_placements',
  PREMIUM: 'premium_placements',
};

export const NEW_SHOP = 'Nuevo';

export const FILTERS = {
  ONLINE_PAYMENT: 'ONLINE_PAYMENT',
  PAYMENT_ON_DELIVERY: 'PAYMENT_ON_DELIVERY',
  FOOD_CATEGORIES: 'FOOD_CATEGORIES',
  DELIVERY_MODES: 'DELIVERY_MODES',
  DELIVERY_TYPES: 'DELIVERY_TYPES',
  OPTIONS: 'OPTIONS',
  BENEFITS: 'BENEFITS',
};

export const deliveryTypes = {
  VENDOR_DELIVERY: 'VENDOR_DELIVERY',
  OWN_DELIVERY: 'OWN_DELIVERY',
};

export const discountsTypes = {
  WEEKLY: 'WEEKLY',
};

export const calculationDiscountType = {
  PERCENTAGE: 'PERCENTAGE',
};

export const vendorStatus = {
  PRE_ORDER: 'PRE_ORDER',
};

export const CUSTOM_FILTERS_SHOPLIST = {
  'pick-up-home': 'DELIVERY_MODES-PICK_UP',
};

export const FILTER_CATEGORY = 'category';

export const PICKUP_FILTER = 'PICK_UP';

export const BUSINESS_TYPE = {
  RESTAURANT: 'restaurantes',
  PHARMACY: 'farmacias',
  GROCERIES: 'locales',
  COFFEE: 'restaurantes',
  KIOSKS: 'kioskos',
};
