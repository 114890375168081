import { fromJS } from 'immutable';

import { successAction, failureAction } from '@utils/actions';
import { INIT_STORE } from '@shared/actionTypes';
import { FETCH_CITY_AREAS } from './actionTypes';
import ModuleStateModel, { IS_FETCHING, DATA } from './model';
import Area from './models/Area';

function areasReducer(state = ModuleStateModel, action) {
  switch (action.type) {
    case INIT_STORE:
      return ModuleStateModel.mergeDeep(state);

    case FETCH_CITY_AREAS: {
      const { cityId } = action;
      return state.setIn([IS_FETCHING, cityId], true);
    }

    case successAction(FETCH_CITY_AREAS): {
      const {
        payload: { areas },
        cityId,
      } = action;
      return state
        .setIn([DATA, cityId], fromJS(areas.map(a => Area(a))))
        .setIn([IS_FETCHING, cityId], false);
    }

    case failureAction(FETCH_CITY_AREAS): {
      const { cityId } = action;
      return state.setIn([IS_FETCHING, cityId], false);
    }

    default:
      return state;
  }
}

export default areasReducer;
