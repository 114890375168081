import { colors } from '../colors';

const location = {
  suggestionsContainer: {
    margin: '$space_12 $space_20',
  },
  autocompleteAddress: {
    borderBottom: `1px solid ${colors.elementLine}`,
    padding: '$space_20 $space_20 $space_20 $space_32',
  },
  historyAddress: {
    borderBottom: `1px solid ${colors.elementLine}`,
    padding: '$space_20 0',
    iconPadding: '10px',
  },
  searchInputContainer: {
    padding: '0px 4% 4%',
    elevation: `0 15px 20px -19px $sepia_50`,
  },
  backButton: {
    backgroundColor: '$white',
    shadow: '0 6px 16px -8px $grape_75',
  },
  footer: {
    topbar: {
      backgroundColor: '$platinum',
    },
  },
};

export default location;
