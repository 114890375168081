import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';

import BasicHelmet from '../BasicHelmet';
import getCityLandingTitles from './getHelmetInfo';
import connect from './connect';

const defaultValue = { metaTitle: '', metaDescription: '' };

function CityLandingHeaders({ intl: { formatMessage }, area, category, city }) {
  const { metaTitle, metaDescription } = useMemo(
    () =>
      city
        ? getCityLandingTitles({
            formatMessage,
            area,
            category,
            city,
          })
        : defaultValue,
    [area, category, city, formatMessage],
  );

  return (
    <BasicHelmet
      title={metaTitle}
      metaContent={metaDescription}
      withPagination
    />
  );
}

CityLandingHeaders.propTypes = {
  intl: intlShape,
  city: PropTypes.string,
  area: PropTypes.string,
  category: PropTypes.string,
};

export default connect(CityLandingHeaders);
