import { isTest } from '@utils/environment';

export const successAction = action => appendSuffix(action, 'SUCCESS');
export const failureAction = (action, error) =>
  appendSuffix(action, 'FAILURE', error);

function appendSuffix(action, suffix, error) {
  if (typeof action === 'string') return `${action}_${suffix}`;
  if (Reflect.has(action, 'type')) {
    const dataToReturn = { ...action, type: `${action.type}_${suffix}` };
    if (!isTest()) dataToReturn.error = error;
    return dataToReturn;
  }

  return action;
}

export default { successAction, failureAction };
