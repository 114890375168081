import { combineReducers } from 'redux-immutable';
import { connectRouter } from 'connected-react-router/immutable';

import { getHistory } from '@utils/history';
import reducerRegistry from '@utils/redux/ReducerRegistry';
import { isServer } from '@commons/utils';
import coreReducers from '@core/reducers';

import fwfModuleRegister from './modules/fwf/register';
import sharedModuleRegister from './modules/shared/register';
import locationModuleRegister from './modules/location/register';
import userModuleRegister from './modules/user/register';
import registerJokerModule from './modules/joker/register';

export function createRootReducer(reducers, stateKeys) {
  const initialReducers = { ...reducers };

  stateKeys.forEach(item => {
    if (!reducers[item]) {
      initialReducers[item] = (state = null) => state;
    }
  });

  return combineReducers(initialReducers);
}

export default function createReducer(initialState, history, customsRegisters) {
  if (!isServer())
    reducerRegistry.register('router', connectRouter(history || getHistory()));

  sharedModuleRegister();
  fwfModuleRegister();
  locationModuleRegister();
  coreReducers();
  userModuleRegister();
  registerJokerModule();

  if (customsRegisters) customsRegisters.forEach(r => r());

  return createRootReducer(
    reducerRegistry.getReducers(),
    Object.keys(initialState),
  );
}
