import React from 'react';
import 'lazysizes/plugins/attrchange/ls.attrchange';
import 'lazysizes';

import '@utils/modernizr/cssStickyTransformDetection.min';
import App from '@app/app';
import load from './load';

async function beforeHydration(props) {
  try {
    const { trackerPromise, fwfPromise } = props;

    const loadedData = await load({ fwfPromise });

    const {
      gtmConfig,
      paymentKeys,
      ravelinConfig,
      device,
      messages,
      store,
      lng,
      googleMapKey,
      countryShortName,
    } = loadedData;

    return {
      App: function AppWrapper() {
        return (
          <App
            {...props}
            lng={lng}
            messages={messages}
            store={store}
            device={device}
            countryShortName={countryShortName}
          />
        );
      },
      data: {
        paymentKeys,
        ravelinConfig,
        store,
        gtmConfig,
        device,
        googleMapKey,
        trackerPromise,
      },
    };
  } catch (error) {
    console.error('An error occurred in beforeHydration:', error);
    throw error;
  }
}

export default beforeHydration;
