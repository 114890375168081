import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import useIsDesktopLayout from '@hooks/useIsDesktopLayout';

import connect from './connect';
import {
  goToPage,
  getComponentToRender,
  getDefaultComponentToRender,
} from './utils';

function ModalManager({ children, loading, page, location, pushPage, goBack }) {
  const isDesktop = useIsDesktopLayout();
  const render = useMemo(() => getComponentToRender(children, page, loading), [
    children,
    loading,
    page,
  ]);

  const renderDefault = useMemo(() => getDefaultComponentToRender(children), [
    children,
  ]);

  const go = useCallback(route => goToPage(route, location, pushPage), [
    pushPage,
    location,
  ]);

  const back = useCallback(fallbackUrl => goBack({ fallbackUrl }), [goBack]);

  const props = useMemo(() => ({ go, goBack: back }), [go, back]);

  if (!isDesktop) return render ? render(props) : null;

  return (
    <>
      {renderDefault(props)}
      {renderDefault !== render && render(props)}
    </>
  );
}

ModalManager.propTypes = {
  // calling component
  children: PropTypes.array.isRequired,
  loading: PropTypes.bool,

  // connect
  page: PropTypes.string,
  location: PropTypes.object.isRequired,
  pushPage: PropTypes.func.isRequired,
  goBack: PropTypes.func.isRequired,
};

ModalManager.defaultProps = {
  loading: false,
};

export default connect(ModalManager);
