import React from 'react';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import queryString from 'query-string';

import connect from './connect';

function CannonicalHeader({ canonicalUrl, location, withPagination = false }) {
  const { page } = queryString.parse(location.search);

  if (canonicalUrl) {
    return (
      <Helmet
        link={[
          {
            rel: 'canonical',
            href: `${canonicalUrl}${
              withPagination && page ? `?page=${page}` : ''
            }`,
          },
        ]}
      />
    );
  }

  return null;
}

CannonicalHeader.propTypes = {
  canonicalUrl: PropTypes.string,
  location: PropTypes.object,
  withPagination: PropTypes.bool,
};

export default connect(CannonicalHeader);
