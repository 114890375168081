export const SEND_ORDER_FAIL_TYPES = {
  ONLINE_PAYMENT_LOW_RISK: 'LOW_RISK',
  ONLINE_PAYMENT_HIGH_RISK: 'HIGH_RISK',
  INTERNAL_SERVER_ERROR: 'INTERNAL_SERVER_ERROR',
  ERROR_ORDER_TIME_SLOT_FULL: 'ERROR_ORDER_TIME_SLOT_FULL',
};

export const DEFAULT_ERROR_CATEGORY = 'SYSTEM';
export const INVALID_CARD_CATEGORY = 'INVALID_CARD';
export const INSUFFICIENT_FUNDS_CATEGORY = 'INSUFFICIENT_FUNDS';
export const EXPIRED_CARD_CATEGORY = 'EXPIRED_CARD';
export const BLOCKED_CARD_CATEGORY = 'BLOCKED_CARD';
export const FRAUD_RISK = 'FRAUD_RISK';

export const SEND_ORDER_FAIL_KNOWN_CATEGORIES = {
  DEFAULT_ERROR_CATEGORY,
  INVALID_CARD_CATEGORY,
};

function isLowRisk(errorMessages) {
  return errorMessages.some(x =>
    x.includes(SEND_ORDER_FAIL_TYPES.ONLINE_PAYMENT_LOW_RISK),
  );
}

function isHighRisk(errorMessages) {
  return errorMessages.some(x =>
    x.includes(SEND_ORDER_FAIL_TYPES.ONLINE_PAYMENT_HIGH_RISK),
  );
}

function getErrorCategory(errorMessages) {
  const errorCategory = errorMessages.find(x => x.includes('errorDetail:'));
  if (!errorCategory) return DEFAULT_ERROR_CATEGORY;
  const parsedCategory = errorCategory.replace('errorDetail:', '');
  return parsedCategory || DEFAULT_ERROR_CATEGORY;
}

function getErrorType(errorMessages) {
  if (errorMessages.length === 0)
    return SEND_ORDER_FAIL_TYPES.INTERNAL_SERVER_ERROR;

  if (isLowRisk(errorMessages))
    return SEND_ORDER_FAIL_TYPES.ONLINE_PAYMENT_LOW_RISK;

  if (isHighRisk(errorMessages))
    return SEND_ORDER_FAIL_TYPES.ONLINE_PAYMENT_HIGH_RISK;

  return SEND_ORDER_FAIL_TYPES.INTERNAL_SERVER_ERROR;
}

export function parseTransactionError({ error }) {
  const errorMessages = (error && error.messages) || [];
  const errorType = getErrorType(errorMessages);
  const errorCategory = getErrorCategory(errorMessages);

  return { type: errorType, category: errorCategory };
}
