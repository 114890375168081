import { fromJS, List } from 'immutable';

import { INIT_STORE } from '@shared/actionTypes';
import { successAction, failureAction } from '@utils/actions';
import { TOGGLE_FAVORITES } from '@core/reducers/shop/actionTypes';
import {
  updateFavoriteByUserOfSwimlane,
  updateFavoriteByUserOfShop,
} from '@utils/immutable';
import {
  FETCH_SWIMLANES,
  FETCH_FILTERS,
  FETCH_VENDORS,
  FETCH_MORE_VENDORS,
} from './actionTypes';

import model, {
  SWIMLANES,
  IS_FETCHING_SWIMLANES,
  FILTERS,
  IS_FETCHING_VENDORS,
  VENDORS_LIST,
  TOTAL_VENDORS_LIST,
  HAS_FETCHED_VENDORS,
  MIDAS_REQUEST_ID,
} from './model';

function coreShopListReducer(state = model, action) {
  switch (action.type) {
    case INIT_STORE:
      return model.mergeDeep(state);

    case FETCH_SWIMLANES:
      return state.set(IS_FETCHING_SWIMLANES, true);

    case failureAction(FETCH_SWIMLANES): {
      return state.set(IS_FETCHING_SWIMLANES, false).set(SWIMLANES, List());
    }

    case successAction(FETCH_SWIMLANES): {
      const { payload } = action;
      return state
        .set(SWIMLANES, fromJS(payload))
        .set(IS_FETCHING_SWIMLANES, false);
    }

    case failureAction(TOGGLE_FAVORITES):
    case TOGGLE_FAVORITES: {
      const { shopId } = action.payload;
      let newState =
        state.get(VENDORS_LIST).size > 0
          ? state.update(VENDORS_LIST, shopList =>
              updateFavoriteByUserOfShop(shopId, shopList),
            )
          : state;

      newState = newState.updateIn([SWIMLANES, 'data'], swimlanes =>
        swimlanes?.map(swimlane =>
          swimlane.get('type') === 'partners'
            ? updateFavoriteByUserOfSwimlane(shopId, swimlane)
            : swimlane,
        ),
      );
      return newState;
    }

    case FETCH_FILTERS: {
      return state.setIn([FILTERS, 'loading'], true);
    }
    case successAction(FETCH_FILTERS): {
      return state
        .setIn([FILTERS, 'data'], fromJS(action.payload))
        .setIn([FILTERS, 'loading'], false);
    }
    case failureAction(FETCH_FILTERS): {
      return state.setIn([FILTERS, 'loading'], false);
    }

    case FETCH_VENDORS:
      return state
        .set(IS_FETCHING_VENDORS, true)
        .set(HAS_FETCHED_VENDORS, false)
        .set(VENDORS_LIST, model.get(VENDORS_LIST))
        .set(TOTAL_VENDORS_LIST, model.get(TOTAL_VENDORS_LIST));

    case successAction(FETCH_VENDORS): {
      const { vendors, totalResults, midasRequestId } = action.payload;
      return state.merge({
        [IS_FETCHING_VENDORS]: false,
        [VENDORS_LIST]: vendors,
        [TOTAL_VENDORS_LIST]: totalResults,
        [HAS_FETCHED_VENDORS]: true,
        [MIDAS_REQUEST_ID]: midasRequestId,
      });
    }

    case failureAction(FETCH_VENDORS): {
      return state.merge({
        [IS_FETCHING_VENDORS]: false,
      });
    }

    case successAction(FETCH_MORE_VENDORS): {
      const { vendors, pagination } = action.payload;
      const vendorsUpdated =
        pagination?.offset === -1 ? model.get(VENDORS_LIST) : vendors;
      return state.updateIn([VENDORS_LIST], currentShops =>
        currentShops?.concat(fromJS(vendorsUpdated)),
      );
    }

    default:
      return state;
  }
}

export default coreShopListReducer;
