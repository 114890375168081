import { fromJS } from 'immutable';

export const IS_POSTING_LOGIN = 'isPostingLogin';
export const DATA = 'data';
export const SESSION_ID = 'sessionId';
export const IS_UPDATING_USER = 'isUpdatingUser';
export const NOTIFICATIONS = 'notifications';
export const SETTINGS = 'settings';
export const MOBILE = 'mobile';
export const PHONE_VERIFIED = 'phoneVerified';
export const ERROR = 'error';
export const STATUS = 'status';
export const STATUSES = {
  ERROR: 'error',
  IDLE: 'idle',
  LOADING: 'loading',
  SUCCESS: 'success',
};
export const GETTING_TC = 'gettingTC';
export const AGREEING_TC = 'agreeingTC';

export const createModel = ({
  isPostingLogin = false,
  data = null,
  sessionId = null,
  isUpdatingUser = false,
  gettingTC = false,
  agreeingTC = false,
} = {}) =>
  fromJS({
    [IS_POSTING_LOGIN]: isPostingLogin,
    [DATA]: data,
    [SESSION_ID]: sessionId,
    [IS_UPDATING_USER]: isUpdatingUser,
    [GETTING_TC]: gettingTC,
    [AGREEING_TC]: agreeingTC,
  });

const ModuleStateModel = createModel();

export default ModuleStateModel;
