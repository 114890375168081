import { logError, track } from '@tracking';

const EVENT_NAME = 'migration.store_clicked';

export const Origins = {
  Appetito24: 'appetito24',
  Glovo: 'glovo',
};

export const Stores = {
  Apple: 'apple',
  Google: 'google',
};

const trackMigrationLinkToStoreClicked = ({
  origin = Origins.Appetito24,
  store,
}) => {
  try {
    track(EVENT_NAME, { origin: origin ?? Origins.Appetito24, store });
  } catch (error) {
    logError(EVENT_NAME, error);
  }
};

export default trackMigrationLinkToStoreClicked;
