import React from 'react';
import PropTypes from 'prop-types';

import closeIcon from '@assets/icons/close_white_small.svg';
import isString from '@commons/utils/string/isString';

import TooltipContainer from './components/Container';
import Content from './components/Content';
import Pointer from './components/Pointer';
import CloseIcon from './components/CloseIcon';
import ContentMessage from './components/Message';

import { POINTER_POSITION } from './constants';

function Tooltip({ content, target, onClose, pointerPosition, styles }) {
  if (!target) return null;

  const targetPosition = target.getBoundingClientRect();
  const [firstPosition, secondPosition] = pointerPosition.split('-');

  return (
    <TooltipContainer
      targetEl={targetPosition}
      firstPosition={firstPosition}
      secondPosition={secondPosition}
    >
      <Content style={styles}>
        {isString(content) ? (
          <ContentMessage className="message">{content}</ContentMessage>
        ) : (
          content
        )}
        {onClose && (
          <CloseIcon>
            <img src={closeIcon} onClick={onClose} />
          </CloseIcon>
        )}
        <Pointer
          targetEl={targetPosition}
          firstPosition={firstPosition}
          secondPosition={secondPosition}
        />
      </Content>
    </TooltipContainer>
  );
}

Tooltip.propTypes = {
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  target: PropTypes.object.isRequired,
  onClose: PropTypes.func,
  pointerPosition: PropTypes.oneOf(Object.values(POINTER_POSITION)),
  styles: PropTypes.object,
};

Tooltip.defaultProps = {
  pointerPosition: POINTER_POSITION.TOP_LEFT,
};

export default Tooltip;
