import PropTypes from 'prop-types';

export const ID = 'id';
export const RENDER = 'render';
export const DEFAULT = 'default';
export const IS_ACTIVE = 'isActive';

function ModalManagerPage() {
  return null;
}

ModalManagerPage.propTypes = {
  // calling component
  [ID]: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  [IS_ACTIVE]: PropTypes.bool,
  [RENDER]: PropTypes.func.isRequired,
  [DEFAULT]: PropTypes.bool,
};

ModalManagerPage.defaultProps = {
  [DEFAULT]: false,
  [IS_ACTIVE]: false,
};

export default ModalManagerPage;
