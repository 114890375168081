import { track, logError } from '@tracking';
import getShop from '@core/reducers/shop/selectors/getShop';
import getShopId from '@core/reducers/shop/selectors/getShopId';
import getShopName from '@core/reducers/shop/selectors/getShopName';
import getShopStatus from '@utils/tracking/getShopStatus';
import getFromOrderId from '@core/reducers/cart/selectors/getFromOrderId';

import { DELETE_METHOD } from '../constants';

const EVENT_NAME = 'cart.deleted';

const trackCartDeleted = (deleteMethod = DELETE_METHOD.DELETE_ORDER) => (
  _,
  getState,
) => {
  try {
    const state = getState();
    const shop = getShop(state);

    const shopId = getShopId(state);
    const shopName = getShopName(state);
    const shopStatus = getShopStatus(shop);
    const repeatedOrder = Boolean(getFromOrderId(state));

    const payload = {
      shopId,
      shopName,
      shopStatus,
      repeatedOrder,
      deleteMethod,
    };

    track(EVENT_NAME, payload);
  } catch (error) {
    logError(EVENT_NAME, error);
  }
};

export default trackCartDeleted;
