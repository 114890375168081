import {
  STG_ENVIRONMENT,
  PROD_ENVIRONMENT,
  DEV_ENVIRONMENT,
} from '@commons/config/constants';

const SENTRY_DSN = {
  [PROD_ENVIRONMENT]:
    'https://96e54ad4758248909fd81e871ac13789@o4504046939799552.ingest.sentry.io/4504798871486464',
  [STG_ENVIRONMENT]:
    'https://96e54ad4758248909fd81e871ac13789@o4504046939799552.ingest.sentry.io/4504798871486464',
  [DEV_ENVIRONMENT]:
    'https://96e54ad4758248909fd81e871ac13789@o4504046939799552.ingest.sentry.io/4504798871486464',
};

export const getSentryDSN = env => SENTRY_DSN[env] || null;

export const SENTRY_ENVIRONMENTS = [
  STG_ENVIRONMENT,
  PROD_ENVIRONMENT,
  DEV_ENVIRONMENT,
];
