import { connect } from 'react-redux';
import { compose } from 'redux';
import { injectIntl as withIntl } from 'react-intl';
import { createStructuredSelector } from 'reselect';

const mapStateToProps = createStructuredSelector({});

const withConnect = connect(mapStateToProps);

export default compose(
  withIntl,
  withConnect,
);
