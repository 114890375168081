import { Map, OrderedMap } from 'immutable';
import { successAction, failureAction } from '@utils/actions';
import { FAVORITES_DISHES_SECTION_ID } from '@shopDetails/constants';
import orderFlowReducer from '@core/utils/orderFlowReducer';
import { INIT_STORE } from '@shared/actionTypes';
import { SET_SHOP } from '@core/reducers/shop/actionTypes';
import {
  FETCH_EXTENDED_MENU,
  FETCH_SHELVES_MENU,
  FETCH_SHELVES_MENU_ITEMS,
  FETCH_MENU_SECTION,
  FILTER_MENU,
  ADD_FAVORITE_DISH,
  REMOVE_FAVORITE_DISH,
  SHOW_TOOLTIP_FAVORITE_DISH,
  HIDE_TOOLTIP_FAVORITE_DISH,
} from './actionTypes';

import model, {
  DATA,
  IS_FETCHING,
  HAS_FETCHED,
  FETCHED_DATE,
  FILTER,
  TOOLTIP_FAVORITE_DISH,
} from './model';

function mapProductsToImmutable(products) {
  const iProducts =
    products?.reduce((result, product) => {
      const current = Map(product);
      return result.set(product.id, current);
    }, OrderedMap()) || OrderedMap();

  return iProducts;
}

function mapSectionToImmutable(section) {
  return OrderedMap()
    .set('enabled', section.enabled)
    .set('id', section.id)
    .set('name', section.name)
    .set('index', section.index)
    .set('isDeleted', section.isDeleted)
    .set('requiresAgeCheck', section.requiresAgeCheck)
    .set('image', section.image)
    .set('customImage', section.customImage)
    .set('totalProducts', section.totalProducts)
    .set('products', mapProductsToImmutable(section.products));
}

function mapSectionsToImmutable(sections) {
  const iSections =
    sections?.reduce(
      (result, section) =>
        result.set(section.id, mapSectionToImmutable(section)),
      OrderedMap(),
    ) || OrderedMap();

  return iSections;
}

function menuToImmutable(menu) {
  const iMenu = OrderedMap({
    ...menu,
    sections: mapSectionsToImmutable(menu.sections),
  });

  return iMenu;
}

function mapSectionFavoriteDish(product) {
  return mapSectionsToImmutable([
    {
      enabled: true,
      id: FAVORITES_DISHES_SECTION_ID,
      name: 'Mis Favoritos',
      products: [{ ...product, isFavourite: true }],
      totalProducts: 1,
    },
  ]);
}

function menuReducer(state = model, action) {
  switch (action.type) {
    case INIT_STORE:
      return model.mergeDeep(state);

    case SET_SHOP:
      return model;

    case FETCH_SHELVES_MENU:
    case FETCH_EXTENDED_MENU:
      return state.set(IS_FETCHING, true).set(HAS_FETCHED, false);

    case FETCH_MENU_SECTION:
      return state.set('isFetching', true).set('hasFetchedMenu', false);

    case failureAction(FETCH_SHELVES_MENU):
    case failureAction(FETCH_EXTENDED_MENU):
    case failureAction(FETCH_MENU_SECTION): {
      return state.set(IS_FETCHING, false).set(HAS_FETCHED, false);
    }

    case successAction(FETCH_EXTENDED_MENU): {
      const { menu } = action.payload;
      return state
        .set(DATA, menuToImmutable(menu))
        .set(IS_FETCHING, false)
        .set(HAS_FETCHED, true)
        .set(FETCHED_DATE, Date.now());
    }

    case successAction(FETCH_SHELVES_MENU): {
      const { menu } = action.payload;
      const immutableMenu = menuToImmutable(menu);

      const newState = state
        .set(IS_FETCHING, false)
        .set(HAS_FETCHED, true)
        .set(FETCHED_DATE, Date.now());

      if (!state.get(DATA)) return newState.set(DATA, immutableMenu);

      const oldSectionsMap = state.getIn([DATA, 'sections']);
      const newSectionsMap = immutableMenu.get('sections');
      const oldSectionCount = state.getIn([DATA, 'count']) || 0;
      const newCount = oldSectionCount + menu.count;

      return newState
        .setIn([DATA, 'sections'], oldSectionsMap.merge(newSectionsMap))
        .setIn([DATA, 'count'], newCount);
    }

    case successAction(FETCH_SHELVES_MENU_ITEMS): {
      const { data, sectionId, sort, fetchMoreFiltered } = action.payload;

      const newProducts = mapProductsToImmutable(data);

      if (sort && !fetchMoreFiltered) {
        return state.setIn(
          [DATA, 'sections', sectionId, 'products'],
          newProducts,
        );
      }

      return state.updateIn(
        [DATA, 'sections', sectionId, 'products'],
        products => products.merge(newProducts),
      );
    }

    case successAction(FETCH_MENU_SECTION): {
      const { section } = action.payload;

      return state
        .setIn([DATA, 'sections', section.id], mapSectionToImmutable(section))
        .set(IS_FETCHING, false)
        .set(HAS_FETCHED, true);
    }

    case FILTER_MENU: {
      const search = action.payload;
      return state.set(FILTER, search);
    }

    case SHOW_TOOLTIP_FAVORITE_DISH: {
      const { title } = action.payload;
      return state.set(TOOLTIP_FAVORITE_DISH, Map({ show: true, title }));
    }

    case HIDE_TOOLTIP_FAVORITE_DISH: {
      const { title } = action.payload;
      return state.set(TOOLTIP_FAVORITE_DISH, Map({ show: false, title }));
    }

    case ADD_FAVORITE_DISH: {
      const { product, sectionId } = action.payload;
      const { id } = product;
      const saveDishes = state.getIn([
        DATA,
        'sections',
        FAVORITES_DISHES_SECTION_ID,
      ]);
      if (saveDishes) {
        return state
          .updateIn(
            [DATA, 'sections', FAVORITES_DISHES_SECTION_ID, 'products'],
            products => products.merge(mapProductsToImmutable([product])),
          )
          .setIn(
            [DATA, 'sections', sectionId, 'products', id, 'isFavourite'],
            true,
          );
      }
      return state
        .updateIn([DATA, 'sections'], sections =>
          mapSectionFavoriteDish(product).merge(sections),
        )
        .setIn(
          [DATA, 'sections', sectionId, 'products', id, 'isFavourite'],
          true,
        );
    }

    case REMOVE_FAVORITE_DISH: {
      const { product, sectionId } = action.payload;
      const { id } = product;
      return state
        .deleteIn([
          DATA,
          'sections',
          FAVORITES_DISHES_SECTION_ID,
          'products',
          id,
        ])
        .setIn(
          [DATA, 'sections', sectionId, 'products', id, 'isFavourite'],
          false,
        );
    }

    default:
      return state;
  }
}

export { menuToImmutable };

export default orderFlowReducer(menuReducer, model);
