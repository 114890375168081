/*
 * Home Messages
 *
 * This contains all the text for the CityLanding component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  HomeMetaTitle: {
    id: 'app.module.containers.Home.metaTitle',
    defaultMessage: 'Delivery de Comida Online - Elegí, Pedí y Ya | PedidosYa',
  },
  HomeMetaDescription: {
    id: 'app.module.containers.Home.metaDescription',
    defaultMessage:
      'Pedí lo que quieras en PedidosYa. Descubrí el menú de tus restaurantes favoritos, comprá en supermercados, farmacias ¡y recibí tu pedido donde estés!',
  },
});
