import { COUNTRY_SHORT_NAME } from '@commons/constants';

export function getUrlData(params, categorySuffix) {
  const { city } = params;
  let { area, category } = params;

  if (area && area.endsWith(categorySuffix)) {
    category = area.replace(categorySuffix, '');
    area = null;
  } else if (category && category.endsWith(categorySuffix)) {
    category = category.replace(categorySuffix, '');
  }
  return { city, area, category };
}

export function getCountryCategorySuffix(countryShortName) {
  switch (countryShortName) {
    case COUNTRY_SHORT_NAME.CHILE:
      return '-a-domicilio';
    default:
      return '-delivery';
  }
}
