import axios from 'axios';
import axiosRetry from 'axios-retry';
import isRetryAllowed from 'is-retry-allowed';
import { log as logError } from '@logger';
import clearSession from './clearSession.service';

let instance = null;

const INVALID_TOKEN_403_RESPONSE = 'INVALID_TOKEN';

function isNetworkError(error) {
  return (
    !error.response &&
    Boolean(error.code) && // Prevents retrying cancelled requests
    error.code !== 'ECONNABORTED' && // Prevents retrying timed out requests
    isRetryAllowed(error) // Prevents retrying unsafe errors
  );
}

async function errorResponseHandler(error) {
  try {
    const { message } = error;
    const data = error.response?.data || {};
    const url = error.config?.url;
    const status = error.response?.status || 'unknown';

    if (error.response?.data?.code === INVALID_TOKEN_403_RESPONSE) {
      await clearSession({ axios });
    }

    if (isNetworkError(error)) {
      return Promise.reject(error);
    }

    const dataToLog = JSON.stringify({
      status,
      url,
      message,
      data,
    });

    logError(`[AXIOS_ERROR_INTERCEPTOR] ${dataToLog}`);

    return Promise.reject(error);
  } catch {
    return Promise.reject(error);
  }
}

const errorInterceptor = axiosInstance => {
  axiosInstance.interceptors.response.use(
    response => response,
    errorResponseHandler,
  );
};

const retryInterceptor = axiosInstance =>
  axiosRetry(axiosInstance, {
    retries: 3,
    // only retries on internet connection errors
    retryCondition: isNetworkError,
    retryDelay: axiosRetry.exponentialDelay,
  });

export function initialize(props) {
  instance = (props && axios.create(props)) || axios.create();

  retryInterceptor(instance);
  errorInterceptor(instance);

  return instance;
}

const getInstance = props => instance || initialize(props);

export default getInstance;
