import React from 'react';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';
import Helmet from 'react-helmet';
import { joinAsSentence } from '@utils/string';
import { getTopCategories } from '@utils/shop';
import createSchemaRestaurant from '@shopDetails/utils/createSchemaRestaurant';
import isRestaurantOrCoffee from '@shopDetails/utils/isRestaurantOrCoffee';
import BasicHelmet from '../BasicHelmet';
import shopDetailsMessages from './messages';
import connect from './connect';

function getTopCategoriesMetaDescription(shop) {
  return joinAsSentence(getTopCategories(shop).map(x => x.name));
}

function ShopDetailsHeaders({ intl: { formatMessage }, shop, menu }) {
  if (!shop) return null;

  const title = formatMessage(shopDetailsMessages.title, {
    shopName: shop.name,
  });

  const metaDescription = formatMessage(
    shopDetailsMessages.metaDescriptionWithTopCategories,
    {
      shopName: shop.name,
      topCategories: getTopCategoriesMetaDescription(shop),
    },
  );

  const showSchema =
    isRestaurantOrCoffee(shop.businessType) && menu?.sections?.length > 0;

  return (
    <>
      <BasicHelmet title={title} metaContent={metaDescription} />
      {showSchema && (
        <Helmet>
          <script type="application/ld+json">
            {JSON.stringify(
              createSchemaRestaurant({
                sections: menu.sections,
                name: shop.name,
                ratingCount: shop.validReviewCount,
                ratingValue: shop.generalScore,
              }),
            )}
          </script>
        </Helmet>
      )}
    </>
  );
}

ShopDetailsHeaders.propTypes = {
  intl: intlShape,
  shop: PropTypes.shape({
    name: PropTypes.string,
    validReviewCount: PropTypes.number,
    generalScore: PropTypes.number,
    businessType: PropTypes.string,
  }),
  menu: PropTypes.shape({
    sections: PropTypes.array,
  }),
};

export default connect(ShopDetailsHeaders);
