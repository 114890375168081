import { BUSINESS_TYPE } from '@commons/constants';

/**
 * Helper to check if the current BussinessType is "Restaurant" or "Coffee"
 * @param {string} businessType
 * @returns bool
 */
function isRestaurantOrCoffee(businessType) {
  return (
    businessType === BUSINESS_TYPE.RESTAURANT ||
    businessType === BUSINESS_TYPE.COFFEE
  );
}

export default isRestaurantOrCoffee;
