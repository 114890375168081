import React from 'react';
import ReactDOM from 'react-dom';
import singleSpaReact from 'single-spa-react';

import beforeHydratation from '@app/run/beforeHydratation';
import afterHydratation from '@app/run/afterHydratation';

let data = null;
const appId = 'app';

const reactLifecycles = singleSpaReact({
  React,
  ReactDOM,
  renderType: 'hydrate',
  loadRootComponent: async props => {
    const { App, data: loadedData } = await beforeHydratation(props);
    data = loadedData;
    return App;
  },
  domElementGetter: () => {
    let element = document.getElementById(appId);
    if (!element) {
      element = document.createElement('div');
      element.id = appId;
      document.body.appendChild(element);
    }
    return element;
  },
  errorBoundary(err, info, props) {
    // Customize the root error boundary for your microfrontend here.
    console.error('Error mounting Monolith');
    console.error(err);
    console.error(info);
    console.error(props);
    return <div>Some error occur mounting this app</div>;
  },
});

const domElementRemover = async () => {
  const element = document.getElementById(appId);
  if (element) {
    element.remove();
  }
};

export const { bootstrap } = reactLifecycles;

export const mount = [reactLifecycles.mount, () => afterHydratation(data)];

export const unmount = [reactLifecycles.unmount, domElementRemover];
