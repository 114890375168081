import { fromJS } from 'immutable';

export const IS_FETCHING = 'isFetching';
export const HAS_FETCHED = 'hasFetched';
export const DATA = 'data';
export const HIDE_NOTES = 'hideNotes';
export const IS_FETCHING_VENDOR_CONFIG_NOTES = 'isFetchingVendorConfigNotes';

export const createModel = ({
  isFetching = false,
  hasFetched = false,
  data = null,
  isFetchingVendorConfigNotes = false,
  hideNotes = null,
} = {}) =>
  fromJS({
    [DATA]: data,
    [IS_FETCHING]: isFetching,
    [HAS_FETCHED]: hasFetched,
    [IS_FETCHING_VENDOR_CONFIG_NOTES]: isFetchingVendorConfigNotes,
    [HIDE_NOTES]: hideNotes,
  });

const model = createModel();

export default model;
