import { log } from '@logger';
import {
  loadSDK as sdkcLoadInstance,
  setPublishableKey as sdkcSetPublishableKey,
  initialize as sdkcInitialize,
} from './payments/providers';
import { getOnlinePaymentData as sdkcGetOnlinePaymentData } from './payments/token';

let type;
let sdkckeys;
let sdkcInstance;

export const setPaymentGatewayKeys = keys => {
  const prefix = '[paymentGateway][setPaymentGatewayKeys]';
  log(`${prefix} invocation`);
  log(`${prefix} selected type: ${type}`);
  if (!keys) throw new Error('Missing key values');
  sdkckeys = keys;
  log(`${prefix} keys stored`);
  if (sdkcInstance && keyExist()) {
    log(`${prefix} call sdkcSetPublishableKey`);
    sdkcSetPublishableKey(sdkcInstance, getKey());
  }
};

export async function loadPaymentGateway(gatewayType) {
  const prefix = '[paymentGateway][loadPaymentGateway]';
  log(`${prefix} invocation`);
  log(`${prefix} gatewayTpe: ${gatewayType}, selected type: ${type}`);
  if (!gatewayType)
    throw new Error('Type is required to load "payment gateway service"');
  if (sdkcInstance?.gatewayType === gatewayType) return;
  type = gatewayType;
  log(`${prefix} call sdkcInitialize for type: ${gatewayType}`);
  await sdkcInitialize(gatewayType);
  log(`${prefix} sdkcInitialize finished`);
  log(`${prefix} call sdkcLoadInstance`);
  const sdkInstancePromise = sdkcLoadInstance(type);
  log(`${prefix} sdkcLoadInstance finished`);
  const instance = await sdkInstancePromise;
  log(`${prefix} sdkcLoadInstance promise resolved`);
  sdkcInstance = instance;
  sdkcInstance.gatewayType = gatewayType;
  if (keyExist()) {
    log(`${prefix} keyExist() == true call sdkcSetPublishableKey`);
    sdkcSetPublishableKey(sdkcInstance, getKey());
  }
}

export async function getOnlinePaymentData(paymentMethod) {
  try {
    if (!sdkcInstance)
      throw new Error('must call loadPaymentGateway() before get the instance');
    log(
      `[paymentGateway][getOnlinePaymentData] sdkInstace OK, call sdkcGetOnlinePaymentData`,
    );
    return sdkcGetOnlinePaymentData(paymentMethod, type, sdkcInstance);
  } catch (e) {
    throw new Error('Gateway fail to create token');
  }
}

function keyExist() {
  return !!type && !!sdkckeys && !!sdkckeys[type];
}

function getKey() {
  if (!keyExist()) throw new Error('missing sdk keys');
  return sdkckeys[type];
}
