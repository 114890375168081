export const MODULE_NAME = 'shopDetails';
export const SHELVES_SECTIONS_MAX_QUANTITY = 20;
export const SHELVES_PRODUCTS_MAX_QUANTITY = 10;
export const SECTIONS_LIST_MAX_QUANTITY = 20;

export const SHELVES_PRODUCTS_EXPAND_MIN_QUANTITY = 3;

export const SHOP_TABS = [
  { id: 1, name: 'Catálogo' },
  { id: 2, name: 'Secciones' },
];

export const SHOP_TAB_INDEX = 1;

export const ONLINE_PAYMENT_TOOLTIP_TIMER = 5000;

export const HIGHLIGHTED_PRODUCTS_SECTION_ID = 0;
export const DISCOUNTS_PRODUCTS_SECTION_ID = 1;
export const FAVORITES_DISHES_SECTION_ID = 'favourites-section-id';
export const PREFERRED_DISHES_SECTION_ID = 3;

export const EXTENDED_MENU_SECTION_OFFSET = 250;
export const EXTENDED_MENU_SECTION_OFFSET_MAX = 300;

export const MENU_SECTIONS_MAX_QUANTITY = 10;

export const SHOP_SCHEDULE_DAYS = {
  1: 'Domingo',
  2: 'Lunes',
  3: 'Martes',
  4: 'Miércoles',
  5: 'Jueves',
  6: 'Viernes',
  7: 'Sábado',
};

export const FEEDBACK_TRACKING = 'restaurant_feedback';
export const FEEDBACK_CLICK_LOCATION = {
  BUTTON: 'button',
  OUTSIDE: 'outside',
};

export const CAPACITY_CHECK = {
  FULL: 'FULL',
  MEDIUM: 'MEDIUM',
  LOW: 'LOW',
  NONE: 'NONE',
};

export const SHOP_NOTIFICATIONS = {
  PREORDER: 'preorder, header',
  OPEN_LATER: 'opens_later, header',
  CLOSED_TODAY: 'closed_today, header',
  MOMENTARILY_CLOSED: ' | momentarily_closed, toast',
};

export const SWIMLANES = {
  DISCOUNTS: 'product_discounts',
  RECOMMENDED_PRODUCTS: 'recommended_products',
  CROSS_SELLING_PRODUCTS: 'cross_selling_products',
  PREFERRED_DISHES: 'past_products',
};
