import { ignore } from '@utils/object';
import { NOT_SET } from '@utils/tracking/constants';
import saveTracking from '@tracking/services/saveData';
import { getCommonData } from '@tracking/commonTrackingData/services/commonTrackingData';

const DO_NOT_MODIFY = ['userId'];

function shouldReplaceToNotSet(key, value) {
  return (
    !DO_NOT_MODIFY.includes(key) &&
    (typeof value === 'undefined' || value === null || value === '')
  );
}

function mapPropsToTracking(data) {
  try {
    return Object.keys(data).reduce((obj, key) => {
      const value = data[key];
      // eslint-disable-next-line no-param-reassign
      obj[key] = shouldReplaceToNotSet(key, value) ? NOT_SET : value;
      return obj;
    }, {});
  } catch (err) {
    return data;
  }
}

export function formatDataForWebTrack(
  payload,
  { excludeFromCommon, format = true },
) {
  const commonTrackingData = getCommonData();
  const filteredCommonTrackingData = ignore(
    commonTrackingData,
    excludeFromCommon,
  );
  const data = {
    ...payload,
    ...filteredCommonTrackingData,
  };
  return format ? mapPropsToTracking(data) : data;
}

export function webTrack(event, payload, { excludeFromCommon }) {
  const data = {
    event,
    ...formatDataForWebTrack(payload, { excludeFromCommon }),
  };
  window.dataLayer.push(data);
  saveTracking(data);
}

export function isWebTrack() {
  if (window?.dataLayer) {
    return true;
  }
  return false;
}
