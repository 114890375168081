import loadScript, { checkIsLoaded } from '@utils/loadScript';
import { error } from '@logger';

export const SCRIPT_ID = 'google_maps';

function getGoogleMapsScript(language, googleMapKey) {
  const languageQuery = `language=${language}`;
  const idQuery = `key=${googleMapKey}`;
  const src = `https://maps.googleapis.com/maps/api/js?${idQuery}&${languageQuery}&callback=onMapLoad&libraries=marker&loading=async`;
  return src;
}

const googleMapAuthErrorHandler = () => error('[GOOGLE MAP ERROR]');

const defaultCallback = () => {};

function loadGoogleMapsScript({
  countryLanguage,
  callback = defaultCallback,
  googleMapKey,
}) {
  const src = getGoogleMapsScript(countryLanguage, googleMapKey);
  // create global function to listen google maps errors
  // https://developers.google.com/maps/documentation/javascript/events?hl=es#auth-errors
  if (window) window.gm_authFailure = googleMapAuthErrorHandler;
  loadScript({ src, callback, id: SCRIPT_ID });
}

export function checkGoogleMapsScriptIsLoaded() {
  return checkIsLoaded({ id: SCRIPT_ID });
}

export default loadGoogleMapsScript;
