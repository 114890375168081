import fenixTheme from '@pedidosya/web-fenix/theme';
import fontFamily, { fonts } from './fonts';
import baseColors, { colors } from './colors';
import spaces, { spacesFn } from './spaces';
import zIndex from './position';
import { mapValues } from './utils';
import {
  location,
  orderStatus,
  cart,
  checkout,
  vouchers,
  riderReview,
} from './modules';

export const LATERAL_SPACE_PERCENTAGE = 4;

const theme = {
  colors,
  spaces,
  spacing: spacesFn,
  fontFamily,
  fonts,
  constants: {
    lateralSpace: `${LATERAL_SPACE_PERCENTAGE}%`,
    zIndex,
  },

  commons: {
    global: {
      backgroundColor: '$white',
      textPrimary: '$night_blue_1',
      textSecondary: '$night_blue_2',
      // DEPRECATED
      textHint: 'rgb(169, 177, 183)',
      error: 'rgb(194, 0, 24)',
      buttonAnimateGray: 'rgb(218, 224, 229)',
      notification: 'rgb(234, 242, 253)',
      notificationYellow: 'rgb(252, 247, 229)',
      elementLine: 'rgb(218, 224, 229)',
      elementLightGrey: 'rgb(246, 248, 248)',
      elementTransparentGrey: 'rgba(218, 224, 229, 0.58)',
      elementInputDisable: 'rgb(221, 226, 229)',
      overlay: '$grape_75',
    },
    boxMessage: {
      informative: '$limon_lighter_1',
      positive: '$lime_lighter_1',
      negative: '$atomic_tangerine',
      border: '$deep_purple',
    },
    sidebar: {
      backgroundColor: '$white',
      elevation: '-1px 2px 11px -1px rgb(204, 204, 204)',
      zIndex: 10000,
    },
    optionWithGraphic: {
      background: '$white',
      backgroundSelected: 'rgb(254, 244, 245)',
    },
    modal: {
      spaces: '$space_32 $space_24 $space_24 $space_24',
    },
    button: {
      sizes: {
        full: {
          width: '100%',
          padding: '$space_16 $space_40',
          lineHeight: 'normal',
          iconWidth: '20px',
        },
        large: {
          width: 'auto',
          padding: '$space_16 $space_40',
          lineHeight: 'normal',
          iconWidth: '20px',
        },
        small: {
          width: 'auto',
          padding: '$space_6 $space_16',
          lineHeight: '1.5',
          iconWidth: '12px',
        },
        medium: {
          width: '100%',
          padding: '$space_8',
          lineHeight: 'normal',
          iconWidth: '$space_20',
        },
      },
      primary: {
        background: '$red',
        backgroundPressed: 'linear-gradient(to right, $red_darker_1, $red)',
        backgroundActive: '$red',
        color: '$white',
        colorActive: '$white',
        opacity: '30%',
        borderRadius: '50px',
      },
      secondary: {
        background: '$platinum',
        backgroundPressed:
          'linear-gradient(to right, $french_gray_1, $platinum)',
        backgroundActive: '$deep_purple',
        color: '$night_blue_1',
        colorActive: '$white',
        opacity: '30%',
        borderRadius: '50px',
      },
      tertiary: {
        background: 'none',
        backgroundPressed: 'none',
        backgroundActive: 'none',
        color: '$night_blue_1',
        colorActive: '$night_blue_1',
        opacity: '30%',
        borderRadius: '50px',
      },
    },
    icon: {
      size: {
        xsmall: '12',
        small: '16',
        medium: '24',
        large: '32',
      },
      color: {
        default: '$deep_purple',
        brand: '$red',
        white: '$white',
        disabled: '$french_gray_1',
        gray: '$night_blue_2',
      },
    },
    card: {
      padding: '$space_12',
      margin: '$space_12 $space_16',
      borderRadius: '6px',
      background: '$white',
      elevation: '0 8px 34px -25px $sepia_80',
      borderTopRadius: '6px 6px 0px 0px',
    },
    header: {
      textColor: '$night_blue_1',
      background: '$white',
      elevation: '0 16px 24px -18px $sepia_50',
      padding: '$space_16',
      margin: '0 0 $space_12 0',
      zIndexMainHeader: 900,
      zIndexHeaderWithSidebar: 999,
      zIndexHeaderSearchPage: 999,
      zIndexFixedHeader: 100,
      gradient: 'linear-gradient($transparent_50 0%, $transparent 80%)',
    },
    footer: {
      background: '$white',
      elevation: '0 -16px 34px -28px $sepia_50',
      zIndex: 100,
    },
    input: {
      background: '$white',
      border: '1px solid $night_blue_3',
      borderRadius: '34px',
      focusColor: '$deep_purple',
      successColor: 'rgb(1, 201, 140)',
      errorColor: '$red_darker_2',
      sandElevation: '0 8px 34px -25px $sepia_80',
      whiteElevation: '0 3px 14px -8px $sepia_20',
    },
    search: {
      search: {
        background: '$white',
      },
      searchInput: {
        background: {
          primary: '$white',
          secondary: '$platinum',
        },
        crossBackground: '$french_gray_1',
        height: '36px',
      },
      suggestionsSearch: {
        borderBottom: '1px solid $platinum',
      },
    },
    currencySymbol: {
      marginTop: '$space_20',
    },
    actionableOptionItem: {
      padding: '2vh 0',
      borderBottom: `1px solid ${colors.platinum}`,
    },
    option: {
      padding: '15px 0',
    },
    bottomSheet: {
      elevation: '0 -32px 84px -10px $grape_50',
      handlerColor: '$platinum',
    },
    tabs: {
      textColor: '$night_blue_2',
      textColorActive: '$night_blue_1',
      borderColorActive: '$deep_purple',
    },
    tabsContainer: {
      height: '56px',
      lineHeight: '54px',
      zIndex: '10',
      overflow: 'hidden',
    },
    tags: {
      plus: {
        background: '$night_blue_1',
        color: '$french_gray_2',
      },
      translucent: {
        background: '$tranlucent_grey',
        color: '$white',
      },
      deals: {
        background: '$limon',
        color: '$night_blue_1',
      },
      brand: {
        background: '$red_lighter_2',
        color: '$red',
      },
      warning: {
        background: '$limon_lighter_1',
        color: '$night_blue_1',
      },
      danger: {
        background: '$atomic_tangerine',
        color: '$night_blue_1',
      },
      default: {
        background: '$french_gray_2',
        color: '$night_blue_2',
      },
    },
    checkBox: {
      selectedColor: '$deep_purple',
      borderColor: '$night_blue_2',
    },
    radioButton: {
      selectedColor: '$deep_purple',
      borderColor: '$night_blue_2',
      background: '$white',
      disabled: '$french_gray_1',
    },
    rating: {
      none: {
        textColor: '$night_blue_3',
        backgroundColor: '$french_gray_2',
      },
      gradeA: {
        backgroundColor: '$rating_a_background',
        textColor: '$rating_a_foreground',
      },
      gradeB: {
        backgroundColor: '$rating_b_background',
        textColor: '$rating_b_foreground',
      },
      gradeC: {
        backgroundColor: '$rating_c_background',
        textColor: '$rating_c_foreground',
      },
      gradeD: {
        backgroundColor: '$rating_d_background',
        textColor: '$rating_d_foreground',
      },
    },
    toast: {
      bottom: '$space_20',
    },
    tooltip: {
      textColor: '$white',
      background: '$deep_purple',
      zIndex: zIndex.mainHeader + 1,
    },
    cuisineSwimlaneItem: {
      full: {
        backgroundColor: '$french_gray_2',
        textColor: '$night_blue_1',
        backgroundColorActive: '$french_gray_1',
      },
      small: {
        backgroundColor: '$white',
        textColor: '$night_blue_1',
      },
    },
    channels: {
      effect:
        'brightness(0) saturate(100%) invert(9%) sepia(14%) saturate(3548%) hue-rotate(227deg) brightness(82%) contrast(111%)',
    },
    defaultFiltersButton: {
      textColor: '$white',
      background: '$deep_purple',
      marginRight: '$space_4 $space_4 0 0',
    },
    headerNotification: {
      padding: '$space_16 4%',
      color: {
        discount: '$blue_darker_3',
        openin: '$limon_darker_3',
        closed: '$red_darker_2',
        closed_reason: '$red_darker_2',
      },
    },
    productItem: {
      imageContainer: {
        height: '145px',
      },
    },
    productItemPrice: {
      price: {
        marginRight: '$space_4',
      },
    },
    smartBanner: {
      background: '$white',
      border: '1px solid $platinum',
    },
    swiper: {
      buttonShadow: '0 9px 19px -9px $grape_50',
    },
    star: {
      full: '$limon_darker_1',
      empty: '$platinum',
    },
  },
  irl: {
    textColor: '$white',
    googleLogin: 'rgb(45, 134, 244)',
    facebookLogin: 'rgb(59, 89, 152)',
    appleLogin: 'rgb(27, 37, 35)',
  },
  payments: {
    plus: {
      backgroundColor:
        'linear-gradient(94deg, rgb(0, 189, 237), rgb(0, 142, 179))',
      textColor: '$white',
    },
    webpay: {
      legendColor: 'rgb(108, 118, 125)',
      reminderColor: '$night_blue_1',
    },
  },
  shoplist: {
    filterHelp: {
      backgroundColor: 'rgb(229, 249, 254)',
      padding: '$space_24 $space_16',
      margin: '0 $space_16',
      borderRadius: '6px',
    },
    cuisineSwimlane: {},
    featuredProduct: {
      color: '$night_blue_1',
      marginLeft: '4%',
      marginBottom: '$space_4',
    },
    shopCard: {
      channels: {
        marginBottom: '$space_4',
      },
      delivery: {
        marginTop: '$space_4',
        color: '$night_blue_2',
      },
      onlinePayments: {
        color: '$night_blue_2',
      },
      shopName: {
        color: '$night_blue_1',
      },
      peyaPlus: {
        freeShippingTextColor: '#000000',
      },
    },
  },
  shopdetails: {
    productCard: {
      count: {
        width: '15px',
        height: '15px',
        backgroundColor: '$deep_purple',
        color: '$white',
      },
      description: {
        color: '$night_blue_2',
        marginTop: '$space_4',
      },
      price: {
        paddingTop: '$space_8',
      },
    },
  },
  productConfiguration: {
    optionRowCheckbox: {
      color: '$night_blue_1',
      selected: {
        color: '$deep_purple',
      },
    },
  },
  vouchers,
  orderStatus,
  location,
  cart,
  checkout,
  riderReview,
  ...fenixTheme,
};

mapValues(theme, {
  ...baseColors,
  ...spaces,
});

export default theme;
