import { removeSpecialChars } from '@utils/string';

export default function filterMenu(section, filter) {
  const sectionName = section.get('name', '').toLowerCase();
  const products = section.get('products');
  const query = removeSpecialChars(filter?.toLowerCase() || '');

  return products.reduce((productsArray, product) => {
    const productName = product.get('name', '').toLowerCase();
    const productCategoryTag = product
      .getIn(['foodCategoryTag', 'name'], '')
      .toLowerCase();
    if (
      removeSpecialChars(sectionName).includes(query) ||
      removeSpecialChars(productName).includes(query) ||
      removeSpecialChars(productCategoryTag).includes(query)
    ) {
      productsArray.push(product.toJS());
    }
    return productsArray;
  }, []);
}
