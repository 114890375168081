export const SELECT_CITY = 'app/location/SELECT_CITY';
export const SELECT_AREA = 'app/location/SELECT_AREA';
export const SEARCHED_LOCATION = 'app/location/SEARCHED_LOCATION';
export const AUTOCOMPLETE_ADDRESSES = 'app/location/AUTOCOMPLETE_ADDRESSES';
export const CLEAR_AUTOCOMPLETE_ADDRESSES =
  'app/location/CLEAR_AUTOCOMPLETE_ADDRESSES';
export const GOOGLE_MAP_LOADED = 'app/location/GOOGLE_MAP_LOADED';
export const GPS_PERMISSION_NOT_ALLOWED =
  'app/location/GPS_PERMISSION_NOT_ALLOWED';
export const UPDATE_SEARCH_FORM = 'app/location/UPDATE_SEARCH_FORM';
