import { fromJS } from 'immutable';

export const IS_FETCHING_ADDRESSES = 'isFetchingAddresses';
export const HAS_FETCHED_ADDRESSES = 'hasFetchedAddresses';
export const DATA = 'data';
export const IS_FETCHING_CREATE = 'isFetchingCreate';
export const IS_FETCHING_UPDATE = 'isFetchingUpdate';
export const IS_FETCHING_DELETE = 'isFetchingDelete';

export const createModel = ({
  data = {},
  isFetchingAddresses = false,
  hasFetchedAddresses = false,
  isFetchingCreate = false,
  isFetchingUpdate = false,
  isFetchingDelete = false,
} = {}) =>
  fromJS({
    [DATA]: data,
    [IS_FETCHING_ADDRESSES]: isFetchingAddresses,
    [HAS_FETCHED_ADDRESSES]: hasFetchedAddresses,
    [IS_FETCHING_CREATE]: isFetchingCreate,
    [IS_FETCHING_UPDATE]: isFetchingUpdate,
    [IS_FETCHING_DELETE]: isFetchingDelete,
  });

const model = createModel();

export default model;
