import { set } from '../services/commonTrackingData';
import { PLATFORM_VERSION } from '../services/constants';

const APP_ARCHITECTURE_NAME = 'microsite';

const setPlatformVersion = () => {
  set(PLATFORM_VERSION, APP_ARCHITECTURE_NAME);
};

export default setPlatformVersion;
