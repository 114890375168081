function onWindowResizeEnds(callback, windowObject = window) {
  let resizeId;

  function removeTimeout() {
    if (resizeId) clearTimeout(resizeId);
  }

  function onResize() {
    removeTimeout();
    resizeId = setTimeout(callback, 500);
  }

  if (windowObject) windowObject.addEventListener('resize', onResize);

  return () => {
    if (windowObject) windowObject.removeEventListener('resize', onResize);
    removeTimeout();
  };
}

export default onWindowResizeEnds;
