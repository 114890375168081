import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';

import { ROUTES, isShopList } from '@services/routes.service';
import { STATUS_CODE, URI_PARAM } from '@shared/constants';
import { isProduction } from '@utils/environment';

// care module
import Chat from '@care/pages/Chat/Loadable';

// results module
import ShopsList from '@results/router/Loadable';

// conversion module
import Cart from '@cart/router/Loadable';
import Checkout from '@checkout/router/Loadable';
import ShopDetails from '@shopDetails/router/Loadable';

import ConversionRoute from '@conversion/containers/ConversionRoute';

// account
import RiderReview from '@riderReview/containers/RiderReview/Loadable';

import MyAddressesRouter from '@location/containers/MyAddresses/MyAddressesRouter';

import NutritionInfo from '@productConfig/pages/NutritionInfo/Loadable';

import Error from '@shared/containers/Error/Loadable';

// landings
import CityLanding from '@cityLanding/pages/CityLanding/Loadable';
import { getQueryAsObject, getQueryParams } from '@utils/domRouter';
import Joker from '@joker/router/Loadable';
import MigrationLanding from '@migrationLanding/pages/MigrationLanding';

import ProtectedRoute from '@user/containers/ProtectedRoute';
import FlaggedRoute from '@funWithFlags/containers/FlaggedRoute';
import { FLAGS } from '@commons/fwf/constants';

// deeplinks
import DeepLinkTester from './modules/pocDeeplink/Loadable';

function Router() {
  return (
    <Switch>
      <Route
        exact
        path={ROUTES.RESULTS}
        render={props => <ShopsList {...props} />}
      />
      <Route
        exact
        path={`${ROUTES.RESULTS}/:${URI_PARAM.CITY}`}
        render={props => {
          const {
            // eslint-disable-next-line react/prop-types
            location,
            // eslint-disable-next-line react/prop-types
            match: {
              // eslint-disable-next-line react/prop-types
              params: { city },
            },
          } = props;
          const search = getQueryAsObject(location);

          if (isShopList(ROUTES.RESULTS, search))
            return <ShopsList {...props} />;

          if (city) return <CityLanding {...props} />;

          return <Error errorCode={STATUS_CODE.NOT_FOUND} />;
        }}
      />
      <Route
        path={`${ROUTES.RESULTS}/:${URI_PARAM.CITY}/:${URI_PARAM.SHOP_MENU}`}
        render={props => <ShopDetails {...props} />}
      />
      <Route
        exact
        path={ROUTES.NUTRITIO_INFO}
        render={props => {
          // eslint-disable-next-line react/prop-types
          const { location } = props;
          const gtin = getQueryParams(location, 'gtin');
          return <NutritionInfo gtin={gtin} />;
        }}
      />
      <Route
        exact
        path={`${ROUTES.RESULTS}/:${URI_PARAM.CITY}/:${URI_PARAM.AREA}?/:${URI_PARAM.CATEGORY}?`}
        render={props => <CityLanding {...props} />}
      />
      <ProtectedRoute
        path={ROUTES.MY_ADDRESSES}
        component={MyAddressesRouter}
      />

      <ProtectedRoute
        exact
        path={`${ROUTES.RIDER_REVIEW}/:${URI_PARAM.ORDER_ID}`}
        component={RiderReview}
      />
      <ProtectedRoute
        exact
        path={ROUTES.RIDER_REVIEW}
        component={RiderReview}
      />

      <ConversionRoute path={ROUTES.CART} component={Cart} />

      {/* Mostly deprecated (checkout-mfe) microsite */}
      <ConversionRoute path={ROUTES.CHECKOUT} component={Checkout} />

      {!isProduction() && (
        <Route
          exact
          path={ROUTES.DEEPLINK_TESTER}
          render={props => <DeepLinkTester {...props} />}
        />
      )}

      <Route
        exact
        path={ROUTES.LANDING_MIGRATION}
        render={props => <MigrationLanding {...props} />}
      />

      <Route path={ROUTES.CHAT} render={props => <Chat {...props} />} />

      <FlaggedRoute path={ROUTES.JOKER} component={Joker} flag={FLAGS.JOKER} />

      <Route render={() => <Error errorCode={STATUS_CODE.NOT_FOUND} />} />
    </Switch>
  );
}

Router.propTypes = {
  location: PropTypes.object,
};

export default Router;
