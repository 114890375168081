import React from 'react';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import CannonicalHeader from './CannonicalHeader';
import OpenGraphHeaders from './OpenGraphHeaders';

function NoFollow() {
  return (
    <>
      <meta name="robots" content="noindex, nofollow" />
      <meta name="googlebot" content="noindex" />
    </>
  );
}

function BasicHelmet({
  title,
  metaContent,
  defaultOgHeaders = false,
  noFollow = false,
  withPagination = false,
}) {
  const helmetTitle = title;
  const helmetMeta = [
    {
      name: 'description',
      content: metaContent,
    },
  ];

  return (
    <>
      <Helmet title={helmetTitle} meta={helmetMeta} />
      <OpenGraphHeaders
        title={title}
        description={metaContent}
        defaultOgHeaders={defaultOgHeaders}
      />
      {noFollow && <NoFollow />}
      <CannonicalHeader withPagination={withPagination} />
    </>
  );
}

BasicHelmet.propTypes = {
  title: PropTypes.string,
  metaContent: PropTypes.string,
  noFollow: PropTypes.bool,
  defaultOgHeaders: PropTypes.bool,
  withPagination: PropTypes.bool,
};

export default BasicHelmet;
