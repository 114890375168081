import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';

import ProtectedRoute from '@user/containers/ProtectedRoute';
import Error from '@shared/containers/Error/Loadable';
import { STATUS_CODE } from '@shared/constants';
import isVariation from '../../utils/isVariation';

function FlaggedRoute({
  component: Component,
  flags,
  flag,
  isProtected,
  ...rest
}) {
  const flagValue = isVariation(flags[flag.KEY]);
  if (flagValue) {
    return isProtected ? (
      <ProtectedRoute {...rest} component={Component} />
    ) : (
      <Route {...rest} render={props => <Component {...props} />} />
    );
  }

  return (
    <Route
      {...rest}
      render={() => <Error errorCode={STATUS_CODE.NOT_FOUND} />}
    />
  );
}

FlaggedRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  render: PropTypes.func,
  flags: PropTypes.object.isRequired,
  flag: PropTypes.object.isRequired,
  isProtected: PropTypes.bool,
};

FlaggedRoute.defaultProps = {
  isProtected: true,
};

export default FlaggedRoute;
