import { createSelector } from 'reselect';

import { BUSINESS_TYPE } from '@commons/constants';
import getVerticals from '@results/selectors/getVerticals';
import { getSelectedVertical } from '@utils/shopsList';
import getBusinessTypeFromQuery from '@shared/selectors/getBusinessTypeFromQuery';
import { getCountryVerticals } from '@shared/selectors';

const getBusinessType = createSelector(
  getBusinessTypeFromQuery,
  getVerticals,
  getCountryVerticals,
  (businessType, verticals, countryVerticals) => {
    const selectedVertical =
      getSelectedVertical(verticals, businessType) ||
      getSelectedVertical(countryVerticals, businessType);
    return selectedVertical?.businessType || BUSINESS_TYPE.RESTAURANT;
  },
);

export default getBusinessType;
