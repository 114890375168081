import {
  TRACK_NEW_STATUS,
  TRACK_DELIVERY_TYPES,
  SHOP_IS_OPEN,
  SHOP_OPENIN,
  SHOP_IS_CLOSED,
} from '@shared/constants';
import { NOT_SET } from '@utils/tracking/constants';

export function getShopDeliveryType(shop) {
  if (shop?.deliveryType)
    return TRACK_DELIVERY_TYPES[shop.deliveryType] || NOT_SET;
  return NOT_SET;
}

export function getShopOpenStatus(shop) {
  let openStatus;
  switch (shop?.opened) {
    case SHOP_IS_OPEN: {
      openStatus = 'open';
      break;
    }
    case SHOP_OPENIN: {
      if (shop.acceptsPreOrder) openStatus = 'preorder';
      else openStatus = 'opens_later';

      break;
    }
    case SHOP_IS_CLOSED: {
      openStatus = 'closed_today';
      break;
    }
    default: {
      openStatus = NOT_SET;
      break;
    }
  }
  return openStatus;
}

export function getShopNewStatus(shop) {
  if ('isNew' in shop)
    return shop.isNew ? TRACK_NEW_STATUS.NEW : TRACK_NEW_STATUS.OLD;
  return NOT_SET;
}

export default function getShopStatus(shop) {
  if (!shop) return '';
  const deliveryType = getShopDeliveryType(shop);
  const openStatus = getShopOpenStatus(shop);
  const newStatus = getShopNewStatus(shop);

  return `${deliveryType},${openStatus},${newStatus}`;
}
