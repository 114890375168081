import { getFWFToken, getFWFLocalUrl } from '@commons/config/funWithFlags';
import { getEnvironment, isProduction, isLocal } from '@utils/environment';
import { Flags } from '@commons/fwf/models/Flags';
import FWFMain from '@deliveryhero/fwf-sdk-javascript';

import { FLAGS_KEYS } from './constants';

const ENV = getEnvironment();
const FWF_TOKEN = getFWFToken(ENV);
const FWF_LOCAL_URL = getFWFLocalUrl(ENV);
const FLAG_TTL_IN_MINUTES = isProduction() ? 20 : 1;
const FLAGS_UPDATED_EVENT_NAME = '@fwf/FLAGS_UPDATED_IN_BACKGROUND';
const CONTEXT_UPDATED_EVENT_NAME = '@fwf/RELEVANT_CONTEXT_CHANGE';
// change manualy to true to enable SDK debug mode
const DEBUG_MODE = isLocal() && false;

const fwfParams = {
  environmentToken: FWF_TOKEN,
  flagTimeToLive: FLAG_TTL_IN_MINUTES,
  region: 'us',
  debugMode: DEBUG_MODE,
};

let initializationPromise;

function getUser({ sessionId, userData = {}, contextData = {} }) {
  let user = {
    ...contextData,
  };
  if (sessionId) user.googleClientId = sessionId;
  const { userId, userEmail, ...userDataRest } = userData;
  const { country } = contextData;
  if (userId) user.userId = userId.toString();
  if (userEmail) user.email = userEmail;
  if (country) user.globalEntityId = `PY_${country.toUpperCase()}`;
  if (userId || userEmail) user = { ...user, ...userDataRest };
  return user;
}

export async function initializeInstance(
  fwfPromise,
  { sessionId, userData, contextData },
  onUpdate,
) {
  initializationPromise = fwfPromise;

  const fwf = await fwfPromise;
  const user = getUser({ sessionId, userData, contextData });
  fwf.setContext(user);
  fwf.subscribeFeatures(FLAGS_KEYS);
  fwf.subscribeObserver(response => {
    if (
      response.type === FLAGS_UPDATED_EVENT_NAME ||
      response.type === CONTEXT_UPDATED_EVENT_NAME
    ) {
      const flags = Flags(response.data);
      onUpdate(flags);
    }
  });

  return initializationPromise;
}

export async function getServerInstance({
  sessionId,
  userData,
  contextData = {},
}) {
  const user = getUser({ sessionId, userData, contextData });
  return new FWFMain({ ...fwfParams, localFwfUrl: FWF_LOCAL_URL, user });
}

export function getClientInstance() {
  if (!initializationPromise)
    throw new Error('[FWF] must call initializeInstance before get one');

  return initializationPromise;
}

export async function updateUser({ sessionId, userData, contextData = {} }) {
  const instance = await getClientInstance();
  const user = getUser({ sessionId, userData, contextData });
  if (instance) {
    return instance.setContext(user);
  }
  return null;
}

export const isInitializated = () => !!initializationPromise;
