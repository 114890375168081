import querystring from 'query-string';
import isInternalVisitUtil from '@commons/utils/url/isInternalVisit';

export function changePropFromQuery(query, key, value) {
  const queryObj = querystring.parse(query);

  if (value) queryObj[key] = value;
  else delete queryObj[key];

  return querystring.stringify(queryObj);
}

export function generateURL({ pathname, search }) {
  let query = search || '';
  if (query && !query.startsWith('?')) {
    query = `?${query}`;
  }
  return `${pathname}${query}`;
}

export function reloadPage({ pathname, search } = {}) {
  if (pathname) {
    let href = pathname;
    if (search) {
      href = generateURL({ pathname, search });
    }
    window.location.href = href; // Force going to server.
  } else {
    window.location.reload();
  }
}

/**
 * @param {string} route full route ex: /restaurantes/montevideo?p=123
 * @param {string} params
 */
export function removeQueryParamsFromURL(route, ...params) {
  const [pathname, query] = route.split('?');
  let search = '';

  if (query && params.length > 0) {
    const searchObj = querystring.parse(`?${query}`);

    params.forEach(p => {
      delete searchObj[p];
    });

    search = querystring.stringify(searchObj);
  }

  return generateURL({ pathname, search });
}

export function isInternalVisit() {
  return isInternalVisitUtil(document.referrer);
}

export function redirectToExternalUrl(externalUrl) {
  window.location.href = externalUrl;
}

export function getLastUrlParam(pathname) {
  if (!pathname) return '';
  const paramArray = pathname.split('/');
  const { length } = pathname.split('/');
  return paramArray[length - 1];
}
