import { fromJS } from 'immutable';
import { successAction, failureAction } from '@utils/actions';
import { INIT_STORE } from '@shared/actionTypes';
import { SEND_ORDER_FAIL_TYPES } from '@commons/utils/payment/parseTransactionError';
import {
  CONFIRM_PAYMENT_METHOD,
  ADD_ONLINE_CARD,
  ADD_CARD_BY_INSTRUMENT_ID,
  DELETE_CONFIRMED_PAYMENT_METHOD,
  SET_INSTRUMENT_CART,
} from '@payments/actionTypes';
import { SELECT_VOUCHER } from '@conversion/actionTypes';
import orderFlowReducer from '@core/utils/orderFlowReducer';
import isOutOfDeliveryError from '@utils/cart/isOutOfDeliveryError';

import model, {
  OUT_OF_DELIVERY_ERROR,
  PRODUCTS_REQUIRE_VALIDATE,
} from './model';
import {
  INIT_CART,
  ADD_LINE,
  UPDATE_LINE,
  DELETE_LINES,
  CHANGE_ADDRESS,
  UPDATE_CART_DELIVERY_TYPE,
  UPDATE_CART_PHONE,
  UPDATE_CART_DELIVERY_TIME,
  UPDATE_CART_NOTES,
  UPDATE_CART_MISSING_PRODUCT_OPTION,
  SET_FROM_ORDER_ID,
  RESET_OUT_OF_DELIVERY_ERROR,
  AGE_VALIDATION_PRODUCTS,
  DELETE_CART,
} from './actionTypes';

import { SET_SHOP } from '../shop/actionTypes';
import { SEND_ORDER } from '../order/actionTypes';

function setOutOfDeliveryErrorOnFailure(state, { error, payload }) {
  const { setOutOfDeliveryError } = payload || {};
  // Don't set to false when !isOutOfDeliveryError.
  // Don't set to true only when setOutOfDeliveryError === false.
  return isOutOfDeliveryError(error) && setOutOfDeliveryError !== false
    ? state.set(OUT_OF_DELIVERY_ERROR, true)
    : state;
}

const getDeleteCartFeaturedProduct = (featuredProduct, cart) => {
  const cartData = cart?.getIn(['data'])?.toJS() || {};
  const items = cartData?.items || [];
  return (
    featuredProduct &&
    (!items ||
      !items.length ||
      items.findIndex(item => `${item.product}` === featuredProduct) === -1)
  );
};

const handleFeaturedProduct = (state, action) => {
  const {
    type,
    payload: { featuredProduct },
  } = action;
  if (type === successAction(ADD_LINE) && !!featuredProduct) {
    return state.set('featuredProduct', fromJS(featuredProduct));
  }
  if (
    type === successAction(DELETE_LINES) &&
    getDeleteCartFeaturedProduct(featuredProduct, state)
  ) {
    return state.set('featuredProduct', null);
  }
  return state;
};

function cartReducer(state = model, action) {
  switch (action.type) {
    case INIT_STORE:
      return model.mergeDeep(state);

    case SET_SHOP:
      return model;

    case SET_FROM_ORDER_ID:
      return state.set('fromOrderId', action.payload);

    case DELETE_LINES:
      return state.set('isDeletingLines', true).set('isUpdetingLines', true);

    case UPDATE_LINE:
      return state.set('isUpdetingLines', true);

    case CHANGE_ADDRESS:
      return state
        .set('isChangingAddress', true)
        .set(OUT_OF_DELIVERY_ERROR, false);

    case successAction(CHANGE_ADDRESS):
      return state
        .set('data', fromJS(action.payload))
        .set('isChangingAddress', false)
        .set(OUT_OF_DELIVERY_ERROR, false);

    case failureAction(CHANGE_ADDRESS):
      return setOutOfDeliveryErrorOnFailure(
        state.set('isChangingAddress', false),
        action,
      );

    case RESET_OUT_OF_DELIVERY_ERROR:
      return state.set(OUT_OF_DELIVERY_ERROR, false);

    case successAction(AGE_VALIDATION_PRODUCTS):
      return state.set(PRODUCTS_REQUIRE_VALIDATE, action.payload);

    case INIT_CART:
      return state
        .set('isInitializing', true)
        .set('fromOrderId', action.payload || null)
        .set(OUT_OF_DELIVERY_ERROR, false);

    case successAction(INIT_CART):
      return state
        .set('data', fromJS(action.payload))
        .set('isInitializing', false);

    case failureAction(INIT_CART):
      return setOutOfDeliveryErrorOnFailure(
        state.set('isInitializing', false),
        action,
      );

    case DELETE_CART:
      // we restart the cart
      return model;

    case successAction(UPDATE_CART_DELIVERY_TYPE):
    case successAction(UPDATE_CART_PHONE):
    case successAction(UPDATE_CART_DELIVERY_TIME):
    case successAction(UPDATE_CART_NOTES):
    case successAction(UPDATE_CART_MISSING_PRODUCT_OPTION):
    case successAction(ADD_LINE):
    case successAction(UPDATE_LINE):
    case successAction(DELETE_LINES):
    case successAction(CONFIRM_PAYMENT_METHOD):
    case successAction(ADD_ONLINE_CARD):
    case successAction(ADD_CARD_BY_INSTRUMENT_ID):
    case successAction(SET_INSTRUMENT_CART):
    case successAction(DELETE_CONFIRMED_PAYMENT_METHOD):
    case successAction(SELECT_VOUCHER): {
      const cart = action.payload?.cart;
      if (cart) {
        const newState = state
          .set('data', fromJS(cart))
          .set('isDeletingLines', false)
          .set('isUpdetingLines', false);
        return handleFeaturedProduct(newState, action);
      }
      return state;
    }

    case failureAction(SEND_ORDER): {
      const { payload } = action;
      if (payload.type === SEND_ORDER_FAIL_TYPES.ONLINE_PAYMENT_HIGH_RISK)
        return state.setIn(['data', 'highRisk'], true);

      return state;
    }

    case failureAction(UPDATE_LINE):
      return state.set('isUpdetingLines', false);

    default:
      return state;
  }
}

export default orderFlowReducer(cartReducer, model);
