import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { routerMiddleware } from 'connected-react-router/immutable';

import { createClientHistory } from '@utils/history';
import reducerRegistry from '@utils/redux/ReducerRegistry';
import { isProduction } from '@utils/environment';
import { setRouterPrevLocationMiddleware } from '@utils/redux/router';
import userMiddleware from '@core/middlewares/user';
import orderFlowMiddleware from '@core/middlewares/order';
import locationMiddleware from '@core/middlewares/location';
import commonTrackingDataMiddleware from '@tracking/commonTrackingData/middleware';
import fromJSGreedy from '@app/configureStore/fromJSGreedy';

import createReducer, { createRootReducer } from '@app/reducers';
import { Logger } from '@logger/redux.logger';
import trackVirtualPageViewMiddleware from '@tracking/trackVirtualPageViewMiddleware';

function configureClientStore(initialState = {}, axiosInstance) {
  const composeEnhancers =
    !isProduction() &&
    typeof window === 'object' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
          // TODO Try to remove when `react-router-redux` is out of beta, LOCATION_CHANGE should not be fired more than once after hot reloading
          // Prevent recomputing reducers for `replaceReducer`
          shouldHotReload: false,
        })
      : compose;

  const immutableState = fromJSGreedy(initialState);
  const history = createClientHistory();

  const enhancers = [
    applyMiddleware(
      routerMiddleware(history),
      thunk.withExtraArgument(axiosInstance),
      setRouterPrevLocationMiddleware,
      commonTrackingDataMiddleware,
      Logger, // redux logger
      orderFlowMiddleware,
      trackVirtualPageViewMiddleware,

      userMiddleware,
      locationMiddleware,
    ),
  ];

  const store = createStore(
    createReducer(initialState),
    immutableState,
    composeEnhancers(...enhancers),
  );

  reducerRegistry.setChangeListener(reducers => {
    const [...stateKeys] = store.getState().keys();
    store.replaceReducer(createRootReducer(reducers, stateKeys));
  });

  return store;
}

export default configureClientStore;
