import {
  push,
  goBack as back,
  go as goToHistoryPoint,
  replace,
} from 'connected-react-router';
import queryString from 'query-string';

import { QUERY_PARAM } from '@commons/constants';
import getDevice from '@commons/utils/device/getDevice';
import { DESKTOP } from '@commons/utils/device/devices';
import { LOGIN_EXTERNAL } from '@core/reducers/user/actionTypes';
import getCurrentUser from '@core/reducers/user/selectors/getCurrentUser';
import { successAction, failureAction } from '@utils/actions';

import * as actions from './actionTypes';
import { NOTIFICATION_TIMEOUT, TOAST_STATE, STATUS_CODE } from './constants';
import { getIsUserLogged, getSearchAsObject, getUserName } from './selectors';

// router actions
export const pushPage = page => push(page);
export const goBack = ({ fallbackUrl } = {}) => dispatch => {
  const prevPage = window.location.href;
  dispatch(back());
  if (fallbackUrl) {
    setTimeout(() => {
      if (window.location.href === prevPage) {
        dispatch(replace(fallbackUrl));
      }
    }, 500);
  }
};
export const go = number => goToHistoryPoint(number);
export const redirect = page => replace(page);

let toastTimeout;
export const hideToast = () => dispatch => {
  if (toastTimeout) clearTimeout(toastTimeout);
  dispatch({ type: actions.HIDE_TOAST });
};

export const showToast = ({
  title,
  color = TOAST_STATE.SUCCESS,
}) => dispatch => {
  dispatch({ type: actions.SHOW_TOAST, title, color });

  toastTimeout = setTimeout(() => {
    dispatch(hideToast());
  }, NOTIFICATION_TIMEOUT);
};

export const showErrorToast = ({ title = 'Ha ocurrido un error' } = {}) =>
  showToast({ title, color: TOAST_STATE.ERROR });

export const showSuccessToast = ({ title = '' }) =>
  showToast({ title, color: TOAST_STATE.SUCCESS });

export const showNeutralToast = ({ title = '' }) =>
  showToast({ title, color: TOAST_STATE.NEUTRAL });

export const redirectToErrorPage = (
  errorCode = STATUS_CODE.INTERNAL_ERROR,
) => ({ type: actions.SHOW_ERROR_PAGE, errorCode });

export const errorAction = ({
  type,
  error,
  errorMessage,
  mute = false,
  errorCode = STATUS_CODE.INTERNAL_ERROR,
  ...props
}) => dispatch => {
  if (!mute) {
    if (errorMessage) dispatch(showErrorToast({ title: errorMessage }));
    else dispatch(redirectToErrorPage(errorCode));
  }

  dispatch(failureAction({ type, ...props }, error));
};

export const successfulAction = ({
  type,
  message,
  mute = false,
  ...props
}) => dispatch => {
  if (!mute && message) {
    dispatch(showSuccessToast({ title: message }));
  }

  dispatch(successAction({ type, ...props }));
};

export const showToastLoginSuccess = () => (dispatch, getState) => {
  const device = getDevice();
  if (device === DESKTOP) {
    const userName = getUserName(getState());
    dispatch(showSuccessToast({ title: `¡Hola ${userName}!` }));
  }
};

const replaceCurrentURL = search => {
  const s = queryString.stringify(search);
  const { origin, pathname } = window.location;
  window.history.replaceState(
    null,
    null,
    `${origin}${pathname}${s ? `?${s}` : ''}`,
  );
};

const showToastAfterExternalLogin = () => async (dispatch, getState) => {
  const state = getState();
  const isLogged = getIsUserLogged(state);
  const currentUser = getCurrentUser(state);
  const type = LOGIN_EXTERNAL;

  dispatch({ type });

  if (isLogged) {
    dispatch(showToastLoginSuccess());
    dispatch(successfulAction({ type, payload: { user: currentUser } }));
  } else {
    dispatch(showErrorToast());
    dispatch(errorAction({ type, error: 'External login failed', mute: true }));
  }
};

export const checkForExternalLogin = () => async (dispatch, getState) => {
  // Check for external login
  const state = getState();
  const search = getSearchAsObject(state);
  if (search[QUERY_PARAM.EXTERNAL_LOGIN]) {
    dispatch(showToastAfterExternalLogin());

    delete search[QUERY_PARAM.EXTERNAL_LOGIN];
    replaceCurrentURL(search);
  }
};

export const setMainContentId = mainContentId => dispatch => {
  dispatch({ type: actions.SET_MAIN_CONTENT_ID, payload: mainContentId });
};
