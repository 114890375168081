import { useMemo } from 'react';
import { DESKTOP } from '@commons/utils/device/devices';
import useDeviceType from '@hooks/useDeviceType';

function useIsDesktopLayout() {
  const device = useDeviceType();
  const isDesktop = useMemo(() => device === DESKTOP, [device]);
  return isDesktop;
}
export default useIsDesktopLayout;
