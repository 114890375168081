import { isLocal } from '@utils/environment';
import * as Sentry from './sentry';
import { ERROR_LEVEL, INFO_LEVEL } from './constants';

let sentryPromise;

function initGlobalLoggerFallback(sessionId) {
  try {
    window.onerror = (message, url, lineNumber, colNumber, err) => {
      const data = {
        message,
        url,
        lineNumber,
        colNumber,
        sessionId,
      };

      if (sentryPromise) {
        sentryPromise.then(() => {
          Sentry.logError(err || message, data);
        });
      }
    };
  } catch (e) {
    if (isLocal()) {
      console.error(e);
    }
  }
}

export function initialize({ sessionId, user, searchedLocation, countryName }) {
  try {
    sentryPromise = Sentry.initialize(sessionId, {
      user,
      searchedLocation,
      countryName,
    });

    initGlobalLoggerFallback(sessionId);

    return sentryPromise;
  } catch (e) {
    if (isLocal()) {
      console.error(e);
    }
    return Promise.resolve();
  }
}

export function log(message) {
  return new Promise((resolve, reject) => {
    try {
      if (message) {
        if (isLocal()) {
          console.log(message);
        }
      }

      resolve();
    } catch (e) {
      reject();
    }
  });
}

export function error(message, { error: e, data } = {}) {
  try {
    const errorMessage = e?.message || '';

    if (e instanceof Error) {
      Sentry.logError(e, { message, data });
    } else {
      Sentry.logEvent(message, {
        data: { error: errorMessage, data },
        level: 'error',
      });
    }
  } catch (err) {
    if (isLocal()) {
      console.error(`[LOGGER][ERROR] ${err.message}`);
    }
  }
}

export const event = Sentry.logEvent;
export { ERROR_LEVEL, INFO_LEVEL };
