import { createSelector } from 'reselect';

import getShop from './getShop';

const getShopName = createSelector(
  getShop,
  shop => shop?.name || '',
);

export default getShopName;
