import { createSelector } from 'reselect';

import getShop from './getShop';

const getShopId = createSelector(
  getShop,
  shop => shop?.id,
);

export default getShopId;
