export const FETCH_EXTENDED_MENU = 'app/core/reducers/menu/FETCH_EXTENDED_MENU';
export const FETCH_SHELVES_MENU = 'app/core/reducers/menu/FETCH_SHELVES_MENU';
export const FETCH_SHELVES_MENU_ITEMS =
  'app/core/reducers/menu/FETCH_SHELVES_MENU_ITEMS';
export const FETCH_MENU_SECTION = 'app/core/reducers/menu/FETCH_MENU_SECTION';
export const FILTER_MENU = 'app/core/reducers/menu/FILTER_MENU';
export const ADD_FAVORITE_DISH = 'app/core/reducers/menu/ADD_FAVORITE_DISH';
export const REMOVE_FAVORITE_DISH =
  'app/core/reducers/menu/REMOVE_FAVORITE_DISH';
export const SHOW_TOOLTIP_FAVORITE_DISH =
  'app/core/reducers/menu/SHOW_TOOLTIP_FAVORITE_DISH';
export const HIDE_TOOLTIP_FAVORITE_DISH =
  'app/core/reducers/menu/HIDE_TOOLTIP_FAVORITE_DISH';
