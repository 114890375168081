import { IS_NEW_ADDRESS } from '@commons/models/LocationPoint/model';
import { pickWithNoUndefinedValues } from '@utils/object';
import { modelProps } from '@services/models/UserAddress';

const ORDER_KEY = 'order';

function UserAddress(address) {
  return pickWithNoUndefinedValues(address, [
    ...modelProps,
    IS_NEW_ADDRESS,
    ORDER_KEY,
  ]);
}

export default UserAddress;
